import React from 'react';
import UserAvatar from '../../../dashboard/components/avatar/UserAvatar';
import { CloseOutlined } from '@ant-design/icons';
import { useCommunityContext } from '../Context/CommunityContext';

const ChatTopBar = ({read}) => {
    const {app,agent,theme,isChatSiderVisible,setIsChatSiderVisible} = useCommunityContext();

    return (
        <div className={`w-full transition-transform duration-300 ease-in-out z-30`} >
            <div className="p-2 h-full flex items-center justify-center w-full shadow-xl relative"
                style={{backgroundColor: 'var(--secondary)', color: 'var(--tertiary1)'}}>
                <div className='absolute left-0 p-2'>
                    <div onClick={() => setIsChatSiderVisible(prev=> !prev)} 
                        className={`text-xl w-fit rounded-lg aspect-square px-2 items-center justify-center relative cursor-pointer flex
                        ${!isChatSiderVisible ? 'opacity-0' : 'opacity-100'}`} 
                        style={{color: 'var(--tertiary1)', backgroundColor: 'var(--secondary)'}}>
                        <CloseOutlined />
                    </div>
                </div>
                <div className='w-8/12 flex items-center' onClick={() => setIsChatSiderVisible(prev=> !prev)}>
                    <div className='flex items-center gap-2 w-fit m-auto'>
                        <div className='relative'>
                            <div className='border rounded-full' style={{borderColor: theme?.colors?.tertiary1 || '#111111'}}>
                                <UserAvatar app={app}/>
                            </div>
                            {
                                read && <div className="absolute bottom-0 right-0 p-0.5"><div className="w-2 aspect-square rounded-full bg-green-400 shadow-xl"></div></div>
                            }
                        </div>
                        <div className='flex flex-col gap-0.5 text-left'>
                            <div className='font-bold text-base leading-none'>
                                {app?.name}
                            </div>
                            <div className='leading-none text-xs'>
                                {agent?.name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatTopBar;