import axios from "axios";
import Resizer from "react-image-file-resizer";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}/content`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1200, 1200, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class CommunityServices {

    getCommunity({path}){
        //
        const values = {
            'path':path,
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/community/info`,
            data: values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    createCommunity({values,user}){

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/community`,
            data: values,
            headers:{"Authorization" : `Bearer ${user.access}`},
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    checkCommunityPath({path}){
        //
        const values = {
            'path':path,
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/community/check`,
            data: values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    joinCommunity({community_id,join,user}){
        //
        const values = {
            'id':community_id,
            'join':join,
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/community/join`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data: values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    checkMemberCommunity({community_id,user}){
        if(user){
            let values = {
                'id':community_id
            }
    
            const configuration = {
                credentials: 'include',
                method: "post",
                url: API_URL + `/community/member/check`,
                headers:{"Authorization" : `Bearer ${user.access}`},
                data: values
            };
            
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response
                error = new Error();
            });
        }
        else{
            return false
        }
    }

    getCommunities({page=0,limit=15,ordering='-updated_at',search=false,}){
        const offset = page * limit || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/community/list?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/community/list?limit=${limit}&ordering=${ordering}` };

        if(search){
            configAPIUrl = configAPIUrl + `&search=${search}`
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    postPageView({app_id}){
        if(app_id){
            const values = {
                'app_id':app_id
            }
    
            const configuration = {
                credentials: 'include',
                method: "post",
                url: API_URL + `/track-page-view`,
                data: values
            };
    
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response
                error = new Error();
            });
        }
    }
}
export default new CommunityServices();