import { createContext, useContext, useMemo } from "react";
import { useOutlet } from "react-router-dom";
import { useLocalStorage } from "../../utils/useLocalStorage";

const RegisterContext = createContext();

export const RegisterProvider = () => {
    const outlet = useOutlet();
    const [userDetails,setUserDetails] = useLocalStorage('userDetails',true);
    
    const value = useMemo(
        () => ({
            userDetails,setUserDetails,
        }),[userDetails,setUserDetails]
    );

    return(
        <RegisterContext.Provider value={value}>
            {outlet}
        </RegisterContext.Provider>
    );
};

export const useRegisterContext = () => {
    return useContext(RegisterContext);
};