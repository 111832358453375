import React, { useState } from "react";
import WaitlistExtraModal from "../../components/modal/WaitlistExtraModal";
import Navbar from "./components/Navbar";
import heroImage from '../../assets/images/hero-image.jpg';

function HomePageForProfessionals() {
    const [isWaitlistExtraModalVisible, setIsWaitlistExtraModalVisible] = useState(false);
    const [email, setEmail] = useState('');
    

    const clickGetStarted = () => {
        const element = document.getElementById("get-started");
        window.scrollTo({top: element.offsetTop - 150,behavior: 'smooth',});
        window.history.pushState(null,null,'#get-started');
    }
    function reveal_by_id(id) {
        var reveals = document.querySelectorAll(id);
    
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 0;
        
            if (elementTop + 50 < windowHeight - elementVisible ) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    function reveal(){
        reveal_by_id(".reveal");
        reveal_by_id(".reveal-top");
        reveal_by_id(".reveal-left");
        reveal_by_id(".reveal-right");
    }
    window.addEventListener(
        "scroll",() => reveal(),{ passive: true }
    );
    
    return(
        <div className="w-screen h-full relative bg-white min-h-screen overflow-x-hidden">
            <Navbar onClick={clickGetStarted} />
            <div className="bg-gray-900 text-white">
                <div className="relative flex flex-col items-center justify-center h-screen bg-cover bg-center">
                    <h1 className="text-4xl md:text-6xl font-bold text-center mb-4">
                        Chat with Virtual Mentors Anytime
                    </h1>
                    <p className="text-lg md:text-xl text-center mb-8">
                        Get personalized guidance from AI-powered experts, available 24/7.
                    </p>
                    <div className="flex space-x-4">
                        <button onClick={() => setIsWaitlistExtraModalVisible(true)} className="px-6 py-3 bg-indigo-500 rounded-lg hover:bg-indigo-600 duration-300">
                            Start Your Free Trial
                        </button>
                        <button onClick={() => setIsWaitlistExtraModalVisible(true)} className="px-6 py-3 border border-white rounded-lg hover:bg-white hover:text-gray-900 duration-300">
                            Explore Mentors
                        </button>
                    </div>
                    <div className="absolute top-10 left-10 w-16 h-16 bg-indigo-400 opacity-30 rounded-full animate-pulse"></div>
                    <div className="absolute top-32 right-16 w-12 h-12 bg-purple-300 opacity-40 rounded-full animate-pulse"></div>
                    <div className="absolute bottom-20 left-24 w-20 h-20 bg-indigo-500 opacity-20 rounded-lg animate-spin-slow"></div>
                    <div className="absolute bottom-10 right-8 w-14 h-14 bg-purple-600 opacity-25 rounded-full"></div>
                </div>
                <section className="py-16 px-8 bg-gray-800">
                    <h2 className="text-3xl font-semibold text-center mb-10">
                        How TalkInBio Mentor AI Works
                    </h2>
                    <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
                        {['Choose Your Mentor', 'Start Chatting Instantly', 'Unlimited Conversations'].map((step, index) => (
                            <div key={index} className="bg-gray-700 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold mb-4">{step}</h3>
                                <p className="text-gray-300">
                                    {index === 0
                                    ? 'Select from AI-driven mentors inspired by real experts.'
                                    : index === 1
                                    ? 'Ask questions and get personalized advice in real-time.'
                                    : 'Enjoy unlimited chats with mentors through a subscription.'}
                                </p>
                            </div>
                        ))}
                    </div>
                </section>
                <section className="py-16 px-8">
                    <h2 className="text-3xl font-semibold text-center mb-10">Pricing Plans</h2>
                    <div className="max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
                        {
                            [
                                { title: 'Free Trial', price: '$0', features: ['7-day access to 1 mentor'] },
                                { title: 'Standard Plan', price: '$29/mo', features: ['Unlimited chats with 3 mentors'] },
                                { title: 'Premium Plan', price: '$49/mo', features: ['Access to all mentors'] },
                            ].map((plan, index) => (
                                <div key={index} className="bg-gray-700 p-6 rounded-lg text-center">
                                    <h3 className="text-xl font-semibold mb-2">{plan.title}</h3>
                                    <p className="text-4xl font-bold mb-4">{plan.price}</p>
                                    <ul className="space-y-2 mb-6">
                                        {plan.features.map((feature, i) => (
                                        <li key={i} className="text-gray-300">
                                            {feature}
                                        </li>
                                        ))}
                                    </ul>
                                    <button onClick={() => setIsWaitlistExtraModalVisible(true)} className="w-full px-4 py-2 bg-indigo-500 rounded-lg hover:bg-indigo-600">
                                        Get Started Now
                                    </button>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <section className="py-16 px-8 bg-gray-800">
                    <h2 className="text-3xl font-semibold text-center mb-10">What Users Are Saying</h2>
                    <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
                        {
                            [
                                'TalkInBio Mentor AI has completely transformed my fitness routine!',
                                'It feels like chatting with the real thing – I get business insights whenever I need them!',
                            ].map((testimonial, index) => (
                                <div key={index} className="bg-gray-700 p-6 rounded-lg">
                                <p className="text-lg italic">"{testimonial}"</p>
                                <div className="mt-4 text-right">
                                    <span className="text-indigo-400">⭐️⭐️⭐️⭐️⭐️</span>
                                </div>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <section className="py-16 px-8">
                    <h2 className="text-3xl font-semibold text-center mb-10">FAQ</h2>
                    <div className="max-w-4xl mx-auto space-y-6">
                        {
                            [
                                { question: 'What happens after I subscribe?', answer: 'You get instant access to your selected mentors.' },
                                { question: 'Can I switch mentors anytime?', answer: 'Yes, you can change mentors based on your needs.' },
                                { question: 'Is my data safe?', answer: 'All interactions are private and secure.' },
                            ].map((faq, index) => (
                                <div key={index} className="bg-gray-700 p-6 rounded-lg">
                                    <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
                                    <p className="text-gray-300">{faq.answer}</p>
                                </div>
                            ))
                        }
                    </div>
                </section>
                <footer className="py-8 bg-gray-900 text-center">
                    <p>&copy; 2024 TalkInBio Mentor AI. All Rights Reserved.</p>
                    <div className="flex justify-center space-x-4 mt-4">
                        {
                            ['Facebook', 'Instagram', 'Twitter'].map((platform) => (
                                <a
                                key={platform}
                                href="#"
                                className="text-indigo-400 hover:text-indigo-500"
                                >
                                {platform}
                                </a>
                            ))
                        }
                    </div>
                </footer>
            </div>
            <WaitlistExtraModal email={email} isWaitlistExtraModalVisible={isWaitlistExtraModalVisible} setIsWaitlistExtraModalVisible={setIsWaitlistExtraModalVisible} />
        </div>
    )
}
export default HomePageForProfessionals;