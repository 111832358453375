import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;


class ContentServices {

    getContentByAgents({user,agent_ids,page=0,limit=15,ordering='-updated_at',search=false,is_active=false}){
        const offset = page * limit || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/content/by-app?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/content/by-app?limit=${limit}&ordering=${ordering}` };

        agent_ids.forEach(agent_id => {
            configAPIUrl = configAPIUrl + `&agent_ids=${agent_id}`
        });

        if(search){
            configAPIUrl = configAPIUrl + `&search=${search}`
        }
        if(is_active){
            configAPIUrl = configAPIUrl + `&is_active=${is_active}`
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
            headers:{"Authorization" : `Bearer ${user.access}`}
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    postContent({values,user}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/content`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    editContent({values,user}){
        const configuration = {
            credentials: 'include',
            method: "patch",
            url: API_URL + `/content`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new ContentServices();