import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import { Button, ConfigProvider, Form, Input, message } from "antd";
import { useAuth } from "../../../../utils/useAuth";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import ImageServices from "../../../../utils/ImageServices";
import UserServices from "../../../../utils/UserServices";
import GeneralAvatar from "../../../../components/avatar/GeneralAvatar";

const UserImagesForm = ({setLoading}) => {
    let newUser;
    const avatarRef = useRef();
    const bannerRef = useRef();
    const {user,setUser,refresh} = useAuth();
    const [uploadingAvatar,setUploadingAvatar] = useState(false);
    const [uploadingBanner,setUploadingBanner] = useState(false);
    const [bannerImage,setBannerImage] = useState(null);
    const [avatar,setAvatar] = useState(null);

    const postAvatar = async (file) => {
        let avatarImageUploadResponse = await ImageServices.postAvatar({image:file, name: `${user.username}-avatar-${file.name.slice(0,30)}`, user:user})
        if(avatarImageUploadResponse.status === 401){
            newUser = await refresh();
            avatarImageUploadResponse = await ImageServices.postAvatar({image:file, name: `${user.username}-avatar-${file.name}`, user:newUser})
        }
        return avatarImageUploadResponse
    }
    const postBanner = async (file) => {
        let imageUploadResponse = await ImageServices.postImage({image:file, name: `${user.username}-banner-${file.name.slice(0,30)}`, user:user})
        if(imageUploadResponse.status === 401){
            newUser = await refresh();
            imageUploadResponse = await ImageServices.postImage({image:file, name: `${user.username}-banner-${file.name.slice(0,30)}`, user:newUser})
        }
        return imageUploadResponse
    }
    const fileUpload = async (event,fileType) => {
        setLoading(false);
        if(fileType === 'banner'){
            setUploadingBanner(true);
        }
        else{
            setUploadingAvatar(true);
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        let uploadResponse;
        if(fileType === 'banner'){
            uploadResponse = await postBanner(file);
        }
        else{
            uploadResponse = await postAvatar(file);
        }
        if(uploadResponse.status === 'success'){
            if(fileType === 'banner'){
                setBannerImage(uploadResponse?.data?.image);
                await onSave({values:{'banner_image_id':uploadResponse?.data?.id}});
                setUploadingBanner(false);
                if(!uploadingAvatar){
                    setLoading(false);
                }
            }
            else{
                setAvatar(uploadResponse?.data?.image);
                await onSave({values:{'avatar_id':uploadResponse?.data?.id}});
                setUploadingAvatar(false);
                if(!uploadingBanner){
                    setLoading(false);
                }
            }
        }
        else{
            message.info('Error on Upload! Try Again.');
            setUploadingBanner(false);
            setUploadingAvatar(false);
            setLoading(false);
        }
    };
    const onSave = async ({values}) => {
        let valuesResponse = await UserServices.editUserDetails({values:values, user:user});
        if(valuesResponse.status === 401){
            newUser = await refresh();
            valuesResponse = await UserServices.editUserDetails({values:values, user:newUser});
        }
        if(valuesResponse.status === 'success'){
            setUser(prev => ({...prev,...valuesResponse.data}));
        }
        return valuesResponse.status
    }
    
    return(
        <div className="w-full">
            {
                false &&
                    <div className="flex flex-col gap-2 mb-4">
                        <div className="text-sm text-slate-500">
                            Banner Image
                        </div>
                        <div>
                            <div onClick={() => {if(!uploadingBanner){bannerRef?.current.click();}}} 
                                    className="rounded-2xl w-full aspect-[5/1] bg-cover bg-center bg-no-repeat bg-slate-100 hover:bg-slate-200 text-slate-500 hover:text-slate-600 
                                                border border-slate-300 duration-300 cursor-pointer flex items-center justify-center" 
                                    style={bannerImage ? {backgroundImage:`url(${bannerImage})`} : {}}>
                                    {   
                                        uploadingBanner
                                        ?   <span><LoadingOutlined/> Uploading...</span>
                                        :   !bannerImage
                                            ?   <span >Upload Banner</span>
                                            :   null
                                    }
                            </div>
                        </div>
                        <input type='file' id='file' ref={bannerRef} accept="image/*" onChange={(event) => fileUpload(event,'banner')} style={{display: 'none'}}/>
                    </div>
            }
            <div className="flex flex-col gap-2">
                <div className="text-sm text-slate-500">
                    Avatar Image
                </div>
                <div className="flex items-center gap-2">
                    <GeneralAvatar image={avatar} name={user?.username} loading={uploadingAvatar} size="large" />
                    <span onClick={() => {if(!uploadingAvatar){avatarRef?.current.click();}}} className="cursor-pointer text-slate-400 hover:text-slate-500 duration-300">
                        Upload Avatar
                    </span>
                </div>
                <input type='file' id='file' ref={avatarRef} accept="image/*" onChange={(event) => fileUpload(event,'avatar')} style={{display: 'none'}}/>
            </div>     
        </div>
    )
}

const UserDetailsForm = ({onSuccess}) => {
    const {user,setUser,refresh} = useAuth();
    const [values,setValues] = useState(user);
    const [loading,setLoading] = useState(false);

    const saveUserDetails = async () => {
        setLoading(true);
        let valuesResponse = await UserServices.editUserDetails({values:values, user:user});
        if(valuesResponse.status === 401){
            let newUser = await refresh();
            valuesResponse = await UserServices.editUserDetails({values:values, user:newUser});
        }
        if(valuesResponse.status === 'success'){
            setUser(prev => ({...prev,...valuesResponse.data}));
            message.success('Successfully Updated User');
            onSuccess();
        }
        setLoading(false);
    }

    useEffect(() => {
        setValues(user);
    },[user]);

    return(
        <div className="w-full">
            <Form className="w-full p-0">
                <h1 className="text-3xl font-bold mb-2">
                    Your Profile
                </h1>
                <div className="w-full flex flex-col gap-2 py-2">
                    <div className="p-0.5">
                        <UserImagesForm setLoading={setLoading} />
                    </div>
                    <div>
                        <div className="p-0.5 text-slate-500">
                            Userame:
                        </div>
                        <Input 
                            size='large' placeholder="Username" 
                            value={values?.username}
                            suffix={values?.username !== '' && <CheckCircleOutlined style={{color:'#52c41a',fontSize:'14px'}} />}
                            onChange={(value) => {
                                setValues(prev => ({...prev,'username':value.target.value}))
                            }} />
                    </div>
                    <div>
                        <div className="p-0.5 text-slate-500">
                            Bio (optional):
                        </div>
                        <div>
                            <Input.TextArea 
                                    size='large' 
                                    placeholder="Short Description about You"
                                    value={values?.description}
                                    rows={4}
                                    onChange={(value) => setValues(prev => ({...prev,'description':value.target.value}))} />
                        </div>
                    </div>
                    <div className="flex w-full justify-end">
                        <div className="flex items-center gap-2">
                            <Button type={'outline'} loading={loading} disabled={loading} onClick={saveUserDetails} className="w-full md:w-fit rounded-full bg-slate-500 hover:bg-slate-700 text-white hover:text-white border-none h-auto">
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )

}

function UserManagerModal({isUserManagerModalVisible,setIsUserManagerModalVisible}){
    
    const closeModal = () => {
        setIsUserManagerModalVisible(false);
    }
    
    return(
        <ConfigProvider
            theme={{
                token: {
                    padding:16
                }}}>
            <Modal
                open={isUserManagerModalVisible} 
                footer={null} 
                onCancel={closeModal}
                width={'100%'}
                style={{maxWidth:'460px',overflow:'hidden',marginBottom:'5vh'}}
                className="shadow-xl rounded-3xl p-0"
                centered
                closable={true}
                destroyOnClose
                >
                <div className="w-full h-full px-6 py-8">
                    <div className="text-slate-700 w-full flex flex-col gap-2">
                        <UserDetailsForm onSuccess={closeModal} />
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    )
}

export default UserManagerModal;