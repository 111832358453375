import { Avatar, Popover } from "antd";
import UserAvatar from "../../../dashboard/components/avatar/UserAvatar";

const UserPopoverContent = ({username}) => {

    return(
        <div className="flex flex-col gap-2">
            Go to {username}
        </div>
    )
}

const UserPopover = ({author}) => {
    return(
        <Popover content={<UserPopoverContent username={author?.username || "Anonymous User"}/>} >
            <div onClick={() => {if(author?.username){window.open(`/user/${author?.username.toLowerCase().split(' ').join('-')}`)}}} 
                className="flex items-center gap-2 p-2 pr-4 rounded-xl cursor-pointer hover:bg-slate-100 duration-300">
                <UserAvatar app={author}/>
                <div className="flex flex-col text-left">
                    <h3 className="text-gray-800 text-base p-0 leading-tight font-bold">
                        {author?.username || 'Anonymous User'}
                    </h3>
                    <div className="text-gray-600 p-0 leading-tight">
                        @{author?.username.toLowerCase().split(' ').join('-') || 'anonymous'}
                    </div>
                </div>
            </div>
        </Popover>
    )
}

export default UserPopover;