import { Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import EmailServices from "../../utils/EmailServices";
import { MailOutlined } from "@ant-design/icons";


function WaitlistExtraModal({isWaitlistExtraModalVisible,setIsWaitlistExtraModalVisible}) {
    const [email,setEmail] = useState(null);
    const [loading,setLoading] = useState(false);
    const onSubmit = async () => {
        setLoading(true);
        const response = await EmailServices.postWaitlist({email:email});
        if(response.status === 'success'){
            message.success('Successfully added to waitlist');
        }
        setLoading(false);
    }
    useEffect(() => {
        setTimeout(() => {
            if(document.getElementById('email-input')){
                document.getElementById('email-input').focus();
            }
        }, 100);
    },[isWaitlistExtraModalVisible]);

    return(
        <Modal 
            open={isWaitlistExtraModalVisible}
            onCancel={() => setIsWaitlistExtraModalVisible(false)}
            onClose={() => setIsWaitlistExtraModalVisible(false)}
            centered={true}
            style={{borderRadius:'15px',overflow:'hidden',marginBottom:'5vh'}}
            width={'24rem'}
            destroyOnClose
            footer={null}
            >
            <div className="bg-white rounded-lg px-6 py-5 relative">
                <h2 className="text-2xl font-semibold text-center mb-4">
                    Join the Waitlist
                </h2>
                <p className="text-center text-gray-600 mb-6">
                    Be the first to access our virtual mentors! Sign up to get notified when we launch.
                </p>
                <form className="space-y-4">
                    <Input
                        id='email-input'
                        type="email"
                        placeholder="Enter your email"
                        prefix={<MailOutlined className="text-gray-400 pr-1" />}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        autoFocus={true}
                        required/>
                    <button
                        type="submit"
                        loading={loading}
                        onClick={onSubmit}
                        className="w-full bg-indigo-500 text-white py-2 rounded-lg hover:bg-indigo-600">
                        Join Waitlist
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default WaitlistExtraModal;