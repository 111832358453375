function LoadingBubble({app}) {

    return(
        <div className="flex items-center">
            {
                app?.avatar?.image
                ?
                    <div className="w-10 h-10 rounded-full overflow-hidden border border-gray-100">
                        <img src={app?.avatar?.image} alt="avatar" className="w-full h-full object-cover" />
                    </div>
                :
                    <div className="w-10 aspect-square rounded-full flex items-center justify-center overflow-hidden border border-gray-100" 
                        style={{backgroundColor: 'var(--tertiary1)'}}>
                        <div className="text-white text-base md:text-sm leading-none">{app?.name && app.name.slice(0,1)}</div>
                    </div>
            }
            <div className="pl-4">
                <div className="loading-bubble pt-4 rounded-2xl w-fit">
                    <div className="dot" style={{backgroundColor: 'var(--tertiary2)'}}></div>
                    <div className="dot" style={{backgroundColor: 'var(--tertiary2)'}}></div>
                    <div className="dot" style={{backgroundColor: 'var(--tertiary2)'}}></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingBubble