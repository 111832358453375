import { ArrowRightOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useEffect, useState } from "react";
import KeywordNavigation from "../navigation/KeywordNavigation";


const SearchInput = ({keywords,onSearch}) => {
    const searchParams = new URLSearchParams(window.location.search);
    const [input,setInput] = useState('');

    useEffect(() => {
        setInput(searchParams.get('search'));
    },[searchParams.get('search')]);

    return(
        <div className="w-full">
            <Input 
                className="w-full rounded-full" 
                placeholder="Search..."
                value={input}
                onChange={(value) => setInput(value.target.value)}
                prefix={<SearchOutlined className="text-slate-500"/>}
                suffix={
                    <div className="rounded-full px-1 aspect-square cursor-pointer flex items-center justify-center
                                bg-slate-200 hover:bg-slate-300 text-slate-400 hover:text-slate-500 duration-300" 
                            onClick={() => onSearch(input)}>
                        <ArrowRightOutlined/>
                    </div>
                }
                onPressEnter={() => onSearch(input)}
                size='large' />
            {
                keywords && keywords.length > 0 &&
                    <div className="mb-2">
                        <KeywordNavigation keywords={keywords} onClick={onSearch} />
                    </div>
            }
        </div>
    )
}

export default SearchInput;