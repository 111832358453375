import { Avatar, Skeleton } from "antd";


function GeneralAvatar({image,name,loading,size='large'}){
    return(
            !loading
            ?
                image
                ?   <Avatar src={image} size={size} className="border" />
                :   <Avatar size={size} className="bg-black border">{name ? name.slice(0,1) : 'A'}</Avatar>
            :   <Skeleton.Avatar shape="circle" className="border border-slate-200 rounded-full" size={size} active />
    )
}

export default GeneralAvatar;