import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class MessageServices {

    createConversation({agent_id}){
        //
        const values = {
            'agent_id':agent_id
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/conversation/create`,
            data: values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    saveMessage({values}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/messages/create`,
            data: values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    getRecentlyAskedQuestions({agent_id}){
        const configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/messages/by-agent/public?agent_id=${agent_id}&ordering=-updated_at`
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new MessageServices();