import { MenuOutlined, SearchOutlined } from "@ant-design/icons";
import TabButtons from "./Buttons/TabButtons";
import { useNavigate, useParams } from "react-router-dom";
import SearchInput from "../../../../components/search/SearchInput";
import { useEffect, useState } from "react";
import { useCommunityContext } from "../Context/CommunityContext";
import { Button } from "antd";


const CommunityNav = ({keywords,onSearch,type=false}) => {
    const navigate = useNavigate();
    const {order} = useParams();
    const [showSearch,setShowSearch] = useState(false);
    const {setIsSiderVisible,setIsCreatePostModalVisible} = useCommunityContext();

    const clickButton = (path) => {
        const pathParts = window.location.pathname.split('/').filter(Boolean);
        
        if(['Communities','Feed','Topics'].includes(type)){
            const firstTwoPath = pathParts.slice(0, 1).join('/');
            const newUrl = `/${firstTwoPath}${path}`;
            navigate(newUrl);
        }
        else{
            const firstTwoPath = pathParts.slice(0, 2).join('/');
            const newUrl = `/${firstTwoPath}${path}`;
            navigate(newUrl);
        }
    }
    useEffect(() => {
        if(window.innerWidth > 1000){
            setIsSiderVisible(true);
        }
        else {
            setIsSiderVisible(false);
        }
    },[]);

    return(
        <div className={`fixed md:sticky top-0 left-0 md:left-auto right-0 md:right-auto z-30 shadow-xl  ${type === 'community' ? 'bg-white' : 'bg-slate-200'}`}>
            <div className={`w-full md:w-4/5 max-w-[600px] m-auto `}>
                <div className="flex items-center">
                    <div onClick={() => setIsSiderVisible(true)} 
                        className="block md:hidden cursor-pointer rounded-full duration-300 hover:bg-slate-300 px-2 aspect-square flex items-center justify-center">
                        <MenuOutlined />
                    </div>
                    <div className="flex-grow">
                        <div className="w-full md:w-11/12 overflow-x-auto shadow-[2px_0_5px_-2px_rgba(0,0,0,0.3)]">
                            <div className="flex items-center gap-1 py-0.5">
                                <TabButtons name={`Recent ${type ? type : 'Posts'}`} onClick={() => clickButton('')} active={order === undefined} />
                                <TabButtons name={`Top ${type ? type : 'Posts'}`} onClick={() => clickButton('/top')} active={order === 'top'} />
                                {
                                    !['Communities','Feed','Topics'].includes(type) &&
                                        <TabButtons name={'Chat Feed'} onClick={() => clickButton('/sources')} active={order === 'sources'} />
                                }
                            </div>
                            
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <Button type={'outline'} onClick={() => setIsCreatePostModalVisible(true)} 
                            className="w-full md:w-fit rounded-full text-sm bg-slate-100 hover:bg-slate-200 text-slate-600 hover:text-slate-700 
                                        border-slate-300 hover:border-slate-400 duration-300 h-auto hidden md:block">
                            Create Post
                        </Button>
                        <div onClick={() => setShowSearch(prev => !prev)} 
                            className="cursor-pointer rounded-full duration-300 hover:bg-slate-300 
                                        px-2 aspect-square flex items-center justify-center">
                            <SearchOutlined />
                        </div>
                    </div>
                </div>
                {
                    showSearch && type !== 'Communities' &&
                        <div className="py-3 pb-2 px-2 md:px-0">
                            <SearchInput keywords={keywords} onSearch={onSearch} />
                        </div>
                }
            </div>
        </div>
    )

}

export default CommunityNav;