import { DeleteOutlined } from "@ant-design/icons";
import { Carousel, Image } from "antd";

const ImagePreview = ({images,setImages, editting=false}) => {
    const deleteImage = (id) => {
        setImages(images.filter(image => image.id !== id));
    }
    return (
        <div className="w-full flex items-center justify-start gap-1">
            <Carousel infinite={true} arrows={true} draggable={true}>
                {
                    images.map((image,index) => {
                        return <div key={index} className="rounded-lg overflow-hidden w-full border border-gray-200 relative">
                            <Image 
                                key={index} 
                                width={'100%'} 
                                preview={true} 
                                src={image?.image || image} 
                                alt="image"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'  // Cover the square area without distortion
                                }}
                            />
                            {
                                editting &&
                                    <div className="absolute top-0 right-0 cursor-pointer text-red-400 hover:text-red-500 duration-200" onClick={() => deleteImage(image.id)}>
                                        <DeleteOutlined />
                                    </div>
                            }
                        </div>
                    })
                }
            </Carousel>
        </div>
    )
}   
export default ImagePreview;