import { useEffect, useState } from "react";
import Comment from "./Comment";
import CommentInput from "./CommentInput";
import { LoadingOutlined } from "@ant-design/icons";
import ContentServices from "../../utils/ContentServices";
import { useAuth } from "../../../../utils/useAuth";
import { Empty } from "antd";



const CommentThread = ({content_id,showInput=false}) => {
    const {user,refresh} = useAuth();
    const [loading,setLoading] = useState(false);
    const [currentPage,setCurrentPage] = useState(0);
    const [comments,setComments] = useState([]);
    const [commentCount,setCommentCount] = useState(null);
    const [hasMore,setHasMore] = useState(null);

    const getComments = async ({page}) => {
        setLoading(true);   
        let commentsResponse = await ContentServices.getCommentsByContentId({content_id:content_id,page:page,user:user,limit:5});
        if(commentsResponse.status === 401){
            let newUser = await refresh();
            commentsResponse = await ContentServices.getCommentsByContentId({content_id:content_id,page:page,user:newUser,limit:5});
        }
        if(commentsResponse?.count > 0){
            setComments(prev => ([...prev,...commentsResponse?.results]));
            setCommentCount(commentsResponse?.count);
            setCurrentPage(prev => prev+1);
            setHasMore(commentsResponse?.next);
        }
        setLoading(false);
    }
    const getMoreComments = async () => {
        getComments({page:currentPage});
    }

    useEffect(() => {
        getComments({page:0});
    },[content_id])


    return(
        <div className="px-3 py-2">
            <div className="mb-3">
                <CommentInput content_id={content_id} setComments={setComments} defaultShowComment={showInput} />
            </div>
            <div className="flex flex-col gap-2">
                {
                    loading
                    ? null
                    :   comments && comments.length > 0
                        ?
                            comments.map((values,index) => 
                                <div key={`comment-${index}`}>
                                    <Comment values={values} content_id={content_id} comment_id={values?.id} setComments={setComments} />
                                </div>
                            )
                        :   <div>
                                <Empty description='No Comments' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                }
                {
                    comments && comments.length > 0 && hasMore &&
                        <div className="" onClick={getMoreComments}>
                            Load More Comments
                        </div>
                }
                {
                    loading &&
                        <div className="text-xl p-2"><LoadingOutlined/></div>
                }
            </div>
        </div>
    )

}

export default CommentThread;