import { useState } from "react";
import { ConfigProvider, Input, message } from "antd";
import { ArrowUpOutlined, CloseOutlined, CommentOutlined, FileOutlined, LinkOutlined, LoadingOutlined, SignatureOutlined } from "@ant-design/icons";
import { useCommunityContext } from "../Context/CommunityContext";
import ChatBubble from "../../../chat/components/chat/ChatBubble";
import LoadingBubble from "../../../chat/components/chat/LoadingBubble";
import ChatTopBar from "./ChatTopBar";
  
function ChatUI({fullWidth=false}) { 
    let timeElapsed = 0;
    let timerId;
    const {
        app,agent,theme,
        messages,suggestions,
        loadingMessage,setLoadingMessage,sendMessage,sendingMessage,
        chatAttachment, setChatAttachment
    } = useCommunityContext();
    const [input,setInput] = useState('');
    const [inputActive,setInputActive] = useState(false);
    const [hover,setHover] = useState(false);

    const clickSuggestion = async (value) => {
        setInput('');
        await sendMessage({inputValue:value});
        await setLoadingMessage(false);
    }
    const clickEnter = (e) => {
        if(e.ctrlKey || e.shiftKey || window.innerWidth < 1000){
            e.preventDefault();
            if(input !== ''){
                setInput(input + "\n");
            }
            else{
                setInput('');
            }
        } else {
            onMessage();
            setTimeout(() => {
                setInput(null);
            },[500])
        }
    }
    const clickMessage = async (inputValue) => {
        setInput('');
        await sendMessage({inputValue:inputValue});
    }
    const onMessage = async () => {
        // When Sends Message
        timeElapsed = 0;
        if(input != ''){
            clearInterval(timerId);
            timerId= setInterval(() => {
                timeElapsed += 1
            }, 1000);
            await clickMessage(input);
        }
        else{
            message.info('Write a message')
        }
        setLoadingMessage(false);
    }

    return(
        <div className="h-full w-full relative">
            <div className="h-full flex flex-col">
                <ChatTopBar />
                <div id='messager' className={`${fullWidth ? 'w-full': 'w-full md:w-4/5 max-w-[750px]'}
                        overflow-y-auto scroll-smooth flex-grow p-0 m-auto relative`}>
                    <div className="w-full h-auto flex flex-col gap-2 relative pt-8 pb-4" >
                        {
                            !app &&
                                <div className="text-center text-lg p-4"><LoadingOutlined/></div>
                        }
                        <div>
                            {app && (
                                <div className="px-4 text-center text-sm text-gray-500 py-2" style={{color: 'var(--tertiary2)'}}>
                                    {new Date().toLocaleString()}
                                </div>
                            )}
                        </div>
                        {
                            messages && messages.length > 0 &&
                                messages.map((value,index) => {
                                    const isLastUserMessage = index === messages.length - 1 && value['sender'];
                                    return(
                                        <div key={`message-${index}`} id={`message-${index}`} className="px-4 text-lg md:text-base">
                                            <ChatBubble 
                                                app={app} 
                                                text={value['text']} 
                                                attachments={value?.attachments} 
                                                theme={theme} 
                                                sender={value['sender']} 
                                                sendingMessage={isLastUserMessage && sendingMessage} 
                                                timestamp={value?.timestamp} 
                                            />
                                        </div>
                                    )
                                })
                        }
                        {
                            app && loadingMessage &&
                                <div className="px-4">
                                    <LoadingBubble app={app} />
                                </div>
                        }
                    </div>
                </div>
                <div className={`${fullWidth ? 'w-full': 'w-full md:w-4/5 max-w-[750px] '}
                    p-0 m-auto p-2 md:p-4 pt-1 md:pt-1 pb-2 h-fit flex flex-col gap-1 relative rounded-none`} 
                    style={{backgroundColor:'var(--primary)'}}>
                    {
                        (suggestions || agent?.suggested_messages) &&
                            <div id='suggestions' className="p-1 pt-0.5 w-full overflow-x-scroll flex items-center gap-1 hide-scrollbar">
                                <div className="text-lg" style={{color: 'var(--tertiary2)'}}>
                                    <SignatureOutlined/>
                                </div>
                                {
                                    ((agent && agent?.suggested_messages) || (suggestions && suggestions.length > 0))
                                    ?
                                        suggestions && suggestions.length > 0
                                        ?
                                            suggestions.slice(0,10).map((value,index) =>
                                                <div key={`suggestions-${index}`} 
                                                    onClick={() => clickSuggestion(value)} id={`suggestion-${index}`} 
                                                    className={`p-2 md:py-1 pr-3 border rounded-xl text-left w-fit text-nowrap cursor-pointer ${loadingMessage ? "hover:cursor-wait" : ""}`} 
                                                    style={{color: 'var(--tertiary2)',borderColor: 'var(--tertiary2)'}}>
                                                    {value}
                                                </div>                                    
                                            )
                                        :
                                            agent?.suggested_messages.map((value,index) =>
                                                <div key={`suggestions-${index}`} 
                                                    onClick={() => clickSuggestion(value)} id={`suggestion-${index}`} 
                                                    className={`p-2 md:py-1 pr-3 border rounded-xl text-left w-fit text-nowrap cursor-pointer ${loadingMessage ? "hover:cursor-wait" : ""}`} 
                                                    style={{color: 'var(--tertiary2)',borderColor: 'var(--tertiary2)'}}>
                                                    {value}
                                                </div>
                                            )
                                    : null
                                }
                            </div>
                    }
                    {
                        chatAttachment &&
                            <div className="w-full rounded-xl bg-slate-100 p-2">
                                <div className="grid grid-cols-12 flex items-center flex-wrap gap-2 w-full">
                                    <div className="col-span-2">
                                        {
                                            chatAttachment?.image || chatAttachment?.image_url
                                            ?   <div className="w-full rounded-lg aspect-video bg-cover bg-center bg-no-repeat" style={{backgroundImage:`url(${chatAttachment?.image || chatAttachment?.image_url})`}}></div>
                                            :
                                                <div className="px-1 aspect-square flex items-center justify-center text-xl text-slate-400">
                                                    {
                                                        {
                                                            'link':<LinkOutlined/>,
                                                            'file':<FileOutlined/>
                                                        }[chatAttachment?.type]
                                                    }
                                                </div>
                                        }
                                    </div>
                                    <div className="col-span-9 w-full text-left">
                                        <div className="font-medium text-sm text-slate-500 leading-tight pr-4">
                                            {chatAttachment?.title || (chatAttachment?.content ? chatAttachment?.content.slice(0,30) : '')}
                                        </div>
                                    </div>
                                    <div onClick={() => setChatAttachment(null)} className="col-span-1 px-2 aspect-square flex items-center justify-center text-base cursor-pointer
                                        text-slate-400 hover:text-slate-600 duration-300">
                                        <CloseOutlined/>
                                    </div>
                                </div>
                            </div>
                    }
                    <div className="w-full flex items-center gap-1">
                        <div className={`flex-1 flex items-end rounded-3xl py-1 md:py-0 px-1 duration-300 shadow-md
                            ${(hover||inputActive) ? "opacity-100" : "opacity-70"}
                            ${(loadingMessage || (messages && messages.length === 0)) ? "cursor-wait" : ""}`} 
                            onMouseEnter={() => {if(!loadingMessage){setHover(true)}}}
                            onMouseLeave={() => {if(!loadingMessage){setHover(false)}}}
                            style={{backgroundColor: 'var(--tertiary2)',borderColor: 'var(--tertiary2)'}}>
                            <div className={`p-2 text-lg md:text-base ${(loadingMessage || (messages && messages.length === 0)) ? "cursor-wait" : ""}`}>
                                <div style={{color: 'var(--secondary)', opacity:input ? 1 : 0.5}}><CommentOutlined/></div>
                            </div>
                            <div className="flex-1 pb-1 md:pb-0.5">
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            "colorTextPlaceholder": 'var(--primary-lt)'
                                        },
                                    }}
                                    >
                                    <Input.TextArea
                                        size='large'
                                        placeholder={`Message ${app?.name}`}
                                        value={input}
                                        onChange={(value) => setInput(value.target.value)}
                                        onPressEnter={clickEnter}
                                        onFocus={() => setInputActive(true)}
                                        onBlur={() => setInputActive(false)}
                                        className={`text-lg md:text-base w-full leading-tight pl-0  ${(loadingMessage || (messages && messages.length === 0)) ? "cursor-wait" : ""}`}
                                        style={{lineHeight:window.innerWidth > 1000 ? 1.5 : 1.2,color: 'var(--primary)'}}
                                        variant="borderless"
                                        disabled={loadingMessage || (messages && messages.length === 0)}
                                        autoSize={{maxRows:6}}
                                        autoFocus
                                        />
                                </ConfigProvider>
                            </div>
                            <div className="p-2">
                                <div onClick={onMessage} 
                                    className="text-lg md:text-base rounded-full px-1 aspect-square flex items-center justify-center cursor-pointer hover:brightness-125 duration-150" 
                                    style={input ? {backgroundColor: 'var(--secondary)', color: 'var(--tertiary2)'} : {color: 'var(--secondary)'}}>
                                    <ArrowUpOutlined/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-xs" style={{color: 'var(--tertiary2)'}}>
                        Check important details.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatUI;