import ImagePreview from "./ImagePreview";
import ProductPreview from "./ProductPreview";
import PlacePreview from "./PlacePreview";
import FilePreview from "./FilePreview";
import LinkPreview from "./LinkPreview";

const InputPreview = ({setImages,setFiles,setLinks,clickTalk,files=[],images=[],links=[],products=[],places=[],editting=false,editable=false}) => {
    return (
        <div className="flex flex-col gap-2 items-start w-full px-2">
            {   
                images && images.length > 0 && 
                    <div className="w-full overflow-x-auto">
                        <ImagePreview images={images} setImages={setImages} editting={editting} />
                    </div>                
            }
            {   
                links && links.length > 0 && 
                    <div className="w-full overflow-x-auto">
                        <LinkPreview links={links} setLinks={setLinks} editting={editting} editable={editable} clickTalk={clickTalk} />
                    </div>
            }
            {   
                files && files.length > 0 && 
                    <div className="w-full overflow-x-auto">
                        <FilePreview files={files} setFiles={setFiles} editting={editting} clickTalk={clickTalk} />
                    </div>
            }
            {products && products.length > 0 && <ProductPreview products={products} clickTalk={clickTalk}/>}
            {places && places.length > 0 && <PlacePreview places={places} clickTalk={clickTalk}/>}
        </div>
    )
}
export default InputPreview;