import { useEffect, useRef, useState } from "react";
import { Button, Input, message, Tooltip } from "antd";
import { FaMagic } from "react-icons/fa";
import { FileOutlined, LinkOutlined, LoadingOutlined, PictureOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import UserAvatar from "../../../dashboard/components/avatar/UserAvatar";
import AIInput from "../../../dashboard/components/input/AIInput";
import QuestionMetadataInput from "../../../dashboard/components/input/metadata/QuestionMetadataInput";
import { useCommunityContext } from "../Context/CommunityContext";
import { useAuth } from "../../../../utils/useAuth";
import ContentServices from "../../utils/ContentServices";
import AddLinkModal from "./AddLinkModal";
import InputPreview from "../Content/preview/InputPreview";

function CreateContentInput({minRows=6,fullPage=false}) {
    const inputRef = useRef();
    const imageRef = useRef();
    const fileRef = useRef();
    const {user,refresh} = useAuth();
    const {app,appIndex,setContent,memberStatus,openLoginModal} = useCommunityContext();
    const [text,setText] = useState(null);
    const [images,setImages] = useState([]);
    const [files,setFiles] = useState([]);
    const [links,setLinks] = useState([]);
    const [metadata,setMetadata] = useState({});
    const [loading,setLoading] = useState(false);
    const [uploading,setUploading] = useState({});
    const [agentOptions,setAgentOptions] = useState([]);
    const [showAIAssist,setShowAIAssist] = useState(false);
    const [isAddLinkModalVisible,setIsAddLinkModalVisible] = useState(false);

    const initialSetup = () => {
        inputRef.current.focus();
        const agents = app?.agents.map((value) => ({'id':value?.id,'name':value?.name,'is_active':true}));
        setAgentOptions(agents);
    }
    const clickPost = async () => {
        if(user){
            if(memberStatus){
                let values = {
                    'agent_ids':agentOptions.map(value => value?.id).filter(value=>value),
                    'content':text,
                    'image_ids':images.map(value => value?.id).filter(value=>value),
                    'file_ids':files.map(value => value?.id).filter(value=>value),
                    'link_ids':links.map(value => value?.id).filter(value=>value),
                    'organization_id':app?.organization_id,
                    'application_id':app?.id,
                    'metadata':metadata,
                    'is_complete':false
                }
                setLoading(true);
                if(text && text !== ''){
                    let contentResponse = await ContentServices.postContent({values:values,user:user});
                    if(contentResponse.status === 401){
                        let newUser = await refresh();
                        contentResponse = await ContentServices.postContent({values:values,user:newUser});
                    }
                    if(contentResponse.status === 'success'){
                        setText(null);
                        setImages([]);
                        setFiles([]);
                        setLinks([]);
                        setMetadata({});
                        setContent(prev => [contentResponse.data,...prev]);
                        message.success('Content has been posted!');
                    }
                }
                else{
                    message.info('Add some text to post!');
                }
                setLoading(false);
            }
            else{
                message.info('Join the Group to Post!');
            }
        }
        else{
            message.info('Must be logged in to post in this group.');
            openLoginModal({});
        }
    }
    const clickMetadata = (value) => {
        if(metadata?.type){
            if(metadata.type !== value){
                setMetadata({'type':value})
            }
            else{
                setMetadata({});
            }
        }
        else{
            setMetadata({'type':value})
        }
    }
    const uploadImage = async (file) => {
        let data = {
            'organization_id':app?.organization_id,
            'application_id':app?.id
        }
        let imageResponse = await ContentServices.postImage({image:file,name:`${user.username}-image-${file.name}`,data:data,user:user});
        if(imageResponse.status === 401){
            let newUser = await refresh();
            imageResponse = await ContentServices.postImage({image:file,name:`${user.username}-image-${file.name}`,data:data,user:newUser});
        }
        if(imageResponse.status === 'success'){
            setImages(prev => ([...prev,imageResponse.data]));
        }
    }
    const uploadFile = async (file) => {
        let data = {
            'organization_id':app?.organization_id,
            'application_id':app?.id
        }
        let fileResponse = await ContentServices.postFile({file:file,name:`${user.username}-file-${file.name}`,data:data,user:user});
        if(fileResponse?.status === 401){
            let newUser = await refresh();
            fileResponse = await ContentServices.postFile({file:file,name:`${user.username}-file-${file.name}`,data:data,user:newUser});
        }
        if(fileResponse?.status === 'success'){
            setFiles(prev => ([...prev,fileResponse.data]));
        }
        else if(fileResponse?.data?.data?.file && fileResponse?.data?.data?.file?.[0].includes('Ensure this filename has at most')){
            message.info('Filename must be at most 100 characters');
        }
    }
    const fileUpload = async (event,fileType) => {
        if(fileType === 'file'){
            setUploading(prev => ({...prev,'file':true}));
        }
        else if(fileType === 'image'){
            setUploading(prev => ({...prev,'image':true}));
        }
        var file = event.target.files[0];
        if(file){
            var reader = new FileReader();
            reader.readAsDataURL(file);
    
            let uploadResponse;
            if(fileType === 'file'){
                uploadResponse = await uploadFile(file);
                setUploading(prev => ({...prev,'file':false}));
            }
            else if(fileType === 'image'){
                uploadResponse = await uploadImage(file);
                setUploading(prev => ({...prev,'image':false}));
            }
        }
    };

    useEffect(() => {
        initialSetup();
    },[app,appIndex]);

    return(
        <div className={`bg-white p-3 pt-4 flex items-start w-full gap-2 z-60 
            ${fullPage ? 'h-full w-full flex-col' : 'shadow-xl rounded-3xl'}`}>
            <div className={fullPage ? 'p-2 flex items-center gap-2' : ''}>
                <UserAvatar app={user}/>
                {
                    fullPage &&
                        <div className="text-slate-600 text-base font-medium">
                            {user?.username}
                        </div>
                }
            </div>
            <div className="flex-1 w-full">
                {
                    metadata?.type &&
                        {
                            'question':<QuestionMetadataInput setMetadata={setMetadata} />
                        }[metadata?.type]
                }
                <div className="w-full flex gap-2 items-start">
                    <div className="flex-1">
                        <Input.TextArea 
                            ref={inputRef}
                            placeholder="Just write..."
                            variant="borderless"
                            id='content-input'
                            size="large"
                            className={`font-medium ${fullPage ? "text-xl" : "text-lg"}`}
                            value={text}
                            autoFocus
                            onChange={(value) => setText(value.target.value)}
                            autoSize={{minRows:minRows}}
                            />
                        {
                            showAIAssist &&
                                <AIInput setGeneratedText={setText} />
                        }
                    </div>
                    <div className="flex flex-col text-base">
                        <div className="text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 cursor-pointer px-1.5 aspect-square flex items-center justify-center rounded-lg">
                            <Tooltip title="AI Assisted">
                                <div onClick={() => setShowAIAssist(!showAIAssist)}><FaMagic/></div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={`flex ${fullPage ? 'flex-col w-full items-start' : 'items-center'}`}>
                    <div className={`flex-1 flex items-center p-1 ${fullPage ? 'gap-1 text-2xl' : 'text-xl'}`}>
                        <div className="text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 cursor-pointer px-1.5 aspect-square flex items-center justify-center rounded-lg">
                            <Tooltip title="Attach Question">
                                <div onClick={() => clickMetadata('question')}>
                                    <QuestionCircleOutlined/>
                                </div>
                            </Tooltip>
                        </div>
                        <div onClick={() => imageRef.current.click()} 
                            className={`text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 px-1.5 aspect-square flex items-center justify-center rounded-lg
                            ${uploading?.image ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer"}`}>
                            <Tooltip title="Attach Image">
                                {uploading?.image ? <LoadingOutlined/> : <PictureOutlined/>}
                            </Tooltip>
                            <input type='file' id='file' ref={imageRef} accept="image/*" onChange={(event) => fileUpload(event,'image')} style={{display: 'none'}}/>
                        </div>
                        <div onClick={() => fileRef.current.click()}
                            className={`text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 px-1.5 aspect-square flex items-center justify-center rounded-lg
                            ${uploading?.file ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer"}`}>
                            <Tooltip title="Attach Files">
                                {uploading?.file ? <LoadingOutlined/> : <FileOutlined/>}
                            </Tooltip>
                            <input type='file' id='file' ref={fileRef} accept=".txt, .py, .md, .csv, .json, .html, .xml, .doc, .docx, .pptx, .pdf" 
                                    onChange={(event) => fileUpload(event,'file')} style={{display: 'none'}}/>
                        </div>
                        <div onClick={() => setIsAddLinkModalVisible(true)} 
                            className={`text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 px-1.5 aspect-square flex items-center justify-center rounded-lg
                            ${uploading?.link ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer"}`}>
                            <Tooltip title="Attach Link">
                                {uploading?.link ? <LoadingOutlined/> : <LinkOutlined/>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className={fullPage ? `w-full p-2` : ""}>
                        <Button 
                            type='primary'
                            loading={loading || Object.values(uploading).some(value => value === true)}
                            disabled={loading || Object.values(uploading).some(value => value === true)}
                            size={fullPage ? 'large' : 'middle'}
                            className={
                                fullPage 
                                ? "w-full rounded-full text-base h-auto"
                                : "py-1.5 font-medium rounded-full"}
                            onClick={clickPost}>Post</Button>
                    </div>
                </div>
                {
                    (images.length > 0 || files.length > 0 || links.length > 0) &&
                        <div className="w-full py-2">
                            <InputPreview 
                                images={images} setImages={setImages} 
                                files={files} setFiles={setFiles} 
                                links={links} setLinks={setLinks} 
                                editable={true}
                                editting={true} />
                        </div>
                }
            </div>
            <AddLinkModal isAddLinkModalVisible={isAddLinkModalVisible} setIsAddLinkModalVisible={setIsAddLinkModalVisible} setLinks={setLinks} />
        </div>
    )
}

export default CreateContentInput;
