import { message } from "antd";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 800, 800, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class UserServices {
    async uploadAvatarImage({image, name, user=false}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/avatar",
            data: formData,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};
        }
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    getUserAvatar({user_id}){
        var configAPIUrl = API_URL + `/user/${user_id}/avatar`;
        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            error = new Error();
        });
    }

    // User Details
    editUserDetails({user, values}) {
        if(user){
            const configuration = {
                credentials: 'include',
                method: "patch",
                url: API_URL + `/user/${user.user_id}`,
                headers: {"Authorization" : `Bearer ${user.access}`},
                data:values
            };
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response
                error = new Error();
            });
        }
    };
    getUser({user_id,user}) {
        if(user){
            const configuration = {
                credentials: 'include',
                method: "get",
                url: API_URL + `/user/${user_id}`,
                headers: {"Authorization" : `Bearer ${user.access}`},
            };
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                error = new Error();
            });
        }
    };

    // User Rankings
    getUserList({page=0, limit=12, ordering='-updated_at', username=false, search=false, filters=false}) {

        const offset = limit * page || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/users?limit=${limit}&offset=${offset}&ordering=${ordering}&is_active=True`}
        else { configAPIUrl = API_URL + `/users?limit=${limit}&ordering=${ordering}&is_active=True` };

        if(username){
            configAPIUrl = configAPIUrl + `&username=${username}`;
        }
        if(search){
            configAPIUrl = configAPIUrl + `&search=${search}`;
        }
        if(filters?.category && filters?.category.length > 0){
            configAPIUrl = configAPIUrl + `&category__name=${filters?.category.join(',')}`;
        }
        if(filters?.socials && filters?.socials.length > 0){
            configAPIUrl = configAPIUrl + `&socials_onboard__type=${filters?.socials.join(',')}`;
        }
        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };

        // Send POST Query with Login Info
        return axios(configuration)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            error = new Error();
        });
    }
}
export default new UserServices();