import { useState } from "react";
import Modal from "antd/es/modal/Modal";
import { Button, ConfigProvider, Input, message, Switch } from "antd";
import ContentServices from "../../utils/ContentServices";
import { useAuth } from "../../../../utils/useAuth";

function AddLinkModal({isAddLinkModalVisible,setIsAddLinkModalVisible,setLinks}){
    const {user,refresh} = useAuth();
    const [url,setUrl] = useState('');
    const [scrape,setScrape] = useState(true);
    const [loading,setLoading] = useState(false);

    const focusInput = async () => {
        const element = document.getElementById('link-input');
        if(element){
            const fieldElement = document.getElementById('link-input-div');
            await fieldElement.classList.remove("bounce-sm");
            element.focus();
            fieldElement.classList.add("bounce-sm");
        }
        else{
            setTimeout(() => {
                focusInput();
            },500)
        }
    }

    const clickAddLink = async () => {
        setLoading(true);
        const metadataResponse = await ContentServices.fetchMetadata({url:url.split('?')[0],user:user});
        if(metadataResponse?.status === 'success'){
            let data = metadataResponse?.data
            let metadata = {
                'url':data?.['url'] || url,
                'title':data?.['title'] || '',
                'description':data?.['description'] || '',
                'image_url':data?.['image_url'] || ''
            }
            let contentResponse = await ContentServices.addLink({values:metadata,user:user});
            if(contentResponse?.status === 401){
                let newUser = await refresh();
                contentResponse = await ContentServices.addLink({values:metadata,user:newUser});
            }
            if(contentResponse?.status === 'success'){
                setLinks(prev => ([...prev,contentResponse?.data]));
                message.success('Added Link');
                setIsAddLinkModalVisible(false);
            }
            else{
                message.info('Error Try again');
            }
        }
        else{
            message.info('Error Occurred. Check link.');
        }

        setLoading(false);
    }

    const closeModal = () => {
        setIsAddLinkModalVisible(false);
    }
    return(
        <ConfigProvider
            theme={{
                token: {
                    padding:16
                }}}>
            <Modal
                open={isAddLinkModalVisible} 
                footer={null} 
                onCancel={closeModal}
                width={'100%'}
                style={{maxWidth:'460px',overflow:'hidden',marginBottom:'5vh',backgroundColor:'#1A73E8'}}
                className="shadow-xl rounded-3xl"
                centered
                closable={true}
                destroyOnClose
                >
                <div className="p-2">
                    <div className="text-white flex items-center justify-center">
                        <div className="w-full flex flex-col gap-2">
                            <div className="text-2xl text-slate-600 font-semibold">
                                Attach a Link
                            </div>
                            <div className="flex items-start gap-1">
                                <div className="flex-1" id='link-input-div'>
                                    <Input id='link-input' 
                                        size='large' 
                                        placeholder="ie. https://www.link.com/"
                                        onChange={(value) => setUrl(value.target.value)} />
                                    {
                                        loading &&
                                            <div className="text-xs text-slate-300 p-1 pb-0">
                                                Verifying Link...
                                            </div>
                                    }
                                </div>
                                <Button size="large" loading={loading} disabled={loading} onClick={clickAddLink}>
                                    Attach
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    )
}

export default AddLinkModal;