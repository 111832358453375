import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Button, Layout } from "antd";
import { Helmet } from "react-helmet-async";
import LoadingPage from "../../landing/LoadingPage";
import { useCommunityContext } from "../components/Context/CommunityContext";
import ChatSider from "../components/Chat/ChatSider";
import ExploreSider from "../components/Explore/ExploreSider";
import PageNav from "../components/Nav/PageNav";
import ContentServices from "../utils/ContentServices";
import CommunityNav from "../components/Feed/CommunityNav";
import ContentLoadingCard from "../components/Content/ContentLoadingCard";
import SearchInput from "../../../components/search/SearchInput";
import KeywordNavigation from "../../../components/navigation/KeywordNavigation";
import CreateContentInput from "../components/Create/CreateContentInput";
import EditContentInput from "../components/Content/EditContentInput";
import { useAuth } from "../../../utils/useAuth";
import { useParams } from "react-router-dom";

const { Content } = Layout;

function FeedPage() {
    const loader = useRef(null);
    const {user} = useAuth();
    const {order} = useParams();
    const {setApp,isChatSiderVisible,setIsChatSiderVisible,
        setSuggestions,setMessages,setFilters,
        keywords, setKeywords
    } = useCommunityContext();
    const {content,setContent} = useCommunityContext();
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isFeedLoading, setIsFeedLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [showInput, setShowInput] = useState(false);
    const [totalCount, setTotalCount] = useState(0);

    // Apply theme variables
    const themeVars = {
        "--primary": "#171717",
        "--primary-lt": "rgba(255,255,255,0.9)",
        "--secondary": "#232323",
        "--tertiary1": "#ececec",
        "--tertiary2": "#ececec",
    };

    const intialGetFeed = async () => {
        setIsLoading(true);
        setIsFeedLoading(true);
        let defaultApp = {
            'name':'Feed AI',
            'agents':[{
                'name':`Feed`,
                'intro_message':`Hey it's Feed AI.\nAsk me anything about topics you see in the feed!`,
                'suggested_messages':[]
            }],
        }
        setApp(defaultApp);
        setIsLoading(false);
        getFeed({page:0,limit:5});
    }
    const onSearch = (value) => {
        const url = new URL(window.location.href);
        url.searchParams.set('search',value);
        window.history.replaceState(null, '', url);

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setContent([]);
        getFeed({page:0,limit:5});
    }
    const getFeed = async ({page,limit=5,tags=false,organization=false,author=false}) => {
        setIsFeedLoading(true);
        try {
            let params = {
                filters:{},
                limit:limit,
                page:page
            }
            if(tags){
                params['filters']['tag'] = tags;
            }
            if(organization){
                params['filters']['organization'] = [organization];
            }
            if(author){
                params['filters']['author'] = [author];
            }
            setFilters(params['filters']);

            const contentResponse = await ContentServices.getContent(params);
            if(contentResponse?.count){
                setContent(prev => ([...prev,...contentResponse.results]));
                setTotalCount(contentResponse.count);
                setCurrentPage((prevPage) => prevPage + 1);
            }
            if(content.length >= contentResponse.count){
                setHasMore(false);
            }
            setIsFeedLoading(false);
            setKeywords(contentResponse.results.map((value) => value?.tags).flat().sort(() => Math.random() - 0.5).slice(0,50));

        } catch (error) {
            console.error("Error fetching content:", error);
            setIsFeedLoading(false);
        }
        
    }
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading && !isFeedLoading && hasMore) {
            setTimeout(() => {
                getFeed({page:currentPage,limit:10});
            },[500]);
        }
    }, [isFeedLoading]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
        if (loader.current) observer.observe(loader.current);
        return () => observer.disconnect();
    }, [handleObserver]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setContent([]);
        setMessages([]);
        setSuggestions([]);
        intialGetFeed();
    },[order]);

    return(
        !isLoading
        ?
            <Layout style={{...themeVars}} className="w-full h-auto md:h-screen">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`Feed Page | TalkInBio`}</title>
                    <meta property="og:title" content={`Feed Page | TalkInBio Feed`}/>
                    <meta name="description" content={`Feed Page | TalkInBio Feed`} />
                </Helmet>
                <Layout className="w-full h-full relative">
                    <PageNav/>
                    <Suspense fallback={null}>
                        <ExploreSider />
                    </Suspense>
                    <Content className="w-full h-full">
                        <Suspense fallback={<LoadingPage/>}>
                            <div className="pt-16 md:pt-0 w-full h-auto md:h-screen overflow-y-auto">
                                <CommunityNav keywords={keywords} onSearch={onSearch} type={'Feed'} />
                                <div className="w-full md:w-4/5 max-w-[600px] h-auto m-auto px-2 md:px-0 pb-40">
                                    <div className="pt-8 text-left mb-2">
                                        <h1 className="text-2xl font-bold text-slate-600">
                                            Just Scroll
                                        </h1>
                                    </div>
                                    <div className="py-2">
                                        <SearchInput keywords={keywords} onSearch={onSearch} />
                                    </div>
                                    <div className="flex flex-col gap-2 mb-4">
                                        {
                                            showInput
                                            ?   <div className="pt-2"><CreateContentInput /></div>
                                            :   <Button type='outline' onClick={() => setShowInput(prev=>!prev)} size='large' className="w-full bg-slate-50 hover:bg-slate-100 border border-slate-300 text-slate-400 hover:text-slate-500 duration-300 rounded-full">
                                                    Add Content
                                                </Button>
                                        }
                                    </div>
                                    {
                                        content && content.length > 0 &&
                                            <div className="mb-4">
                                                <div className="w-full h-full m-auto flex flex-col gap-6">
                                                    <div className="w-full h-full flex flex-col gap-4">
                                                        {
                                                            content.map((value,index) => 
                                                                <div key={`content-${index}`} className="w-full">
                                                                    <EditContentInput 
                                                                            author={value?.author} 
                                                                            values={value} 
                                                                            index={index} 
                                                                            isEditable={user && value?.author?.id === user?.user_id} />
                                                                    {
                                                                        (content.length - 3 === index) &&
                                                                            <div ref={loader}  className="w-full h-2"></div>
                                                                    }
                                                                </div>
                                                            )                            
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    <div className="flex flex-col gap-5" >
                                        {
                                            isFeedLoading &&
                                                [1,2,3,4].map((_,index) => 
                                                    <div key={`loader-${index}`} className="w-full">
                                                        <ContentLoadingCard />
                                                    </div>
                                                )
                                        }
                                    </div>
                                    {
                                        hasMore === false &&
                                            <div className="pt-8">
                                                <div className="mb-2 text-left px-4">
                                                    <h2 className="text-2xl font-bold">Explore More Topics</h2>
                                                </div>
                                                <div className="text-center">
                                                    <KeywordNavigation 
                                                            orientation={'center'}
                                                            keywords={keywords}
                                                            defaultShow={true} 
                                                            onClick={onSearch} />
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </Suspense>
                    </Content>
                    <Suspense fallback={<LoadingPage/>}>
                        <ChatSider isSiderVisible={isChatSiderVisible} setIsSiderVisible={setIsChatSiderVisible} />
                    </Suspense>
                </Layout>
            </Layout>
        :   <LoadingPage/>
    )
}

export default FeedPage;
