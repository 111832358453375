import { Button, ConfigProvider, Drawer, Popover } from "antd";
import { useCommunityContext } from "../Context/CommunityContext";
import React, { useEffect, useState } from "react";
import { HomeOutlined, MenuOutlined, RiseOutlined, TagOutlined, TeamOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../utils/useAuth";
import AccountDropdown from "../Account/AccountDropdown";
import Sider from "antd/es/layout/Sider";
import { Link, useNavigate } from "react-router-dom";
import CommunityServices from "../../utils/CommunityServices";
import GeneralAvatar from "../../../../components/avatar/GeneralAvatar";

const MenuItem = ({name,icon,onClick,active=false}) => {
    return(
        <div onClick={onClick} className={`p-2 hover:bg-slate-100 rounded-2xl duration-300 cursor-pointer
            ${active ? 'bg-slate-100' : ''}`}>
            <div className="flex items-center gap-3 text-slate-600">
                <div className="text-2xl">
                    {icon}
                </div>
                <div className="text-base font-semibold">
                    {name}
                </div>
            </div>
        </div>
    )
}

const ExploreContent = () => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const {setIsSiderVisible,setIsLoginModalVisible,openCreateCommunity,exploreCommunities,setExploreCommunities} = useCommunityContext();
    const [loading,setLoading] = useState(false);
    

    const getCommunities = async () => {
        setLoading(true);
        const communitiesResponse = await CommunityServices.getCommunities({page:0,limit:8})
        if(communitiesResponse?.results){
            setExploreCommunities(communitiesResponse?.results);
        }
        setLoading(false);
    }

    const clickItem = (value) => {
        if(window.innerWidth < 1000){
            setIsSiderVisible(false);
        }
        navigate(value);
    }

    useEffect(() => {
        getCommunities();
    },[]);

    return(
        <div className="w-full h-full max-h-screen relative">
            <div className="absolute top-0 left-0 right-0 bottom-0 pt-18 p-3 flex flex-col" >
                <div className="flex-shrink-0 flex items-center">
                    <div className="flex-1">
                        <div onClick={() => setIsSiderVisible(prev => !prev)} 
                            className="text-xl md:text-lg cursor-pointer w-fit rounded-lg aspect-square px-2 bg-hover border-2 duration-150 flex items-center justify-center" >
                            <MenuOutlined />
                        </div>
                    </div>
                </div>
                <div className="flex-grow pt-8 flex flex-col overflow-y-scroll">
                    <div className="flex-shrink-0 flex flex-col gap-1">
                        <MenuItem name={'Home'} icon={<HomeOutlined/>} onClick={() => clickItem('/feed')} />
                        <MenuItem name={'Trending'} icon={<RiseOutlined/>} onClick={() => clickItem('/feed/top')} />
                        <MenuItem name={'Topics'} icon={<TagOutlined />} onClick={() => clickItem('/topics')} />
                        <MenuItem name={'Communities'} icon={<TeamOutlined/>} onClick={() => clickItem('/communities')} />
                        <div className="mt-4">
                            <Button type='outline' onClick={openCreateCommunity} size='large' className="w-full rounded-full bg-slate-100 hover:bg-slate-200 text-slate-600 hover:text-slate-700 ">
                                Create a Community
                            </Button>
                        </div>
                        <div className="p-1 text-left text-sm text-slate-700">
                            Recently Active
                        </div>
                    </div>
                    <div className="flex-grow py-2 flex flex-col overflow-y-scroll hide-scrollbar">
                        <div className="flex flex-col gap-1">
                            {
                                exploreCommunities && exploreCommunities.length > 0 &&
                                    exploreCommunities.map((value,index) => 
                                        <Link key={`community-${index}`} to={`/group/${value?.path}`} >
                                            <div className="w-full border text-left rounded-xl p-2 cursor-pointer hover:bg-slate-100 duration-300">
                                                <div className="flex items-center gap-2">
                                                    <div className="flex-shink-0">
                                                        <GeneralAvatar size='small' image={value?.avatar?.image} name={value?.name} />
                                                    </div>
                                                    <h2 className="text-base font-medium" style={{lineHeight:1.2}}>{value?.name}</h2>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                            }
                        </div>
                    </div>
                </div>
                <div className="flex-shrink-0 p-4 flex flex-col gap-2">
                    <div>
                        {
                            user
                            ?   <div>
                                    <AccountDropdown />
                                </div>
                            :   <div>
                                    <Button type='primary' size='large' 
                                        onClick={() => setIsLoginModalVisible(true)} 
                                        className="w-full rounded-full">
                                        Login / Register
                                    </Button>
                                </div>
                        }
                    </div>
                    <div className="text-slate-500">
                        <a href='/@TalkInBio?chat=Feedback' target='_blank' >
                            Leave us feedback
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ExploreSider = React.memo(function ExploreSider() {
    const {isSiderVisible,setIsSiderVisible,setIsLoginModalVisible,app} = useCommunityContext();

    return(
        window.innerWidth > 1000
        ?
            <Sider
                width={window.innerWidth * 0.2 > 250 ? '20%' : 250}
                collapsedWidth={0}
                collapsed={!isSiderVisible}
                trigger={null}
                className="shadow-3xl"
                style={{minWidth:'300px'}}
                collapsible>
                <div className="w-full h-full" style={{backgroundColor:"white"}}>
                    <ExploreContent app={app} setIsSiderVisible={setIsSiderVisible} setIsLoginModalVisible={setIsLoginModalVisible} />
                </div>
            </Sider>
        :
        <ConfigProvider
            theme={{
                token: {
                    padding:0,
                },
            }}>
            <Drawer 
                open={isSiderVisible}
                onClose={() => setIsSiderVisible(false)}
                onCancel={() => setIsSiderVisible(false)}
                closeIcon={null}
                width={window.innerWidth > 1000 ? '30%':'100%'}
                height={'100%'}
                placement="left"
                closable={true}
                styles={{
                    "content":{
                        minWidth:'300px'
                    },
                    "body":{
                        padding:0
                    },
                    "header":{
                        display:'none'
                    }
                }}
                >
                <ExploreContent app={app} setIsSiderVisible={setIsSiderVisible} setIsLoginModalVisible={setIsLoginModalVisible} />
            </Drawer>
        </ConfigProvider>
    )
});

export default ExploreSider;