import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class FileServices {
    async postFile({file, name, user=false}) {
        if(user){
            let formData = new FormData();
            formData.append('file', file, `${name}`);
            formData.append('name', name);
    
            let configuration = {
                credentials: 'include',
                method: "post",
                url: API_URL + "/file",
                headers:{"Authorization" : `Bearer ${user.access}`},
                data: formData,
            };
        
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
    }
}
export default new FileServices();