import { Avatar, Button } from "antd";
import GeneralAvatar from "../avatar/GeneralAvatar";
import { ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const CommunityCard = ({values,openShareLink}) => {

    return(
        <Link to={`/group/${values?.path}`} >
            <div className="rounded-2xl w-full border shadow-md bg-white duration-300 text-slate-600 hover:text-slate-800 overflow-hidden cursor-pointer">
                <div className="w-full aspect-[5/1] bg-cover bg-center bg-no-repeat"
                    style={{backgroundImage:`url(${values?.banner_image?.image})`}}>
                </div>
                <div></div>
                <div className="text-left p-8 flex flex-col gap-4">
                    <div className="flex items-center">
                        <div className="flex-1 flex items-center gap-2">
                            <Avatar size='medium' src={values?.avatar?.image} />
                            <h2 className="text-lg font-semibold">{values?.name}</h2>
                        </div>
                        <div onClick={() => openShareLink({'type':'Community','name':values?.name,'link':`/group/${values?.path}`})}  className="text-slate-500 text-lg px-2 aspect-square flex items-center justify-center rounded-full hover:bg-slate-100 duration-300 cursor-pointer">
                            <ShareAltOutlined />
                        </div>
                    </div>
                    <div className="leading-snug text-base">
                        {values?.description.slice(0,200)}...
                    </div>
                    <div className="flex items-center text-left gap-2">
                        <Avatar.Group max={3}>
                            {
                                values?.members_preview.map((value,index) => 
                                    <GeneralAvatar key={`avatar-${index}`} image={value?.avatar?.image || value?.avatar} name={value?.name} size="medium" />
                                )
                            }
                        </Avatar.Group> 
                        {
                            values?.member_count &&
                                <div className="text-slate-400">
                                    {values?.member_count} members
                                </div>
                        }
                    </div>
                    <div className="flex items-center gap-2">
                        <Button type='ghost' size='large' className="w-full rounded-2xl text-sm border duration-300 bg-white hover:bg-slate-100 text-slate-500 hover:text-slate-600 border-slate-300 hover:border-slate-400">
                            Check out {values?.name}
                        </Button>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default CommunityCard;