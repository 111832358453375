import { CommentOutlined, LoadingOutlined } from '@ant-design/icons';

function LoadingPage() {

    return (
        <div className='absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center bg-[#f5f5f5]]'>
            <div className="flex flex-col gap-4 text-center">
                <div className="text-5xl text-[#3972E0]">
                    <CommentOutlined/>
                </div>
                <div>
                    <LoadingOutlined/>
                </div>
            </div>
        </div>
    )
}

export default LoadingPage;