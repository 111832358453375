import { Popover } from "antd";
import UserAvatar from "../../../dashboard/components/avatar/UserAvatar";

const OrganizationPopoverContent = ({name,values}) => {

    return(
        <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
                <UserAvatar app={values} size="small" />
                <div className="flex flex-col text-left">
                    <h3 className="text-gray-800 text-xs p-0 leading-tight font-medium">
                        {name}
                    </h3>
                </div>
            </div>
            {
                values?.members_count && 
                    <div>
                        {values?.members_count} Members
                    </div>
            }
        </div>
    )
}

const OrganizationPopover = ({name,values}) => {
    return(
        <Popover content={<OrganizationPopoverContent name={name} values={values}/>} >
            <div onClick={() => {if(values?.path){window.open(`/group/${values?.path}`)}}} className="bg-slate-100 hover:bg-slate-200 duration-300 cursor-pointer rounded-xl px-2 py-1 w-fit">
                <div className="flex items-center gap-2">
                    <UserAvatar app={values} size="small" />
                    <div className="flex flex-col text-left">
                        <h3 className="text-gray-800 text-xs p-0 leading-tight font-medium">
                            {name}
                        </h3>
                    </div>
                </div>
            </div>
        </Popover>
    )
}

export default OrganizationPopover;