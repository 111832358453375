import { useEffect, useState } from "react";



const KeywordNavigation = ({keywords,onClick,defaultShow=false}) => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchValue = searchParams.get('search')
    const [showKeywords,setShowKeywords] = useState(defaultShow);

    useEffect(() => {
        if(showKeywords){
            setShowKeywords(defaultShow);
        }
    },[searchValue])

    return(
        <div className="flex flex-col items-start py-2 gap-1">
            <div className="flex items-center gap-1 flex-wrap text-left leading-tight">
                {
                    !showKeywords
                    ?   keywords.slice(0,3).map((value,index) => 
                            <div key={`keyword-${index}`} onClick={() => onClick(value?.name)}
                                className="px-2 py-0.5 bg-slate-100 hover:bg-slate-200 text-slate-400 hover:text-slate-600 border border-slate-200 hover:border-slate-400 duration-300
                                text-sm rounded-lg md:rounded-md cursor-pointer leading-tight">
                                {value?.name || value}
                            </div>
                        )
                    :   keywords.map((value,index) => 
                            <div key={`keyword-${index}`} onClick={() => onClick(value?.name)}
                                className="px-2 py-0.5 bg-slate-100 hover:bg-slate-200 text-slate-400 hover:text-slate-600 border border-slate-200 hover:border-slate-400 duration-300
                                text-sm rounded-lg md:rounded-md cursor-pointer leading-tight">
                                {value?.name || value}
                            </div>
                        )
                }
            </div>
            <div className="pt-1 md:pt-0 px-1 text-xs text-slate-400 hover:text-slate-500 duration-300 cursor-pointer"
                onClick={() => setShowKeywords(prev => !prev)}>
                {
                    (keywords.length - 3) > 0
                    ? !showKeywords ? `Show ${keywords.length - 3} more keywords` : 'Hide'
                    : 'No more keywords'
                }
            </div>
        </div>        
    )
}

export default KeywordNavigation;