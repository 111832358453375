import { CheckCircleOutlined, DeleteOutlined, DownOutlined, ExclamationCircleOutlined, FileOutlined, LoadingOutlined, MessageOutlined } from "@ant-design/icons";
import { message, Popover, Tooltip } from "antd";
import { useState } from "react";
import ContentServices from "../../../../community/utils/ContentServices";
import { useAuth } from "../../../../../utils/useAuth";
import Outline from "./Outline";


const FilePreviewCard = ({file,clickTalk}) => {
    const {user,refresh} = useAuth();
    const [content,setContent] = useState(null);
    const [showContent,setShowContent] = useState(false);
    const [loadingContent,setLoadingContent] = useState(false);

    const clickShowContent = () => {
        if(user){
            if(content){
                setShowContent(prev => !prev);
            }
            else{
                if(showContent){
                    setShowContent(false);
                }
                else if(!showContent && !loadingContent){
                    setLoadingContent(true);
                    getContent();
                    setShowContent(true);
                }
                else{
                    setShowContent(true);
                }
            }
        }
        else{
            message.info('Must Login to Show Raw Content')
        }
    }

    const getContent = async () => {
        setLoadingContent(true);
        let contentResponse = await ContentServices.getFile({file_id:file.id,user:user});
        if(contentResponse?.status === 401){
            let newUser = await refresh();
            contentResponse = await ContentServices.getFile({file_id:file.id,user:newUser});
        }
        if(contentResponse?.status === 'success'){
            setContent(contentResponse?.data);
        }
        setLoadingContent(false);
    }

    const clickDownload = (value) => {
        window.open(value)
    }

    return(
        <div className="p-3 w-full rounded-xl relative
            bg-slate-100 border border-slate-200 cursor-pointer shadow-md">
            <div className="flex flex-col md:flex-row items-start md:items-center gap-0 md:gap-2 mb-1">
                <div className="flex items-center gap-2 text-left">
                    <div className="p-1 md:p-3 rounded-3xl text-3xl text-slate-400">
                        <FileOutlined/>
                    </div>
                    <div className="flex items-center block md:hidden">
                        <div className="flex-grow text-base font-medium text-slate-700 leading-tight">
                            {
                                file?.file
                                ?   file?.file.includes('-file-')
                                    ?   file?.file.split('-file-').splice(file?.file.split('-file-').length - 1)
                                    :   file?.file.split('/files/').splice(file?.file.split('/files/').length - 1)
                                :   null
                            }
                        </div>
                        <div className="flex-shrink-0">
                            {
                                file?.status === 'Processed'
                                ?
                                    <Popover content={<div className="max-w-[300px] leading-tight">Ready to go!</div>} >
                                        <div className="text-green-500 text-base pr-1 cursor-pointer">
                                            <CheckCircleOutlined />
                                        </div>
                                    </Popover>
                                :
                                    <div>
                                        <Popover content={<div className="max-w-[300px] leading-tight">Pending. Batch processing can take up to 24 hours. Upgrade for instant processing.</div>} >
                                            <div className="text-yellow-500 text-sm cursor-pointer">
                                                <ExclamationCircleOutlined />
                                            </div>
                                        </Popover>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center md:items-start gap-3 md:gap-1 w-full">
                    <div className="flex items-center text-left gap-2">
                        <div className="text-base font-medium text-slate-700 leading-tight hidden md:block">
                            {
                                file?.file
                                ?   file?.file.includes('-file-')
                                    ?   file?.file.split('-file-').splice(file?.file.split('-file-').length - 1)
                                    :   file?.file.split('/files/').splice(file?.file.split('/files/').length - 1)
                                :   null
                            }
                        </div>
                    </div>
                    <div className="w-full">
                        <div className="flex flex-col md:flex-row w-full md:w-10/12 items-center gap-1">
                            {
                                file?.status === 'Processed' &&
                                    <Tooltip title="Talk to File Content">
                                        <div onClick={() => clickTalk({'id':file?.id,'type':'file','title':file?.file.split('-file-').splice(file?.file.split('-file-').length - 1),'link':file?.file})} 
                                            className="w-full md:w-fit px-2 py-2 md:py-0.5 duration-300 cursor-pointer
                                            border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                            text-xs text-slate-600 text-nowrap" >
                                            <MessageOutlined/> Talk to File
                                        </div>
                                    </Tooltip>
                            }
                            <Tooltip title={'Download File'}>
                                <div onClick={clickDownload} className="w-full md:w-fit px-2 py-2 md:py-0.5 duration-300 cursor-pointer
                                    border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                    text-xs text-slate-600 text-nowrap" >
                                    Download
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    {
                        file?.status === 'Processed'
                        ?
                            <Popover content={<div className="max-w-[300px] leading-tight">Ready to go!</div>} >
                                <div className="text-green-500 text-base pr-1 cursor-pointer">
                                    <CheckCircleOutlined />
                                </div>
                            </Popover>
                        :
                            <div>
                                <Popover content={<div className="max-w-[300px] leading-tight">Pending. Batch processing can take up to 24 hours. Upgrade for instant processing.</div>} >
                                    <div className="text-yellow-500 text-sm cursor-pointer">
                                        <ExclamationCircleOutlined />
                                    </div>
                                </Popover>
                            </div>
                    }
                </div>
            </div>
            {
                file?.status === 'Processed' &&
                    <Tooltip title={`${showContent ? 'Hide' : 'Show'} TL;DR`}>
                        <div onClick={clickShowContent} className="w-full px-2 py-2 duration-300 cursor-pointer
                            border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                            text-xs text-slate-600 text-nowrap" >
                            {showContent ? 'Hide' : 'Show'} TL;DR <DownOutlined/>
                        </div>
                    </Tooltip>
            }
            {
                showContent &&
                    <div className="w-full">
                        {
                            loadingContent
                            ?
                                <div className="text-slate-600 text-2xl">
                                    <LoadingOutlined/>
                                </div>
                            :   content?.outline
                                ?
                                    <div className="p-2 w-full">
                                        <div className="mb-4">
                                            <Outline values={content?.outline}/>
                                        </div>
                                        <div onClick={clickShowContent} className="w-full md:w-fit px-3 py-2 md:py-0.5 duration-300 cursor-pointer
                                            border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                            text-xs text-slate-600" >
                                            Hide TL;DR
                                        </div>
                                    </div>
                                :   <div className="py-2">
                                        No TL;DR Found
                                    </div>
                        }                        
                    </div>
            }
        </div>
    )

}

const FilePreview = ({files,setFiles,clickTalk,editting=false}) => {
    
    const deleteFile = (id) => {
        setFiles(files.filter(file => file.id !== id));
    }
    return (
        <div className="flex flex-col items-stretch gap-2 w-full">
            {
                files && files.length > 0 &&
                    files.map((file,index) => {
                        return (
                            <div key={`file-${index}`} className="flex items-center gap-1">
                                <FilePreviewCard file={file} clickTalk={clickTalk} />                                
                                {
                                    editting &&
                                        <Tooltip title="Delete File">
                                            <div className="px-1.5 aspect-square flex items-center justify-center cursor-pointer
                                                border border-transparent 
                                                text-red-400 hover:text-red-500 hover:bg-slate-50 hover:border-red-200 rounded-md duration-300" 
                                                onClick={() => deleteFile(file.id)}>
                                                <DeleteOutlined />
                                            </div>
                                        </Tooltip>
                                }
                            </div>
                        )
                    })
            }
        </div>
    )
}

export default FilePreview;