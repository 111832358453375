
const TabButtons = ({name,onClick,active=false}) => {
    return(
        <div onClick={onClick} 
            className={`py-1 cursor-pointer ${active ? 'border-b-2': 'border-none'}`}
            style={{borderColor:'var(--secondary)',color:active ? 'var(--secondary)' : '#111111'}}>
            <div className={`rounded-xl px-4 py-3 duration-300 text-slate-800 font-medium hover:bg-slate-100 text-nowrap`}>
                {name}
            </div>
        </div>
    )
}

export default TabButtons;