import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}/content`;


class ChatServices {

    queryChatTriage({query,messages}){
        //
        let payload = {
            'query':query,
            'messages':messages
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/query/triage`,
            data: payload
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    queryChat({query,messages,followup,conversation_id,attachment=false,agent_ids=[],filters={}}){

        let payload = {
            'query':query,
            'messages':messages,
            'followup':followup,
            'conversation_id':conversation_id
        }

        if(attachment) {
            payload['attachment'] = attachment
        }

        // Add Agents
        if(agent_ids && agent_ids.length > 0){
            payload['agent_ids'] = agent_ids
        }

        // Add Filters - file_ids,content_ids,etc
        if(Object.keys(filters).length > 0){
            for (const [key, value] of Object.entries(filters)) {
                // Only add to query if the array is not empty and contains objects with an id
                if (Array.isArray(value) && value.length > 0) {
                    const ids = value.map(item => item?.id).filter(id => id != null); // Extract ids and filter out null/undefined
                    if (ids.length > 0) {
                        payload[`${key}_ids`] = ids;
                    }
                }
            }
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/query/chat`,
            data: payload
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new ChatServices();