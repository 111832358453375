import { ConfigProvider, Drawer, Modal } from "antd";
import CreateContentInput from "./CreateContentInput";

const CreatePostModal = ({isCreatePostModalVisible,onClose}) => {

    return(
        <Modal
            open={isCreatePostModalVisible} 
            footer={null} 
            onCancel={onClose}
            width={window.innerWidth > 1000 ? '100%' : '95%'}
            style={{maxWidth:'600px',overflow:'hidden',marginBottom:'5vh'}}
            className="shadow-xl rounded-3xl p-0"
            styles={
                {
                    content:{padding:0}
                }
            }
            centered
            closable={false}
            destroyOnClose
            >
            <div>
                <CreateContentInput minRows={10} fullPage={window.innerWidth < 1000} />
            </div>
        </Modal>
    )
}

export default CreatePostModal;