import { DeleteOutlined, FlagOutlined, MoreOutlined } from "@ant-design/icons";
import { Input, message, Popconfirm, Popover } from "antd";
import { useAuth } from "../../utils/useAuth";
import ContentServices from "../../containers/community/utils/ContentServices";
import { useCommunityContext } from "../../containers/community/components/Context/CommunityContext";
import { useState } from "react";


const MoreButton = ({values}) => {
    const {user,refresh} = useAuth();
    const {setContent} = useCommunityContext();
    const [reason,setReason] = useState('');

    const onReport = async () => {
        let reportResponse = await ContentServices.submitReport({content_id:values?.id, reason:reason, user:user});
        if(reportResponse.status === 401){
            let newUser = await refresh();
            reportResponse = await ContentServices.submitReport({content_id:values?.id, reason:reason, user:newUser});
        }
        if(reportResponse.status === 200){
            message.success('Successfully Reported');
        }
    }
    const onDelete = async () => {
        let deleteResponse = await ContentServices.deleteContent({content_id:values?.id, user:user});
        if(deleteResponse.status === 401){
            let newUser = await refresh();
            deleteResponse = await ContentServices.deleteContent({content_id:values?.id, user:newUser});
        }
        if(deleteResponse.status === 204){
            message.success('Successfully Deleted');
            setContent(prev => prev.filter(value => value?.id !== values?.id));
        }
    }

    return(
        <Popover content={
            <div className="flex flex-col">
                <Popconfirm description={<div className="flex flex-col gap-2 max-w-[200px]">
                            Are you sure you want to report this post?
                            <Input placeholder='Reason for reporting' onChange={(value) => setReason(value.target.value)} />
                        </div>} onConfirm={onReport}>
                    <div className="text-red-400 hover:text-red-600 flex items-center gap-2 px-3 py-1 rouned-lg cursor-pointer">
                        <FlagOutlined/> Report
                    </div>
                </Popconfirm>
                {
                    user && values?.author?.id === user?.id &&
                        <Popconfirm description={'Are you sure you want to delete this post?'}  onConfirm={onDelete}>
                            <div className="text-red-400 hover:text-red-600 flex items-center gap-2 px-3 py-1 rouned-lg cursor-pointer">
                                <DeleteOutlined/> Delete
                            </div>
                        </Popconfirm>
                }

            </div>} className="p-0">
            <div className="text-xl text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 cursor-pointer px-0.5 py-1.5 
                            aspect-square flex items-center justify-center rounded-lg">
                <MoreOutlined />
            </div>
        </Popover>
    )
}

export default MoreButton;