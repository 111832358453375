import React, { createContext, useContext, useMemo, useState } from "react";
import MessageServices from "../../../chat/utils/MessageServices";
import { useLocalStorage } from "../../../../utils/useLocalStorage";
import ChatServices from "../../utils/ChatServices";
import { useAuth } from "../../../../utils/useAuth";
import ShareLinkModal from "../Modal/ShareLinkModal";
import LoginModal from "../Login/LoginModal";
import CreatePostModal from "../Create/CreatePostModal";
import CreateCommunityModal from "../Create/CreateCommunityModal";
import UserManagerModal from "../Account/UserManagerModal";

const CommunityContext = createContext();

export const CommunityProvider = ({ children }) => {
    const {user} = useAuth();
    const [activeTab,setActiveTab] = useLocalStorage('activeTab','');
    const [app,setApp] = useLocalStorage('app',null);
    const [messages,setMessages] = useLocalStorage('messages',[]);
    const [conversationId,setConversationId] = useLocalStorage('conversationId',[]);
    const [suggestions,setSuggestions] = useLocalStorage('suggestions',[]);
    const [content,setContent] = useState([]);
    const [filters,setFilters] = useState([]);
    const [keywords,setKeywords] = useState([]);    
    const [chatAttachment,setChatAttachment] = useState(null);    
    const [embeddingIds,setEmbeddingIds] = useState('numbers',[]);
    const [loadingMessage,setLoadingMessage] = useState(false);
    const [embeddingTotal,setEmbeddingTotal] = useState(0);
    const [sendingMessage,setSendingMessage] = useState(false);
    const [memberStatus,setMemberStatus] = useState(false);
    const [loginType,setLoginType] = useState('login');
    const [isLoginModalVisible,setIsLoginModalVisible] = useState(false);
    const [isSiderVisible,setIsSiderVisible] = useState(false);
    const [isChatSiderVisible,setIsChatSiderVisible] = useState(false);    
    const [isCreatePostModalVisible,setIsCreatePostModalVisible] = useState(false);    
    const [isCreateCommunityModalVisible,setIsCreateCommunityModalVisible] = useState(false);    
    const [isUserManagerModalVisible,setIsUserManagerModalVisible] = useState(false);    
    const [isShareLinkModalVisible,setIsShareLinkModalVisible] = useState(false);
    const [shareValues,setShareValues] = useState({});    
    const [exploreCommunities,setExploreCommunities] = useState([]);
    
    const theme = app?.theme;
    let agent = app?.agents?.[0];
    let embeddingProgress = ((embeddingIds.length / embeddingTotal) * 100) < 1 ? embeddingIds.length == 0 ? 0 : ((embeddingIds.length / embeddingTotal) * 100).toFixed(2) : ((embeddingIds.length / embeddingTotal) * 100).toFixed(1);
    
    const scrollToBottom = () => {
        var ms_div = document.getElementById('messager');
        if(ms_div){
            setTimeout(() => {ms_div.scrollTo({top: ms_div.scrollHeight,behavior: "smooth",});},[500]);
        }
    }
    const scrollToElementInDiv = (elementId) => {
        const container = document.getElementById('messager'); // The div with overflow
        const targetElement = document.getElementById(elementId); // The element to scroll to
        
        if(container && targetElement){
            const scrollPosition = targetElement.offsetTop - container.offsetTop;
            setTimeout(() => {container.scrollTo({top: scrollPosition,behavior: "smooth",});},[500]);
        }
    }
    const openLoginModal = ({type='login'}) => {
        setLoginType(type);
        setIsLoginModalVisible(true);
    }
    const openCreateCommunity = () => {
        if(user){
            setIsCreateCommunityModalVisible(true);
        }
        else{
            setIsLoginModalVisible(true);
        }
    }
    const openShareLink = (values) => {
        setShareValues(values)
        setIsShareLinkModalVisible(true);
    }

    const sendMessage = async ({inputValue}) => {
        setIsChatSiderVisible(true);
        setSendingMessage(true);
        if(inputValue === undefined){
            messages.info('Please enter a message');
            return;
        }
        
        if(document.getElementById('suggestions')){
            document.getElementById('suggestions').scrollTo(0,0);
        }
        setMessages((prev) => {
            let message = {text:inputValue,sender:true,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`};
            return prev ? [...prev, message] : [message]
        })
        scrollToBottom();
        let searchParams = {
            'system':agent?.description || "You are a virtual assistant that helps answer questions about a topic or feed. Use the given context to respond to the following messages",
            'filters':{}
        }
        if(app?.agents){
            searchParams['agent_ids'] = app?.agents.map((value) => value?.id).filter(value => value);
        }
        if(filters){
            searchParams['filters'] = filters
        }
        if(messages && messages.length > 0){
            let messageList = messages.slice(-5).map((value) => {
                return{role:value['sender'] ? 'user' : 'assistant',content:value['text']}
            });
            searchParams['messages'] = messageList
        }
        else{
            searchParams['messages'] = '';
        }
        searchParams['query'] = inputValue;

        // Save Message
        if(conversationId === undefined){
            const convoResponse = await MessageServices.createConversation({agent_id:app?.agents?.[0]?.id});
            if(convoResponse?.status === 'success'){
                setConversationId(convoResponse?.data?.id)
            }
        }
        searchParams['conversation_id'] = conversationId;
        if(chatAttachment?.id ){
            if(chatAttachment?.type === 'link'){
                searchParams['filters']['link'] = [{'id':chatAttachment?.id}]
            }
            if(chatAttachment?.type === 'file'){
                searchParams['filters']['file'] = [{'id':chatAttachment?.id}]
            }
            if(chatAttachment?.type === 'content'){
                searchParams['filters']['content'] = [{'id':chatAttachment?.id}]
            }
            // Add Attachement
            searchParams['attachment'] = `Attachment Type: ${chatAttachment?.type}\nAttachment Title: ${chatAttachment?.title || (chatAttachment?.content)}`
        }
        let quickResponse = await ChatServices.queryChatTriage(searchParams);
        searchParams['followup'] = quickResponse.data;
        setSendingMessage(false);
        setLoadingMessage(true);
        await scrollToBottom();
        let searchResponse = await ChatServices.queryChat(searchParams);
        if(searchResponse?.status === 'success'){
            const replyMessage = searchResponse.data?.response;
            setMessages(prev => {
                let message = {text:replyMessage,sources:searchResponse.data?.sources,attachments:searchResponse.data?.attachments,sender:false,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`};
                return [...prev, message]
            });
            setLoadingMessage(false);
            if(searchResponse.data?.replies){
                setSuggestions(prev => ([searchResponse.data?.replies[0],...prev]));
            }
            await scrollToElementInDiv(`message-${messages?.length}`);
            if(searchResponse.data?.sources && searchResponse.data?.sources.length > 0){
                const source_ids = searchResponse.data?.sources.slice(0,2).map((value) => {return value.eid});
                setEmbeddingIds(prev => [...prev,...source_ids].filter((value, index, self) => self.indexOf(value) === index));
            }
        }
    }
    
    const value = useMemo(
        () => ({
            isSiderVisible,setIsSiderVisible,
            activeTab,setActiveTab,
            app,setApp,theme,agent,
            messages,setMessages,
            conversationId,setConversationId,
            suggestions,setSuggestions,
            chatAttachment,setChatAttachment,
            embeddingIds,setEmbeddingIds,
            content,setContent,
            filters,setFilters,
            keywords,setKeywords,
            memberStatus,setMemberStatus,
            embeddingTotal,setEmbeddingTotal, embeddingProgress,
            isChatSiderVisible,setIsChatSiderVisible,
            isCreatePostModalVisible,setIsCreatePostModalVisible,
            isCreateCommunityModalVisible,setIsCreateCommunityModalVisible,
            loadingMessage,setLoadingMessage,sendMessage, sendingMessage, 
            isLoginModalVisible, setIsLoginModalVisible,
            isShareLinkModalVisible,setIsShareLinkModalVisible,
            isUserManagerModalVisible,setIsUserManagerModalVisible,
            shareValues,setShareValues,openShareLink,
            openLoginModal,openCreateCommunity,
            exploreCommunities,setExploreCommunities
        }),[conversationId,activeTab,content,filters,
            app,agent,messages,keywords,suggestions,
            embeddingIds,embeddingTotal,embeddingProgress,exploreCommunities,
            isSiderVisible,isChatSiderVisible,isCreatePostModalVisible,isLoginModalVisible,isUserManagerModalVisible,isShareLinkModalVisible,
            shareValues,loadingMessage,sendingMessage,memberStatus,chatAttachment]
    );

    return(
        <CommunityContext.Provider value={value}>
            {children}
            <LoginModal isLoginModalVisible={isLoginModalVisible} setIsLoginModalVisible={setIsLoginModalVisible} type={loginType} />
            <CreatePostModal isCreatePostModalVisible={isCreatePostModalVisible} onClose={() => setIsCreatePostModalVisible(false)} />
            <CreateCommunityModal isCreateCommunityModalVisible={isCreateCommunityModalVisible} setIsCreateCommunityModalVisible={setIsCreateCommunityModalVisible} onClose={() => setIsCreateCommunityModalVisible(false)} />
            <UserManagerModal isUserManagerModalVisible={isUserManagerModalVisible} setIsUserManagerModalVisible={setIsUserManagerModalVisible} />
            <ShareLinkModal isShareLinkModalVisible={isShareLinkModalVisible} setIsShareLinkModalVisible={setIsShareLinkModalVisible} values={shareValues} />
        </CommunityContext.Provider>
    );
};

export const useCommunityContext = () => {
    return useContext(CommunityContext);
};