import { createContext, useContext, useMemo, useState } from "react";
import { useLocalStorage } from "../../../../utils/useLocalStorage";
import ChatServices from "../../../../utils/ChatServices";
import MessageServices from "../../utils/MessageServices";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const [isSiderVisible,setIsSiderVisible] = useLocalStorage('siderToggle',true);
    const [activeTab,setActiveTab] = useLocalStorage('activeTab','');
    const [app,setApp] = useLocalStorage('app',null);
    const [agentIndex,setAgentIndex] = useLocalStorage('agentIndex',0);
    const [messages,setMessages] = useLocalStorage('messages',{});
    const [conversations,setConversations] = useLocalStorage('conversations',{});
    const [suggestions,setSuggestions] = useLocalStorage('suggestions',{});
    const [embeddingIds,setEmbeddingIds] = useLocalStorage('numbers',[]);
    const [loadingMessage,setLoadingMessage] = useState(false);
    const [embeddingTotal,setEmbeddingTotal] = useState(0);
    const [threads,setThreads] = useState({});
    const [sendingMessage,setSendingMessage] = useState(false);
    const [isCreateAppModalVisible,setIsCreateAppModalVisible] = useLocalStorage('createAppModal',true);
    const [isCreatorEngagementSiderVisible,setIsCreatorEngagementSiderVisible] = useLocalStorage('siderToggle',false);

    const theme = app?.theme;
    let embeddingProgress = ((embeddingIds.length / embeddingTotal) * 100) < 1 ? embeddingIds.length == 0 ? 0 : ((embeddingIds.length / embeddingTotal) * 100).toFixed(2) : ((embeddingIds.length / embeddingTotal) * 100).toFixed(1);
    
    const scrollToBottom = () => {
        var ms_div = document.getElementById('messager');
        if(ms_div){
            setTimeout(() => {ms_div.scrollTo({top: ms_div.scrollHeight,behavior: "smooth",});},[500]);
        }
    }
    const scrollToElementInDiv = (elementId) => {
        const container = document.getElementById('messager'); // The div with overflow
        const targetElement = document.getElementById(elementId); // The element to scroll to
        
        if(container && targetElement){
            const scrollPosition = targetElement.offsetTop - container.offsetTop;
            setTimeout(() => {container.scrollTo({top: scrollPosition,behavior: "smooth",});},[500]);
        }
    }

    const sendMessage = async (inputValue) => {
        setSendingMessage(true);
        if(inputValue === undefined){
            messages.info('Please enter a message');
            return;
        }
        let agent = app?.agents?.[agentIndex];
        
        if(document.getElementById('suggestions')){
            document.getElementById('suggestions').scrollTo(0,0);
        }
        setMessages((prev) => {
            let message = {text:inputValue,sender:true,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`};
            return {...prev,[agentIndex]: prev?.[agentIndex] ? [...prev[agentIndex], message] : [message]}
        })
        await scrollToBottom();
        let searchParams = {
            'system':agent?.description,
            'app_sk':agent?.app_sk,
            'assistant_id':agent?.assistant_id,
            'thread_id':threads?.[agentIndex],
        }
        if(messages?.[agentIndex] && messages?.[agentIndex].length > 0){
            let messageList = messages?.[agentIndex].slice(-5).map((value) => {
                return{role:value['sender'] ? 'user' : 'assistant',content:value['text']}
            });
            searchParams['messages'] = messageList
        }
        else{
            searchParams['messages'] = '';
        }
        searchParams['query'] = inputValue;

        // Save Message
        let conversation_id = conversations?.[agentIndex];
        if(conversation_id === undefined){
            const convoResponse = await MessageServices.createConversation({agent_id:app?.agents?.[agentIndex]?.id});
            if(convoResponse?.status === 'success'){
                conversation_id = convoResponse?.data?.id
                setConversations(prev => {prev[agentIndex] = convoResponse?.data?.id; return prev;})
            }
        }
        searchParams['conversation_id'] = conversation_id;
        let quickResponse = await ChatServices.queryChatQuickByApp({values:searchParams});
        searchParams['followup'] = quickResponse.data;
        setSendingMessage(false);
        setLoadingMessage(true);
        await scrollToBottom();
        //let searchResponse = await ChatServices.queryChatByApp({values:searchParams});
        let searchResponse = await ChatServices.queryChatTestingByApp({values:searchParams});
        if(searchResponse?.status === 'success'){
            const replyMessage = searchResponse.data?.response;
            setMessages(prev => {
                let message = {text:replyMessage,sources:searchResponse.data?.sources,attachments:searchResponse.data?.attachments,sender:false,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`};
                return {...prev,[agentIndex]:[...prev[agentIndex], message]}
            });
            if(threads?.[agentIndex] !== searchResponse.data?.thread_id){
                setThreads(prev => {prev[agentIndex] = searchResponse.data?.thread_id; return prev;});
            }
            setLoadingMessage(false);
            if(searchResponse.data?.replies){
                setSuggestions(prev => {
                    let temp = {...prev};
                    temp[agentIndex][0] = searchResponse.data?.replies[0];
                    return temp;
                });
            }
            await scrollToElementInDiv(`message-${messages?.[agentIndex]?.length}`);
            if(searchResponse.data?.sources && searchResponse.data?.sources.length > 0){
                const source_ids = searchResponse.data?.sources.slice(0,2).map((value) => {return value.eid});
                setEmbeddingIds(prev => [...prev,...source_ids].filter((value, index, self) => self.indexOf(value) === index));
            }
        }
    }
    
    const value = useMemo(
        () => ({
            isSiderVisible,setIsSiderVisible,
            activeTab,setActiveTab,
            app,setApp,theme,
            agentIndex,setAgentIndex,
            messages,setMessages,
            conversations,setConversations,
            suggestions,setSuggestions,
            embeddingIds,setEmbeddingIds,
            embeddingTotal,setEmbeddingTotal, embeddingProgress,
            isCreateAppModalVisible,setIsCreateAppModalVisible,
            isCreatorEngagementSiderVisible,setIsCreatorEngagementSiderVisible,
            loadingMessage,setLoadingMessage,sendMessage, sendingMessage
        }),[isSiderVisible,app,messages,conversations,suggestions,agentIndex,isCreateAppModalVisible,embeddingIds,embeddingTotal,embeddingProgress,activeTab,isCreatorEngagementSiderVisible,loadingMessage,sendingMessage]
    );

    return(
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => {
    return useContext(ChatContext);
};