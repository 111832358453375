import axios from "axios";
import Resizer from "react-image-file-resizer";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}/content`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1200, 1200, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class ContentServices {
    async postImage({image, name, data, user=false}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                if(value && value !== null){
                    if (Array.isArray(value)) {
                        // Handle arrays by appending each value separately
                        value.forEach((item) => formData.append(`${key}[]`, item));
                    } else {
                        // Append other data types
                        formData.append(key, value);
                    }
                }
            }
        }
        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/image",
            data: formData,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};
        }
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    postFile({file, name, data, user=false}) {
        if(user){
            let formData = new FormData();
            formData.append('file', file, `${name}`);
            formData.append('name', name);

            for (const key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key];
                    if(value && value !== null){
                        if (Array.isArray(value)) {
                            // Handle arrays by appending each value separately
                            value.forEach((item) => formData.append(`${key}[]`, item));
                        } else {
                            // Append other data types
                            formData.append(key, value);
                        }
                    }
                }
            }
    
            let configuration = {
                credentials: 'include',
                method: "post",
                url: API_URL + "/file",
                headers:{"Authorization" : `Bearer ${user.access}`},
                data: formData,
            };
        
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
    }
    getLinkMetadata({url}){
        let configuration = {
            credentials: 'include',
            method: "get",
            url: `https://api.microlink.io/?url=${url}`
        };
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    async fetchMetadata({url}){
        let configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/link/metadata?url=${url}`,
        };
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
    
    
    addLink({values, user=false}) {
        if(user){
            let configuration = {
                credentials: 'include',
                method: "post",
                url: API_URL + "/link",
                headers:{"Authorization" : `Bearer ${user.access}`},
                data: values,
            };
        
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
    }
    updateLink({link_id,data,user}) {
        if(user){
            let configuration = {
                credentials: 'include',
                method: "patch",
                url: API_URL + `/link/${link_id}`,
                headers:{"Authorization" : `Bearer ${user?.access}`},
                data:data
            };
        
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
    }
    getLink({link_id,user}) {
        if(user){
            let configuration = {
                credentials: 'include',
                method: "get",
                url: API_URL + `/link/${link_id}`,
                headers:{"Authorization" : `Bearer ${user.access}`},
            };
        
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
    }
    getFile({file_id,user}) {
        if(user){
            let configuration = {
                credentials: 'include',
                method: "get",
                url: API_URL + `/file/${file_id}`,
                headers:{"Authorization" : `Bearer ${user.access}`},
            };
        
            // Send POST Query with Login Info
            return axios(configuration)
            .then(response => {
                return response.data
            })
            .catch((error) => {
                return error.response;
                //error = new Error();
            });
        }
    }

    getTags({page=0,limit=15,ordering='-updated_at'}){
        const offset = page * limit || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/tag/list?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/tag/list?limit=${limit}&ordering=${ordering}` };

        const urlParams = new URLSearchParams(window.location.search);
        const searchParam = urlParams.get('search');
        if(searchParam){
            configAPIUrl = configAPIUrl + `&search=${searchParam}`
        }

        let configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
        };
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }

    getPost({post_id,user=false}){
        let configuration = {
            credentials: 'include',
            method: "get",
            url: API_URL + `/community/post/${post_id}`,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};
        }
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }

    getContent({page=0,limit=15,ordering='-updated_at',community_id=false,filters={},is_active=false}){
        const offset = page * limit || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/community/content/list?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/community/content/list?limit=${limit}&ordering=${ordering}` };

        if(community_id){
            configAPIUrl = configAPIUrl + `&community_id=${community_id}`
        };
         
        if(Object.keys(filters).length > 0){
            for (const [key, value] of Object.entries(filters)) {
                // Only add to query if the array is not empty and contains objects with an id
                if (Array.isArray(value) && value.length > 0) {
                    if(value.length > 0) {
                        value.forEach((item) => {
                            configAPIUrl = configAPIUrl + `&${key}_ids=${item}`;
                        });                        
                    }
                }
            }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const searchParam = urlParams.get('search');
        if(searchParam){
            configAPIUrl = configAPIUrl + `&search=${searchParam}`
        }
        if(is_active){
            configAPIUrl = configAPIUrl + `&is_active=${is_active}`
        }

        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    postContent({values,user}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/community/content`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    editContent({values,user}){
        const configuration = {
            credentials: 'include',
            method: "patch",
            url: API_URL + `/community/content`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    deleteContent({content_id,user}){
        const configuration = {
            credentials: 'include',
            method: "delete",
            url: API_URL + `/community/content/${content_id}`,
            headers:{"Authorization" : `Bearer ${user.access}`}
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    getCommentsByContentId({content_id,user,page=0,limit=15,ordering='-updated_at'}){
        const offset = page * limit || 0;

        var configAPIUrl = API_URL
        if (offset > 0){configAPIUrl = API_URL + `/comments/by-content?limit=${limit}&offset=${offset}&ordering=${ordering}`}
        else { configAPIUrl = API_URL + `/comments/by-content?limit=${limit}&ordering=${ordering}` };

        if(content_id){
            configAPIUrl = configAPIUrl + `&content_id=${content_id}`
        };
        const configuration = {
            credentials: 'include',
            method: "get",
            url: configAPIUrl,
            headers:{"Authorization" : `Bearer ${user.access}`}
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    postComment({content_id,text,user,comment_id=false}){
        let values = {
            content: content_id,
            text: text,
        }
        if(comment_id){
            values['parent'] = comment_id
        }
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/comments/`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    deleteComment({comment_id,user}){
        const configuration = {
            credentials: 'include',
            method: "delete",
            url: API_URL + `/comments/${comment_id}`,
            headers:{"Authorization" : `Bearer ${user.access}`}
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    addLike({values,user}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/likes/`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    removeLike({values,user}){
        const configuration = {
            credentials: 'include',
            method: "delete",
            url: API_URL + `/likes/`,
            headers:{"Authorization" : `Bearer ${user.access}`},
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    submitReport({content_id,reason,user}){
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/reports/`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:{
                content: content_id,
                reason: reason,
            }
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new ContentServices();
