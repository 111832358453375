import axios from "axios";
import Resizer from "react-image-file-resizer";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1200, 1200, 'JPEG', 100, 0,
        uri => {
        resolve(uri);
        }, 'file' );
});

class ImageServices {
    async postImage({image, name, user=false}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/image",
            data: formData,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};
        }
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }

    async postAvatar({image, name, user=false}) {
        let formData = new FormData();

        const imageResized = await resizeFile(image);        
        formData.append('image', imageResized, `${name}`);

        let configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + "/avatar",
            data: formData,
        };

        if(user){
            configuration['headers'] = {"Authorization" : `Bearer ${user.access}`};
        }
    
        // Send POST Query with Login Info
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response;
            //error = new Error();
        });
    }
}
export default new ImageServices();