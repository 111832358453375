import { Image, Popover } from "antd";

const ImageAttachment = ({images}) => {
    return(
        <div className="w-full flex items-center justify-start gap-1">
            {
                images.map((image,index) => {
                    return <div key={index} className="rounded-lg overflow-hidden" style={{height:150}}>
                        <Popover content={<div>{image?.title}</div>} className="rounded-lg overflow-hidden">
                            <Image 
                                key={index}
                                height={150}
                                preview={true}
                                src={image?.image}
                                alt="image" />
                        </Popover>
                    </div>
                })
            }
        </div>
    )
}

export default ImageAttachment;