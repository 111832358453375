import { useEffect } from "react";
import { Drawer, ConfigProvider } from "antd";
import Sider from "antd/es/layout/Sider";
import ChatUI from "./ChatUI";
import { useCommunityContext } from "../Context/CommunityContext";

function ChatSider({isSiderVisible, setIsSiderVisible}) {
    const {
        app,agent,
        setMessages,setChatAttachment,
        setSuggestions,setLoadingMessage 
    } = useCommunityContext();

    const initialSetup = async () => {
        setMessages([]);
        await setLoadingMessage(true);
        setSuggestions([]);
        setChatAttachment(null);
        setMessages([
            {
                text:(agent && agent?.intro_message) ? agent?.intro_message : `Hey I'm ${app?.name}'s AI.\nAsk me anything about content in the feed or any related topics.`, 
                sender:false,
                timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 
                    ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`
            }
        ]);
        setLoadingMessage(false);
        if(agent?.suggested_messages && agent?.suggested_messages.length > 0){
            setSuggestions([...agent?.suggested_messages]);
        }
    }

    useEffect(() => {
        setLoadingMessage(true);
        if(app?.name){
            initialSetup();
        }
    },[app?.name]);


    // Apply theme variables
    const themeVars = {
        "--primary": app?.theme?.colors?.primary || "#171717",
        "--primary-lt": `${app?.theme?.colors?.primary}F5` || "rgba(255,255,255,0.9)",
        "--secondary": app?.theme?.colors?.secondary || "#232323",
        "--tertiary1": app?.theme?.colors?.tertiary1 || "#ececec",
        "--tertiary2": app?.theme?.colors?.tertiary2 || "#ececec",
    };

    return(
        window.innerWidth > 1000
        ?
            <Sider
                width={window.innerWidth * 0.3 > 375 ? '30%' : 375}
                collapsedWidth={0}
                collapsed={!isSiderVisible}
                trigger={null}
                className="shadow-3xl"
                style={{minWidth:'300px'}}
                collapsible>
                <div className="w-full h-full" style={{backgroundColor:'var(--primary)'}}>
                    <ChatUI fullWidth={true} />
                </div>
            </Sider>
        :
            <ConfigProvider
                theme={{
                    token: {
                        padding:0,
                    },
                }}>
                <Drawer 
                    open={isSiderVisible}
                    onClose={() => setIsSiderVisible(false)}
                    onCancel={() => setIsSiderVisible(false)}
                    closeIcon={null}
                    width={'100%'}
                    height={'100%'}
                    placement="right"
                    closable={true}
                    styles={{
                        "body":{
                            padding:0
                        },
                        "header":{
                            display:'none'
                        }
                    }}
                    >
                    <div className="flex flex-col h-full w-full text-center" style={{...themeVars,backgroundColor:'var(--primary)'}}>
                        <ChatUI fullWidth={true} />
                    </div>
                </Drawer>
            </ConfigProvider>
    )
}

export default ChatSider;