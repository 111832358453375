import { ClockCircleOutlined, HeartFilled, HeartOutlined, LoadingOutlined } from "@ant-design/icons";
import ReactMarkdown from 'react-markdown';
import remarkBreaks from "remark-breaks";
import { useState } from "react";
import ChatAttachments from "./ChatAttachments";

function ChatBubble({app,text,theme,sender,attachments,sendingMessage=false,timestamp=false}) {
    const [liked,setLiked] = useState(false);
    
    return(
        <div className={`w-full`}>
            <div className={`flex ${sender ? 'justify-end' : 'justify-start'}`}>
                <div className={`flex flex-col items-end
                    ${sender ? 'max-w-[80%]' : 'max-w-[100%]'}
                    `}>
                    <div className="w-full flex items-start">
                        <div>
                            {
                                !sender
                                ?   app?.avatar?.image
                                    ?
                                        <div className="w-10 aspect-square rounded-full overflow-hidden border border-gray-100">
                                            <img src={app?.avatar?.image} alt="avatar" className="w-full h-full object-cover" />
                                        </div>
                                    :   
                                        <div className="w-10 aspect-square rounded-full flex items-center justify-center overflow-hidden border border-gray-100" 
                                            style={{backgroundColor: 'var(--tertiary1)'}}>
                                            <div className="text-white text-base md:text-sm leading-none">{app?.name && app.name.slice(0,1)}</div>
                                        </div>
                                : null
                            }
                        </div>
                        <div className="flex-grow max-w-full flex flex-col">
                            <div className={`p-2.5 text-left rounded-2xl w-full whitespace-prewrap
                                ${!sender ? 'px-2 md:px-4 leading-snug md:leading-normal font-medium pt-0' : 'px-4 shadow-md leading-snug md:leading-tight'}`} 
                                style={!sender ? {color: 'var(--tertiary2)'} : {backgroundColor: 'var(--secondary)', color: 'var(--tertiary1)'}}>
                                <ReactMarkdown remarkPlugins={[remarkBreaks]}>
                                    {text.replace(/\n/gi, "&nbsp; \n")}
                                </ReactMarkdown>
                            </div>
                             {/* Attachments */}
                            <div className="w-11/12">
                                {
                                    attachments && attachments.length > 0 && <ChatAttachments attachments={attachments} />
                                }
                            </div>
                            {
                                !sender &&
                                    <div className="w-fit flex items-center gap-1 pl-2 md:pl-4" onClick={() => setLiked(prev => !prev)}>
                                        <div className="text-xs px-2 py-1 rounded-lg hover:bg-gray-100 duration-200 cursor-pointer"
                                            style={{color: 'var(--tertiary1)',backgroundColor: 'var(--secondary)'}}>
                                            {liked ? <HeartFilled/> : <HeartOutlined/>}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="flex items-center w-full">
                        {
                            !sender && <div className="w-10"></div>}
                        {
                            sender && <div  className="flex-1 flex justify-start"></div>
                        }
                        <div className="w-fit flex items-center gap-2 pl-2 md:pl-4">
                            {
                                sendingMessage &&
                                    <div className="text-xs text-gray-400"><LoadingOutlined/> Sending</div>
                            }
                            {
                                timestamp &&
                                    <div className={`text-left pr-2 text-[10px] opacity-70 w-fit flex items-center text-nowrap flex-nowrap gap-1`} style={{color: theme?.colors?.tertiary2 ? theme?.colors?.tertiary2 : '#F5F5F5', maxWidth:'80%'}}>
                                        <ClockCircleOutlined/> {timestamp}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatBubble;