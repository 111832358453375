import { Input } from "antd";


function QuestionMetadataInput({setMetadata}) {
    return(
        <div className="pb-2">
            <Input.TextArea 
                className="text-lg leading-tight font-semibold flex-wrap h-auto no-sidebar"
                placeholder="Ask a Question"
                variant="borderless"
                autoSize={{minRows:1}}
                onChange={(value) => setMetadata(prev => {return{...prev,'question':value.target.value}})}
                />
        </div>
    )
}

export default QuestionMetadataInput;