import Modal from "antd/es/modal/Modal";
import { ConfigProvider, message } from "antd";
import { FacebookOutlined, TwitterOutlined, LinkedinOutlined, WhatsAppOutlined, MailOutlined, CopyOutlined } from '@ant-design/icons';


function ShareLinkModal({isShareLinkModalVisible,setIsShareLinkModalVisible,values}){
    
    const encodedUrl = encodeURIComponent(`${window.location.protocol}//${window.location.host}${values?.link}`);
    const encodedTitle = encodeURIComponent(values?.name);
    const encodedText = encodeURIComponent(values?.name);

    const socialLinks = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
        twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedText}`,
        linkedin: `https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${encodedTitle}`,
        whatsapp: `https://api.whatsapp.com/send?text=${encodedText}%20${encodedUrl}`,
        email: `mailto:?subject=${encodedTitle}&body=${encodedText}%20${encodedUrl}`,
    };

    const clickCopy = () => {
        navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}${values?.link}`);
        message.info('Copied Link')
    }

    const closeModal = () => {
        setIsShareLinkModalVisible(false);
    }
    return(
        <ConfigProvider
            theme={{
                token: {
                    padding:16
                }}}>
            <Modal
                open={isShareLinkModalVisible} 
                footer={null} 
                onCancel={closeModal}
                width={'100%'}
                style={{maxWidth:'500px',overflow:'hidden',marginBottom:'5vh',backgroundColor:'#1A73E8'}}
                className="shadow-xl rounded-3xl"
                centered
                closable={true}
                destroyOnClose
                >
                <div className="p-4">
                    <div className="text-white flex items-center justify-center">
                        <div className="w-full flex flex-col gap-4">
                            <div className="text-xl text-slate-600 font-semibold">
                                Share {values?.type} Link
                            </div>
                            <div className="flex items-start gap-1">
                                <div className="flex-1" id='link-input-div'>
                                    <div className="w-full bg-slate-100 rounded-lg cursor-text text-left text-lg p-3 text-slate-500">
                                        <div className="flex items-center">
                                            <div className="flex-1">{values?.link}</div>
                                            <div onClick={clickCopy} className="text-xl text-slate-500 cursor-pointer">
                                                <CopyOutlined/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 text-xl text-white">
                                <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: '#3b5998' }} 
                                    className="px-2 rounded-full aspect-square flex items-center justify-center hover:text-white hover:opacity-90 duration-300">
                                    <FacebookOutlined />
                                </a>
                                <a href={socialLinks.twitter} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: '#1da1f2' }}
                                    className="px-2 rounded-full aspect-square flex items-center justify-center hover:text-white hover:opacity-90 duration-300">
                                    <TwitterOutlined />
                                </a>
                                <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: '#0077b5' }}
                                    className="px-2 rounded-full aspect-square flex items-center justify-center hover:text-white hover:opacity-90 duration-300">
                                    <LinkedinOutlined />
                                </a>
                                <a href={socialLinks.whatsapp} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: '#25d366' }}
                                    className="px-2 rounded-full aspect-square flex items-center justify-center hover:text-white hover:opacity-90 duration-300">
                                    <WhatsAppOutlined />
                                </a>
                                <a href={socialLinks.email} target="_blank" rel="noopener noreferrer" style={{ backgroundColor: '#ea4335' }}
                                    className="px-2 rounded-full aspect-square flex items-center justify-center hover:text-white hover:opacity-90 duration-300">
                                    <MailOutlined />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    )
}

export default ShareLinkModal;