import { Button, Input, message, Tooltip, Switch, Popover } from "antd";
import { FaMagic } from "react-icons/fa";
import { CloseOutlined, CommentOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined, HeartOutlined, LoadingOutlined, MessageOutlined, PaperClipOutlined, 
    PictureOutlined, QuestionCircleOutlined, SaveOutlined, ShareAltOutlined} from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import Linkify from 'react-linkify';
import ImageServices from "../../../../utils/ImageServices";
import FileServices from "../../../../utils/FileServices";
import ContentServices from "../../../dashboard/utils/ContentServices";
import { useCommunityContext } from "../Context/CommunityContext";
import { useAuth } from "../../../../utils/useAuth";
import CommentThread from "../Comments/CommentThread";
import OrganizationPopover from "./OrganizationPopover";
import UserPopover from "./UserPopover";
import MoreButton from "../../../../components/button/MoreButton";
import { useNavigate } from "react-router-dom";
import InputPreview from "./preview/InputPreview";

const ActionPreviewCard = React.lazy(() => import("../../../dashboard/components/card/preview/ActionPreviewCard"));
const QuestionPreviewCard = React.lazy(() => import("../../../dashboard/components/card/preview/QuestionPreviewCard"));
const YoutubePreviewCard = React.lazy(() => import("../../../dashboard/components/card/preview/YoutubePreviewCard"));

function EditContentInput({author,values,index,isEditable=false,defaultShowComments=false,onSearch=false}) {
    const inputRef = useRef();
    const imageRef = useRef();
    const fileRef = useRef();
    const {user,refresh} = useAuth();
    const {app,setContent,sendMessage,setIsChatSiderVisible,setChatAttachment,openShareLink,setSuggestions} = useCommunityContext();
    const [text,setText] = useState(null);
    const [metadata,setMetadata] = useState({});
    const [images,setImages] = useState([]);
    const [files,setFiles] = useState([]);
    const [links,setLinks] = useState([]);
    const [showComments,setShowComments] = useState(defaultShowComments);
    const [loading,setLoading] = useState(false);
    const [agentOptions,setAgentOptions] = useState([]);
    const [isEditting,setIsEditting] = useState(false);
    const [uploading,setUploading] = useState(null);
    const [showContent,setShowContent] = useState(false);
    const navigate = useNavigate();
    
    const initialSetup = () => {
        const agents = app?.agents.map((value) => ({'id':value?.id,'name':value?.name,'sk':value?.collection_sk,'is_active':values?.agents ? values?.agents.some(obj => obj.id === value?.id) : true}));
        setAgentOptions(agents);
        setText(values?.content);
        setMetadata(values?.metadata);
        setImages(values?.images);
        setFiles(values?.files);
        setLinks(values?.links);

        setTimeout(() => {
            if(inputRef?.current){
                inputRef.current.focus();
            }
        },[1000]);
    }
    const clickTalk = (value) => {
        setIsChatSiderVisible(true);
        setChatAttachment(value);
        setSuggestions(values?.metadata?.questions);
    }
    const clickAskQuestion = (value) => {
        sendMessage(value);
        clickTalk({...values,'type':'content'});
    }
    const clickMetadata = (value) => {
        setIsEditting(true);
        if(metadata?.type){
            if(metadata.type !== value){
                setMetadata({'type':value})
            }
            else{
                setMetadata({});
            }
        }
        else{
            setMetadata({'type':value})
        }
    }
    const editPost = async () => {
        let contentValues = {
            'ov_id':values?.ov_id,
            'content_id_id':values?.id,
            'sk_list':agentOptions.filter(value => value?.active).map(value => value?.sk),
            'content':text,
            'type':'text',
            'metadata':{...metadata,
                'images':images.map(value => ({'id':value?.id,'image_url':value?.image,'name':value?.name,'description':value?.description})),
                'files':files.map(value => ({'id':value?.id,'file_url':value?.file,'name':value?.name,'description':value?.description}))
            },
            'image_ids':images.map(value => value?.id),
            'file_ids':files.map(value => value?.id),
        }
        
        setLoading(true);
        if(text && text !== ''){
            let contentResponse = await ContentServices.editContent({values:contentValues,user:user});
            if(contentResponse.status === 401){
                let newUser = await refresh();
                contentResponse = await ContentServices.editContent({values:contentValues,user:newUser});
            }
            if(contentResponse.status === 'success'){
                message.success('Successfully Updated Content.');
                setText(null);
                setImages([]);
                setIsEditting(false);
                setContent(prev => {
                    const temp = [...prev];
                    temp[index] = contentResponse.data;
                    return temp;
                });
            }
        }
        else{
            message.info('Add some text to post!');
        }
        setLoading(false);
    }
    const toggleActive = async (value) => {
        if(value){
            message.success('Content Actived.');
        }
        else{
            message.success('Content set to Inactive.');
        }
        let contentValues = {
            'ov_id':values?.ov_id,
            'content_id_id':values?.id,
            'is_active':value
        }
        let contentResponse = await ContentServices.editContent({values:contentValues,user:user});
        if(contentResponse.status === 401){
            let newUser = await refresh();
            contentResponse = await ContentServices.editContent({values:contentValues,user:newUser});
        }
        if(contentResponse?.status === 'success'){
            setContent(prev => {
                const temp = [...prev];
                temp[index] = contentResponse.data;
                return prev;
            });
        }
        else{
            message.error('Issue while saving update. Please try again.');
        }
    }
    const uploadImage = async (file) => {
        let imageResponse = await ImageServices.postImage({image:file,name:`${user.username}-image-${file.name}`,user:user});
        if(imageResponse.status === 401){
            let newUser = await refresh();
            imageResponse = await ImageServices.postImage({image:file,name:`${user.username}-image-${file.name}`,user:newUser});
        }
        if(imageResponse.status === 'success'){
            setImages(prev => ([...prev,imageResponse.data]));
        }
    }
    const uploadFile = async (file) => {
        let fileResponse = await FileServices.postFile({file:file,name:`${user.username}-file-${file.name}`,user:user});
        if(fileResponse.status === 401){
            let newUser = await refresh();
            fileResponse = await FileServices.postFile({file:file,name:`${user.username}-file-${file.name}`,user:newUser});
        }
        if(fileResponse.status === 'success'){
            setFiles(prev => ([...prev,fileResponse.data]));
        }
    }
    const fileUpload = async (event,fileType) => {
        if(fileType === 'file'){
            setUploading('file');
        }
        else if(fileType === 'image'){
            setUploading('image');
        }
        var file = event.target.files[0];
        if(file){
            var reader = new FileReader();
            reader.readAsDataURL(file);
    
            let uploadResponse;
            if(fileType === 'file'){
                uploadResponse = await uploadFile(file);
            }
            else if(fileType === 'image'){
                uploadResponse = await uploadImage(file);
            }
        }
        setUploading(null);
    };

    useEffect(() => {
        initialSetup();
    },[values]);

    return(
        <div className="bg-white shadow-xl rounded-3xl pt-4 md:pt-6 px-2 md:px-6 pb-6 flex flex-col w-full gap-1">
            <div className="w-full flex items-center gap-2 px-2 py-2">
                <div>
                    <UserPopover author={author} />
                </div>
                <div onClick={() => navigate(`/post/${values?.content.slice(0,30).toLowerCase().split(' ').join('-').split(':').join('')}-${values?.id}`)} 
                    className="flex-1 h-10 cursor-pointer">
                </div>
                <div>
                    {
                        isEditable && user && user?.id === values?.author?.id
                        ?   <Switch checked={values?.is_active} value={values?.is_active} onChange={(value) => toggleActive(value)} />
                        :   <div className="flex items-center gap-1">
                                {
                                    (app || values?.application?.id) &&
                                        <OrganizationPopover 
                                                name={values?.application?.name || app?.name} 
                                                values={values?.application || app} />
                                }
                                <MoreButton values={values} />
                            </div>
                    }
                </div>
            </div>
            {
                isEditable && isEditting
                ?
                    <div className="w-full p-2">
                        <div className="w-full flex gap-2 items-start">
                            <div className="flex-1 flex flex-col gap-1">
                                {
                                    metadata?.type && metadata?.type === 'question' &&
                                        <QuestionPreviewCard metadata={metadata} setMetadata={setMetadata} isEditting={true} />
                                }
                                <div className="w-full">
                                    <Input.TextArea 
                                        ref={inputRef}
                                        placeholder="Add Anything"
                                        size="large"
                                        className="text-base rounded-2xl"
                                        value={text}
                                        onChange={(value) => setText(value.target.value)}
                                        autoSize={{minRows:4,maxRows:10}}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="text-sm pt-2 px-2">
                            <div className="w-fit text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 cursor-pointer px-2 py-0.5 flex items-center justify-center rounded-lg">
                                <Tooltip title="AI Assisted">
                                    <div className="flex items-center gap-2">
                                        <FaMagic/> Use AI
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                :
                    <div className="w-full p-2">
                        <div className="text-left text-lg font-medium leading-snug text-slate-800 px-2">
                            {
                                values?.metadata?.type && values?.metadata?.type !== '' &&
                                    {
                                        'question':<QuestionPreviewCard metadata={values?.metadata} setMetadata={setMetadata} />,
                                        'youtube':<YoutubePreviewCard metadata={values?.metadata} />
                                    }[values?.metadata?.type] 
                            }
                            {
                                showContent
                                ?   <div className="pb-6">
                                        <div className="sticky top-14 py-2 mb-2">
                                            <div className="w-fit px-3 py-0.5 duration-300 cursor-pointer
                                                border border-slate-200 hover:border-slate-300 bg-slate-50 hover:bg-slate-200 rounded-full 
                                                text-xs text-slate-400 hover:text-slate-600"
                                                onClick={() => setShowContent(prev => !prev)} >
                                                Hide Content
                                            </div>
                                        </div>
                                        <div className="whitespace-pre-line text-left pb-1">
                                            <Linkify>{values?.content}</Linkify>
                                        </div>
                                    </div>
                                :
                                    values?.content && (values?.content.length > 200 || (values?.content.length > 20 && values?.content.split('\n').length > 5))
                                    ?   <div onClick={() => setShowContent(true)} className="whitespace-pre-line w-full text-left pb-4" >
                                            <Linkify>
                                                {values?.content.slice(0,200)}...
                                            </Linkify>
                                            <div className="pt-2 text-xs text-slate-400 font-normal cursor-pointer">
                                                Show Full
                                            </div>
                                        </div>
                                    :   <div>
                                            <div className="whitespace-pre-line text-left pb-4">
                                                <Linkify>{values?.content}</Linkify>
                                            </div>
                                        </div>
                            }
                        </div>
                        <div>
                            {
                                !values?.is_complete &&
                                    <div className="px-2">
                                        <Popover content={<div className="max-w-[300px] leading-tight">Pending. Batch processing can take up to 24 hours. Upgrade for instant processing.</div>} >
                                            <div className="cursor-pointer w-fit border border-yellow-500 rounded-lg px-2 py-0.5">
                                                <div className="text-yellow-500 text-xs text-left flex items-center gap-1">
                                                    <ExclamationCircleOutlined /> <span>Pending...</span>
                                                </div>
                                            </div>
                                        </Popover>
                                    </div>
                            }
                            {
                                (files && files.length > 0) || (images && images.length > 0) || (links && links.length > 0)
                                ?
                                    <div className="w-full py-2">
                                        <InputPreview 
                                            images={images} setImages={setImages} 
                                            files={files} setFiles={setFiles}
                                            links={links} setLinks={setLinks}
                                            products={[]} places={[]} 
                                            editable={user && user?.id === author?.id}
                                            editting={isEditting} 
                                            clickTalk={clickTalk} />
                                    </div>
                                :   null
                            }
                            <div className="p-2">
                                <div className="text-left p-1">
                                    <h3 className="text-xs text-slate-400">
                                        Deep Dive
                                    </h3>
                                </div>
                                {
                                    values?.tags && 
                                        <ActionPreviewCard 
                                            keywords={values?.tags}
                                            metadata={values?.metadata}
                                            questions={values?.metadata?.questions}
                                            sendMessage={clickAskQuestion} />
                                }
                            </div>
                            <div>
                                {
                                    values?.is_complete &&
                                        <Tooltip title={'Talk to Post Content'}>
                                            <div onClick={() => clickTalk({...values,'type':'content'})} className="w-full px-2 py-2 duration-300 cursor-pointer
                                                border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                                text-xs text-slate-600 text-nowrap" >
                                                <CommentOutlined/> Talk to Post Content
                                            </div>
                                        </Tooltip>
                                }
                            </div>
                        </div>
                    </div>
            }
            <div>
                {
                    isEditable && isEditting
                    ?
                        <div className="flex items-center">
                            <div className="flex-1 flex items-center text-xl p-1">
                                <div className="text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 cursor-pointer px-1.5 aspect-square flex items-center justify-center rounded-lg">
                                    <Tooltip title="Add a Q&A">
                                        <div onClick={() => clickMetadata('question')}>
                                            <QuestionCircleOutlined/>
                                        </div>
                                    </Tooltip>
                                </div>                                
                                <div onClick={() => imageRef.current.click()} 
                                    className={`text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 px-1.5 aspect-square flex items-center justify-center rounded-lg
                                    ${uploading === 'image' ? "bg-gray-100 cursor-not-allowed" : "cursor-pointer"}`}>
                                    <Tooltip title="Upload an Image">
                                        {uploading === 'image' ? <LoadingOutlined/> : <PictureOutlined/>}
                                    </Tooltip>
                                    <input type='file' id='file' ref={imageRef} accept="image/*" onChange={(event) => fileUpload(event,'image')} style={{display: 'none'}}/>
                                </div>
                                <div className={`text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 px-1.5 aspect-square flex items-center justify-center rounded-lg
                                    ${uploading === 'file' ? "bg-gray-100 cursor-not-allowed" : "cursor-not-allowed"}`}>
                                    <Tooltip title="Add Files (Coming Soon)">
                                        {uploading === 'file' ? <LoadingOutlined/> : <PaperClipOutlined/>}
                                    </Tooltip>
                                    <input type='file' id='file' ref={fileRef} onChange={(event) => fileUpload(event,'file')} style={{display: 'none'}}/>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="flex items-center pr-2">
                            <div className="flex-1 flex items-center text-xl p-1">
                                <div onClick={() => setShowComments(prev => !prev)} className="text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-300 cursor-pointer px-2 py-0.5 flex items-center justify-center rounded-lg">
                                    <Tooltip title="Comments">
                                        <div className="flex items-center gap-1">
                                            <MessageOutlined /> <span className="text-base">{values?.comment_count}</span>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className="text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-300 cursor-pointer px-2 py-0.5 flex items-center justify-center rounded-lg">
                                    <Tooltip title="Like Post">
                                        <div className="flex items-center gap-1">
                                            <HeartOutlined /> <span className="text-base">{values?.like_count}</span>
                                        </div>
                                    </Tooltip>
                                </div>
                                {
                                    values?.is_complete &&
                                        <div className="text-gray-400 hover:text-blue-400 hover:bg-slate-100 duration-300 cursor-pointer 
                                                        px-2 py-0.5 flex items-center justify-center rounded-lg">
                                            <Tooltip title="Talk with Post Content">
                                                <div onClick={() => clickTalk({...values,'type':'content'})} className="flex items-center gap-1">
                                                    <CommentOutlined /> <span className="text-base">Talk to Post</span>
                                                </div>
                                            </Tooltip>
                                        </div>
                                }
                            </div>
                            <div className="flex items-center gap-1">
                                <div onClick={() => openShareLink({'type':'Post','name':`${values?.content.slice(0,50)}...`,'link':`/post/${values?.content.toLowerCase().split(' ').join('-').slice(0,30)}-${values?.id}`})} 
                                            className="text-xl text-gray-400 hover:text-blue-400 hover:bg-gray-100 duration-200 cursor-pointer px-1.5 aspect-square flex items-center justify-center rounded-lg">
                                    <Tooltip title="Share Post">
                                        <ShareAltOutlined />
                                    </Tooltip>
                                </div>
                                <div className="text-gray-400 bg-gray-50 hover:bg-gray-150 duration-200 
                                    cursor-pointer px-2.5 text-lg py-1.5 flex items-center justify-center rounded-lg">
                                    <Tooltip title="Related Posts">
                                        <DownOutlined />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                }
                <div className="flex items-center gap-2">
                    <div className="w-fit">
                        {
                            isEditable && user && user?.id === values?.author?.id
                            ?
                                isEditting
                                ?
                                    <div className="flex items-center gap-1">
                                        <Button 
                                            type='primary' 
                                            loading={loading}
                                            disabled={loading}
                                            icon={<SaveOutlined/>}
                                            className="py-1.5 font-medium text-base rounded-xl"
                                            onClick={editPost}>Save</Button>
                                        <Button 
                                            type='default' 
                                            className="py-1.5 font-medium text-base rounded-xl"
                                            onClick={() => setIsEditting(false)}><CloseOutlined/></Button>
                                    </div>
                                :
                                    <div className="px-1">
                                        <Button 
                                            loading={loading}
                                            disabled={loading}
                                            className="py-1.5 font-medium text-base rounded-lg"
                                            onClick={() => setIsEditting(true)}><EditOutlined/></Button>
                                    </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            <div>
                {
                    showComments && 
                        <CommentThread content_id={values?.id} showInput={!defaultShowComments} />
                }
            </div>
        </div>
    )
}

export default EditContentInput;
