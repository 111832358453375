import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;


class EmailServices {

    postWaitlist({email}){
        const values = {
            'email':email
        }
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/waitlist`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    patchWaitlist({email,waitlist_id}){
        const values = {
            'email':email
        }
        const configuration = {
            credentials: 'include',
            method: "patch",
            url: API_URL + `/waitlist/${waitlist_id}`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    addToWaitlist({email,social}){
        //
        const values = {
            'email':email,
            'social_link':social
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/add-to-waitlist`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    generateContext({values}) {
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/generate/context`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            error = new Error();
        });
    };
}
export default new EmailServices();