import { CommentOutlined, EditOutlined, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { useCommunityContext } from "../Context/CommunityContext";
import { Button } from "antd";

const PageNav = () => {
    const {setIsSiderVisible,isChatSiderVisible,setIsChatSiderVisible,setIsCreatePostModalVisible} = useCommunityContext();

    return(
        window.innerWidth > 1000
        ?
            <>
                <div className={`fixed bottom-0 md:bottom-auto top-auto md:top-0 left-0 p-3 items-center justify-center z-40 md:z-70 flex`}>
                    <div className="flex-1">
                        <div onClick={() => setIsSiderVisible(prev => !prev)} 
                            className="text-2xl md:text-lg cursor-pointer w-fit rounded-xl md:rounded-lg aspect-square px-3 md:px-2 border-2 flex items-center justify-center" 
                            style={{borderColor: 'var(--tertiary1)',color: 'var(--tertiary1)', backgroundColor: 'var(--secondary)'}}>
                            <MenuOutlined />
                        </div>
                    </div>
                </div>
                <div className={`fixed bottom-0 md:bottom-auto top-auto md:top-0 right-0 p-3 items-center justify-center z-40 md:z-70 flex
                    ${isChatSiderVisible ? 'invisible' : 'visible'}`}>
                    <div className="flex-1">
                        <div onClick={() => setIsChatSiderVisible(prev => !prev)} 
                            className="text-2xl md:text-lg cursor-pointer w-fit rounded-xl md:rounded-lg aspect-square px-3 md:px-2 border-2 flex items-center justify-center" 
                            style={{borderColor: 'var(--tertiary1)',color: 'var(--tertiary1)', backgroundColor: 'var(--secondary)'}}>
                            <CommentOutlined />
                        </div>
                    </div>
                </div>
            </>
        :   <div className="fixed bottom-0 md:bottom-10 right-0 left-0 md:left-auto p-2 pb-4 z-10">
                <Button type='outline' size='large' icon={<PlusOutlined />} onClick={() => setIsCreatePostModalVisible(true)}
                    className="w-full rounded-full bg-slate-100 hover:bg-slate-200 active:bg-slate-200 text-lg md:text-base shadow-md text-slate-400 hover:text-text-slate-600 border-slate-300 h-auto">
                    Create Content
                </Button>
            </div>
    )
}

export default PageNav;