import { Button, Input, message } from "antd";
import { useAuth } from "../../../../utils/useAuth";
import { useEffect, useRef, useState } from "react";
import AiServices from "../../../../utils/AiServices";


function AIInput({setGeneratedText}) {
    const aiInputRef = useRef();
    const {user,refresh} = useAuth();
    const [text,setText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);

    const generateContent = async () => {
        if (!text.trim()) {
            message.info('Please enter some text to generate from.');
            return;
        }
        setIsGenerating(true);
        try {
            // This is a placeholder for the actual API call
            // Replace this with your actual AI generation service
            const response = await AiServices.generateText({prompt:text,user:user});
            if(response.status === 401){
                let newUser = await refresh();
                response = await AiServices.generateText({prompt:text,user:newUser});
            }            
            if (response.status === 'success') {
                setGeneratedText(prev => prev + '\n' + response.data);
                setText(null);
            } else {
                message.error('Failed to generate content. Please try again.');
            }
        } catch (error) {
            console.error('Error generating content:', error);
            message.error('An error occurred while generating content.');
        } finally {
            setIsGenerating(false);
        }
    };

    useEffect(() => {
        aiInputRef.current.focus();
    },[]);

    return(
        <Input 
            ref={aiInputRef}
            placeholder="Enter your prompt for AI assistance..."
            size="large"
            className="text-base"
            value={text}
            onChange={(value) => setText(value.target.value)}
            suffix={
                <Button 
                    type="ghost" 
                    size="small" 
                    loading={isGenerating} 
                    className={`text-gray-400 ${!text.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:text-blue-400'}`} 
                    onClick={generateContent}
                    disabled={!text.trim()} >
                    Generate
                </Button>
            }
            autoSize={{minRows:4}}
        />
    )
}

export default AIInput;
