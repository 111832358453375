import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from "react-router-dom";
import './App.css';
import React,{ Suspense } from "react";
import "antd/dist/reset.css";
import './output.css';
import './style/index.css';
import { ChatProvider } from "./containers/chat/components/context/ChatContext";
import { AuthProvider } from "./utils/useAuth";
import { RegisterProvider } from "./containers/register/RegisterContext";
import LoadingPage from "./containers/landing/LoadingPage";
import HomePageForProfessionals from "./containers/landing/HomePageForProfessionals";
import { CommunityProvider } from "./containers/community/components/Context/CommunityContext";
import FeedPage from "./containers/community/pages/FeedPage";
import CommunitiesPage from "./containers/community/pages/CommunitiesPage";
import TopicsPage from "./containers/community/pages/TopicsPage";

const HomePage = React.lazy(() => import("./containers/landing/HomePage"));
const ChatPage = React.lazy(() => import("./containers/chat/containers/ChatPage"));
const PostPage = React.lazy(() => import("./containers/community/pages/PostPage"));
const CategoryPage = React.lazy(() => import("./containers/community/pages/CategoryPage"));
const Error404Page = React.lazy(() => import("./containers/landing/Error404Page"));
const LoginPage = React.lazy(() => import("./containers/landing/LoginPage"));
const CommunityPage = React.lazy(() => import("./containers/community/pages/CommunityPage"));
const DashboardLayout = React.lazy(() => import("./containers/dashboard/DashboardLayout"));
const DashboardContentPage = React.lazy(() => import("./containers/dashboard/pages/DashboardContentPage"));
const DashboardAppearancePage = React.lazy(() => import("./containers/dashboard/pages/DashboardAppearancePage"));
const DashboardAgentPage = React.lazy(() => import("./containers/dashboard/pages/DashboardAgentPage"));
const DashboardMessagesPage = React.lazy(() => import("./containers/dashboard/pages/DashboardMessagesPage"));
const DashboardComingSoonPage = React.lazy(() => import("./containers/dashboard/pages/DashboardComingSoonPage"));
const OnboardingPricingPages = React.lazy(() => import("./containers/onboarding/pages/OnboardingPricingPages"));
const OnboardingLayout = React.lazy(() => import("./containers/onboarding/OnboardingLayout"));
const OnboardingThemePage = React.lazy(() => import("./containers/onboarding/pages/OnboardingThemePage"));
const DashboardMorePage = React.lazy(() => import("./containers/dashboard/pages/DashboardMorePage"));
const DashboardSettingsPage = React.lazy(() => import("./containers/dashboard/pages/DashboardSettingsPage"));

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Suspense fallback={<LoadingPage />} ><HomePage /></Suspense>} />
            <Route path='/professionals' element={<Suspense fallback={<LoadingPage />} ><HomePageForProfessionals /></Suspense>} />
            <Route path='loading' element={<Suspense fallback={<LoadingPage />} ><LoadingPage /></Suspense>} />
            <Route path='register' element={<Suspense fallback={<LoadingPage />} ><RegisterProvider /></Suspense>}>
                <Route path='' element={<Suspense fallback={<LoadingPage />} ><LoginPage type="register" /></Suspense>} />
                <Route path='select-plan' element={<Suspense fallback={<LoadingPage />} ><LoginPage type="register" /></Suspense>} />
                <Route path='create/select-template' element={<Suspense fallback={<LoadingPage />} ><LoginPage type="register" /></Suspense>} />
                <Route path='create/select-platforms' element={<Suspense fallback={<LoadingPage />} ><LoginPage type="register" /></Suspense>} />
                <Route path='create/add-links' element={<Suspense fallback={<LoadingPage />} ><LoginPage type="register" /></Suspense>} />
                <Route path='create/name-image-bio' element={<Suspense fallback={<LoadingPage />} ><LoginPage type="register" /></Suspense>} />
            </Route>
            <Route path='dashboard' element={<Suspense fallback={<LoadingPage />} ><DashboardLayout /></Suspense>}>
                <Route path='content' element={<Suspense fallback={<LoadingPage />} ><DashboardContentPage /></Suspense>} />
                <Route path='messages' element={<Suspense fallback={<LoadingPage />} ><DashboardMessagesPage /></Suspense>} />
                <Route path='agents' element={<Suspense fallback={<LoadingPage />} ><DashboardAgentPage /></Suspense>} />
                <Route path='customize' element={<Suspense fallback={<LoadingPage />} ><DashboardAppearancePage /></Suspense>} />
                <Route path='analytics' element={<Suspense fallback={<LoadingPage />} ><DashboardComingSoonPage /></Suspense>} />
                <Route path='settings' element={<Suspense fallback={<LoadingPage />} ><DashboardSettingsPage /></Suspense>} />
                <Route path='more' element={<Suspense fallback={<LoadingPage />} ><DashboardMorePage /></Suspense>} />
            </Route>
            <Route path='onboarding' element={<Suspense fallback={<LoadingPage />} ><OnboardingLayout /></Suspense>}>
              <Route path='pricing' element={<Suspense fallback={<LoadingPage />} ><OnboardingPricingPages /></Suspense>} />
              <Route path='customize' element={<Suspense fallback={<LoadingPage />} ><OnboardingThemePage /></Suspense>} />
            </Route>
            <Route path='/login' element={<Suspense fallback={<LoadingPage />} ><LoginPage /></Suspense>} />
            <Route element={<CommunityProvider><Suspense fallback={<LoadingPage />} ><Outlet/></Suspense></CommunityProvider>}>
              <Route path='/feed' element={<FeedPage/ >} />
              <Route path='/feed/:order' element={<FeedPage/ >} />
              <Route path='/topics' element={<TopicsPage/ >} />
              <Route path='/topics/:order' element={<TopicsPage/ >} />
              <Route path='/communities' element={<CommunitiesPage / >} />
              <Route path='/communities/:order' element={<CommunitiesPage / >} />
              <Route path='/group/:name' element={<CommunityPage />} />
              <Route path='/group/:name/:order' element={<CommunityPage />} />
              <Route path='/post/:name' element={<PostPage/>} />
              <Route path='/post/:name/:order' element={<PostPage/>} />
              <Route path='/category/:category' element={<CategoryPage/>} />
              <Route path='/category/:category/:order' element={<CategoryPage/ >} />
            </Route>
            <Route path='/:username/*' element={<Suspense fallback={<LoadingPage />} ><ChatProvider><ChatPage/></ChatProvider></Suspense>} />              
            <Route path="/404" element={<Error404Page />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
