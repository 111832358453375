

import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;


class AiServices {
    generateText({prompt,user}){
        const data = {
            'prompt':prompt
        }
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/ai/generate`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:data
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    enhance20QuestionsPrompt({text,user}){
        const data = {
            'system':"You are an prompt expert helping someone create a topic statement to prompt generating 20 questions. The goal is to get 20 questions that will help get to know the user across a broad spetrum of ideas related to the topic. Just return as an answer.",
            'text':`Help me create a prompt for a 20 questions bot for:\n${text}\n\n`
        }
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/ai/generate/enhance`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:data
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
    enhance20QuestionsAnswer({question,answer,user}){
        const data = {
            'system':`You are an writing expert. Help enhance the following user input better answer the question. Question ${question}\n\nDon't change the answer too much but help enhance, reword and add to their answer. Try to keep the same writing style. Just return as an answer.`,
            'text':`Help enhance the answer for this question. Use the same style and tone as the given answer.\nAnswer:\n\n${answer}`
        }
        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/ai/generate/enhance`,
            headers:{"Authorization" : `Bearer ${user.access}`},
            data:data
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new AiServices();