import { Input, Modal,Button, Form, message } from "antd";
import { useRef, useState } from "react";
import CommunityServices from "../../utils/CommunityServices";
import { CheckCircleOutlined, CloseCircleOutlined, LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../utils/useAuth";
import GeneralAvatar from "../../../../components/avatar/GeneralAvatar";
import ImageServices from "../../../../utils/ImageServices";


const BasicInfoPage = ({values,setValues,nextPage}) => {
    const [page,setPage] = useState(0);
    const [communityPathValidating,setCommunityPathValidating] = useState(false);
    const [communityPathValidated,setCommunityPathValidated] = useState(false);

    const clickNext = () => {
        if(values?.name && values?.name !== ''){
            if(page === 0){
                setPage(prev => prev+1);
                setValues(prev => ({...prev,'path':values?.name.toLowerCase().split(' ').join('-')}))
            }
            else{
                nextPage();
            }      
        }
        else{
            message.info('Please enter a name');
        }
    }

    return(

        <div className="w-full">
            <Form>
                <div className="w-full flex flex-col gap-2">
                    <div>
                        <div className="p-0.5 text-slate-500">
                            Community Name:
                        </div>
                        <Input 
                            size='large' placeholder="Community Name" 
                            value={values?.name}
                            suffix={values?.name !== '' && <CheckCircleOutlined style={{color:'#52c41a',fontSize:'14px'}} />}
                            onChange={(value) => {
                                setValues(prev => ({...prev,'name':value.target.value}))
                            }} />
                    </div>
                    <div>
                        <div className="p-0.5 text-slate-500">
                            Description (optional):
                        </div>
                        <div>
                            <Input.TextArea 
                                    size='large' 
                                    placeholder="Short Description about your Community"
                                    value={values?.description}
                                    rows={4}
                                    onChange={(value) => setValues(prev => ({...prev,'description':value.target.value}))} />
                        </div>
                    </div>
                    {
                        page > 0 &&
                            <div className="w-full flex flex-col gap-2">
                                <div>
                                    <div className="p-0.5 text-slate-500">
                                        Path:
                                    </div>
                                    <Form.Item
                                        name="path"
                                        className='m-0'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input a path!',
                                                whitespace: true,
                                            },
                                            {
                                                validator: async (_, value) => {
                                                    setCommunityPathValidating(true);
                                                    if(value.includes('_')){
                                                        setCommunityPathValidating(false);
                                                        setCommunityPathValidated(false);
                                                        return Promise.reject('Sorry! Username cannot contain the following: _');
                                                    }
                                                    else if(value.includes(':')){
                                                        setCommunityPathValidating(false);
                                                        setCommunityPathValidated(false);
                                                        return Promise.reject('Sorry! Username cannot contain the following: :');
                                                    }
                                                    else if(value.includes('@')){
                                                        setCommunityPathValidating(false);
                                                        setCommunityPathValidated(false);
                                                        return Promise.reject('Sorry! Username cannot contain the following: :');
                                                    }
                                                    else{
                                                        const check = await CommunityServices.checkCommunityPath({'path': value});
                                                        setCommunityPathValidating(false);
                                                        if (check === false) {
                                                            setCommunityPathValidated(false);
                                                            return Promise.reject('Sorry! This Username Already Exists!');
                                                        } 
                                                        else {
                                                            setCommunityPathValidated(true);
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                    
                                                }
                                            }
                                        ]}>
                                        <Input 
                                            size='large' placeholder="path" 
                                            defaultValue={values?.path}
                                            value={values?.path}
                                            prefix={<span className="text-slate-400">/group/</span>}
                                            suffix={values?.path !== '' ? communityPathValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : communityPathValidated ? <CheckCircleOutlined style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : <CloseCircleOutlined style={{opacity:0}} />} 
                                            onChange={(value) => {
                                                setValues(prev => ({...prev,'path':value.target.value}));
                                            }} />
                                    </Form.Item>
                                </div>
                            </div>
                    }
                    <div className="flex w-full justify-end">
                        <div className="flex items-center gap-2">
                            <Button type={'outline'} onClick={clickNext} className="w-full md:w-fit rounded-full bg-slate-500 hover:bg-slate-700 text-white hover:text-white border-none h-auto">
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}

const MoreInfoPage = ({values,setValues,setIsCreateCommunityModalVisible,setPage}) => {
    let newUser;
    const avatarRef = useRef();
    const bannerRef = useRef();
    const {user,refresh} = useAuth();
    const [saving,setSaving] = useState(false);
    const [uploadingAvatar,setUploadingAvatar] = useState(false);
    const [uploadingBanner,setUploadingBanner] = useState(false);
    const [bannerImage,setBannerImage] = useState(null);
    const [avatar,setAvatar] = useState(null);

    const postAvatar = async (file) => {
        let avatarImageUploadResponse = await ImageServices.postAvatar({image:file, name: `${user.username}-avatar-${file.name.slice(0,30)}`, user:user})
        if(avatarImageUploadResponse?.status === 401){
            newUser = await refresh();
            avatarImageUploadResponse = await ImageServices.postAvatar({image:file, name: `${user.username}-avatar-${file.name}`, user:newUser})
        }
        return avatarImageUploadResponse
    }
    const postBanner = async (file) => {
        let imageUploadResponse = await ImageServices.postImage({image:file, name: `${user.username}-banner-${file.name.slice(0,30)}`, user:user})
        if(imageUploadResponse?.status === 401){
            newUser = await refresh();
            imageUploadResponse = await ImageServices.postImage({image:file, name: `${user.username}-banner-${file.name.slice(0,30)}`, user:newUser})
        }
        return imageUploadResponse
    }
    const fileUpload = async (event,fileType) => {
        if(fileType === 'banner'){
            setUploadingBanner(true);
        }
        else{
            setUploadingAvatar(true);
        }
        var file = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);

        let uploadResponse;
        if(fileType === 'banner'){
            uploadResponse = await postBanner(file);
        }
        else{
            uploadResponse = await postAvatar(file);
        }
        if(uploadResponse?.status === 'success'){
            if(fileType === 'banner'){
                setBannerImage(uploadResponse?.data?.image);
                setValues(prev => ({...prev,'banner_image_id':uploadResponse?.data?.id}));
                setUploadingBanner(false);
            }
            else{
                setAvatar(uploadResponse?.data?.image);
                setValues(prev => ({...prev,'avatar_id':uploadResponse?.data?.id}))
                setUploadingAvatar(false);
            }
        }
        else{
            message.info('Error on Upload! Try Again.');
            setUploadingBanner(false);
            setUploadingAvatar(false);
        }
    };
    const onFinish = async () => {
        setSaving(true);
        if(!uploadingAvatar && !uploadingBanner){
            let communityValues = {
                ...values,
                'is_community':true
            }
            let communityResponse = await CommunityServices.createCommunity({values:communityValues, user:user});
            if(communityResponse.status === 401){
                newUser = await refresh();
                communityResponse = await CommunityServices.createCommunity({values:communityValues, user:newUser});
            }
            if(communityResponse.status === 'success'){
                message.success('Successfully Created Community');
                setTimeout(() => {
                    window.location.href = `/group/${communityResponse?.data?.path}`;
                },[1000]);
            }
        }
        else{
            message.info('Wait for images to upload.')
        }
        setSaving(false);        
    }
    return(
        <div className="flex flex-col gap-4">
            <div className="rounded-2xl border bg-slate-100">
                <div className="px-8 py-6">
                    <div>
                        <div>
                            <h2 className="font-bold text-xl">{values?.name}</h2>
                        </div>
                        <div className="text-slate-400">
                            <LinkOutlined/> /group/{values?.path}
                        </div>
                    </div>
                    <div>
                        {values?.description}
                    </div>
                </div>
            </div>
            <div>
                <div className="flex flex-col gap-2 mb-4">
                    <div className="text-sm text-slate-500">
                        Avatar Image
                    </div>
                    <div className="flex items-center gap-2">
                        <GeneralAvatar image={avatar} name={values?.name} loading={uploadingAvatar} size="large" />
                        <span onClick={() => {if(!uploadingAvatar){avatarRef?.current.click();}}} className="cursor-pointer text-slate-400 hover:text-slate-500 duration-300">
                            Upload Avatar
                        </span>
                    </div>
                    <input type='file' id='file' ref={avatarRef} accept="image/*" onChange={(event) => fileUpload(event,'avatar')} style={{display: 'none'}}/>
                </div>
                <div className="flex flex-col gap-2 mb-4">
                    <div className="text-sm text-slate-500">
                        Banner Image
                    </div>
                    <div>
                        <div onClick={() => {if(!uploadingBanner){bannerRef?.current.click();}}} 
                                className="rounded-2xl w-full aspect-[5/1] bg-cover bg-center bg-no-repeat bg-slate-100 hover:bg-slate-200 text-slate-500 hover:text-slate-600 
                                            border border-slate-300 duration-300 cursor-pointer flex items-center justify-center" 
                                style={bannerImage ? {backgroundImage:`url(${bannerImage})`} : {}}>
                                {   
                                    uploadingBanner
                                    ?   <span><LoadingOutlined/> Uploading...</span>
                                    :   !bannerImage
                                        ?   <span >Upload Banner</span>
                                        :   null
                                }
                        </div>
                    </div>
                    <input type='file' id='file' ref={bannerRef} accept="image/*" onChange={(event) => fileUpload(event,'banner')} style={{display: 'none'}}/>
                </div>                
            </div>
            <div className="flex w-full justify-end">
                <div className="flex items-center gap-1">
                    <Button type={'text'} disabled={saving || uploadingAvatar || uploadingBanner} onClick={() => setPage(prev => prev-1)} className="w-full md:w-fit rounded-full text-slate-600 hover:text-slate-800 bg-white border-none h-auto">
                        Back
                    </Button>
                    <Button type={'outline'} loading={saving} disabled={saving || uploadingAvatar || uploadingBanner} onClick={onFinish} className="w-full md:w-fit rounded-full bg-slate-500 hover:bg-slate-700 text-white hover:text-white border-none h-auto">
                        Finish
                    </Button>
                </div>
            </div>
        </div>
    )
}

const CreateCommunityModal = ({isCreateCommunityModalVisible,setIsCreateCommunityModalVisible,onClose}) => {
    const [values,setValues] = useState({});
    const [page,setPage] = useState(0);

    return(
        <Modal
            open={isCreateCommunityModalVisible} 
            footer={null} 
            onCancel={onClose}
            width={window.innerWidth > 1000 ? '100%' : '95%'}
            style={{maxWidth:'600px',overflow:'hidden',marginBottom:'5vh'}}
            className="shadow-xl rounded-3xl p-0"
            centered
            destroyOnClose
            >
            <div className="w-full h-full p-2 md:p-8 py-4 py-8">
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-4">
                        <h2 className="text-2xl font-semibold">
                            Create a Community
                        </h2>
                        <div>
                            {
                                {
                                    0:<BasicInfoPage values={values} setValues={setValues} nextPage={() => setPage(prev => prev+1)} />,
                                    1:<MoreInfoPage values={values} setValues={setValues} setIsCreateCommunityModalVisible={setIsCreateCommunityModalVisible}  setPage={setPage} />
                                }[page]
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default CreateCommunityModal;