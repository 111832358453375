import { Skeleton } from "antd";

function ContentLoadingCard() {
    return(
        <div className="bg-white shadow-xl rounded-3xl pt-4 md:pt-6 px-2 md:px-6 pb-6 flex flex-col w-full gap-3 overflow-hidden">
            <div className="w-full flex items-center gap-2 px-4 py-2">
                <div className="flex-1 flex items-start gap-2">
                    <Skeleton.Avatar shape="circle" size={'large'} active/>
                    <div className="flex flex-col gap-0.5 pt-1">
                        <Skeleton.Input style={{height:'18px'}} active/>
                        <Skeleton.Input style={{height:'12px'}} active/>
                    </div>
                </div>
                <div>
                    <Skeleton.Input style={{height:'25px',borderRadius:'15px'}} active/>
                </div>
            </div>
            <div className="w-full flex flex-col gap-1 px-4">
                <Skeleton.Input style={{height:'20px'}} block active/>
                <Skeleton.Input style={{height:'20px'}} block active/>
                <Skeleton.Input style={{height:'20px'}} block active/>
                <div className="w-8/12">
                    <Skeleton.Input style={{height:'16px'}} block active/>
                </div>
            </div>
            <div className="flex items-center gap-1 px-4">
                <div className="flex-1 flex items-center gap-1">
                    <Skeleton.Avatar shape="square" style={{borderRadius:'6px'}} active/>
                    <Skeleton.Avatar shape="square" style={{borderRadius:'6px'}} active/>
                    <Skeleton.Avatar shape="square" style={{borderRadius:'6px'}} active/>
                </div>
                <div className="flex items-center gap-1">
                    <Skeleton.Avatar shape="square" style={{borderRadius:'6px'}} active/>
                </div>
            </div>
        </div>
    )
}

export default ContentLoadingCard;
