import { Layout } from "antd";
import { Helmet } from "react-helmet-async";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import LoadingPage from "../../landing/LoadingPage";
import ExploreSider from "../components/Explore/ExploreSider";
import { Content } from "antd/es/layout/layout";
import { useCommunityContext } from "../components/Context/CommunityContext";
import ContentServices from "../utils/ContentServices";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../components/search/SearchInput";
import { LoadingOutlined } from "@ant-design/icons";
import PageNav from "../components/Nav/PageNav";
import CommunityNav from "../components/Feed/CommunityNav";

const TopicsPage = () => {
    const loader = useRef(null);
    const {setIsSiderVisible} = useCommunityContext();
    const [loading,setLoading] = useState(true);
    const [tags,setTags] = useState([]);
    const [tagCount,setTagCount] = useState(0);
    const [currentPage,setCurrentPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    const getTopics = async ({page,limit=100}) => {
        setLoading(true);
        const tagResponse = await ContentServices.getTags({page:page,limit:limit});
        if(tagResponse?.results){
            setTagCount(tagResponse.count)
            setTags(prev => [...prev,...tagResponse?.results]);
            setCurrentPage(prev => prev + 1);

            if(tagResponse.length >= tagResponse.count){
                setHasMore(false);
            }
        }
        setLoading(false);
    }
    const clickTopic = (value) => {
        navigate(`/feed?search=${value}`);
    }
    const onSearch = (value) => {
        const url = new URL(window.location.href);
        url.searchParams.set('search',value);
        window.history.replaceState(null, '', url);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTags([]);
        getTopics({page:0,limit:100});
    }
    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && hasMore) {
            setTimeout(() => {
                getTopics({page:currentPage,limit:100});
            },[500]);
        }
    }, [loading]);

    useEffect(() => {
        const observer = new IntersectionObserver(handleObserver, { threshold: 1.0 });
        if (loader.current) observer.observe(loader.current);
        return () => observer.disconnect();
    }, [handleObserver]);

    useEffect(() => {
        if(window.innerWidth > 1000){
            setIsSiderVisible(true);
        }
        getTopics({page:0});        
    },[])

    return(
        <Layout className="w-full h-full min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Topics | TalkInBio`}</title>
                <meta property="og:title" content={`Topics | TalkInBio`}/>
                <meta name="description" content={`Topics | TalkInBio | AI and LLM Centric Topics.`} />
            </Helmet>
            <PageNav/>
            <Layout className="w-full h-full" >
                <Suspense fallback={null}>
                    <ExploreSider />
                </Suspense>
                <Content className="w-full h-full">
                    <Suspense fallback={<LoadingPage/>}>
                        <div className="pt-8 md:pt-0 w-full h-auto md:h-screen overflow-y-auto">
                            <CommunityNav type='Topics' />
                            <div className="w-full md:w-4/5 max-w-[600px] m-auto p-4 md:p-0">
                                <div className="pt-12 px-2 mb-2 text-left text-slate-600">
                                    <div className="mb-1">
                                        <h1 className="font-bold text-2xl">
                                            Explore Topics
                                        </h1>
                                    </div>
                                    <div className="text-slate-400 text-xs">
                                        {tagCount} total topics
                                    </div>
                                </div>
                                <div className="p-2">
                                    <SearchInput onSearch={onSearch} />
                                </div>
                                <div className="flex flex-col items-start p-2 gap-1 pb-20">
                                    <div className="flex items-center gap-2 flex-wrap text-left leading-tight">
                                        {
                                            tags && tags.length > 0 &&
                                                tags.map((value,index) => 
                                                    <div key={`tags-${index}`} onClick={() => clickTopic(value?.name)}
                                                        className="px-3 py-1 bg-slate-300 hover:bg-slate-400 text-slate-700 hover:text-slate-800 duration-300
                                                        text-sm rounded-lg md:rounded-md cursor-pointer leading-tight shadow-sm">
                                                        {value?.name || value}
                                                    </div>
                                                )
                                        }
                                    </div>
                                    {
                                        loading &&
                                            <div className="text-2xl text-left">
                                                <LoadingOutlined/>
                                            </div>
                                    }
                                    <div ref={loader}  className="w-full h-2"></div>
                                </div>    
                            </div>
                        </div>
                    </Suspense>
                </Content>
            </Layout>
        </Layout>
    )
}

export default TopicsPage;