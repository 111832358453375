import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import LoginForm from "../../../../components/form/LoginForm";
import RegistrationForm from "./RegistrationForm";
import { ConfigProvider } from "antd";
import { CommentOutlined } from "@ant-design/icons";

function LoginModal({isLoginModalVisible,setIsLoginModalVisible,type='login'}){
    const [activeKey,setActiveKey] = useState(type)
    const navigate = useNavigate();
    
    const focusInput = async () => {
        const element = document.getElementById('username-input');
        if(element){
            const fieldElement = document.getElementById('username-input-div');
            await fieldElement.classList.remove("bounce-sm");
            element.focus();
            fieldElement.classList.add("bounce-sm");
        }
        else{
            setTimeout(() => {
                focusInput();
            },500)
        }
    }
    const closeModal = () => {
        setIsLoginModalVisible(false);
    }
    const onRegisterSuccess = () => {
        window.location.href = '?register=success';
    }
    const onLoginSuccess = () => {
        window.location.href = '?login=success';
    }
    return(
        <ConfigProvider
            theme={{
                components:{
                    Modal:{
                        contentBg:'#1A73E8'
                    }
                },
                token: {
                    padding:16
                }}}>
            <Modal
                open={isLoginModalVisible} 
                footer={null} 
                onCancel={closeModal}
                width={'100%'}
                style={{maxWidth:'460px',overflow:'hidden',marginBottom:'5vh',backgroundColor:'#1A73E8'}}
                className="shadow-xl rounded-3xl"
                centered
                closable={true}
                destroyOnClose
                >
                <div className="px-4 py-6 bg-[#1A73E8]">
                    <div className="text-white flex items-center justify-center">
                        <div className="w-full px-6 py-10">
                            <div className='w-full'>
                                <div className='text-left mb-4 text-2xl font-bold'>
                                    {
                                        activeKey === 'login'
                                        ?   <p style={{marginBottom:'5px'}}>Login for Access</p>
                                        :   <div>
                                                <p className='mb-2'>Create an Account</p>
                                                <div className='text-xs font-medium'>
                                                    Limited Spots Availabile!
                                                </div>
                                            </div>
                                    }
                                </div>
                                <div className='mb-2 text-left'>
                                    {
                                        activeKey === 'login'
                                        ? <LoginForm onSuccess={onLoginSuccess} is_community={true} />
                                        : <RegistrationForm onSuccess={onRegisterSuccess} is_community={true} />
                                    }
                                </div>
                                <div className='text-slate-300 cursor-pointer mb-8 text-sm text-center'>
                                    {
                                        activeKey === 'login'
                                        ?   <div className='underline text-center' onClick={() => setActiveKey('register')} >
                                                Create an Account
                                            </div>
                                        :   <div className='underline' onClick={() => setActiveKey('login')}>
                                                Already have an account
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="m-auto">
                                <div className="text-2xl font-bold m-auto px-2 aspect-square w-fit flex items-center justify-center bg-white rounded-xl text-[#1A73E8]">
                                    <CommentOutlined/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </ConfigProvider>
    )
}

export default LoginModal;