

const OutlineSection = ({values,isSubsection=false}) => {
    return(
        <div>
            <h3 className={`font-semibold ${isSubsection ? 'text-base text-slate-600' : 'text-lg text-slate-700'}`}>
                {values?.title}
            </h3>
            {
                values?.content && values?.content.length > 0 &&
                    <ul className="list-disc pl-4">
                        {
                            values?.content.map((value,index) => 
                                <li key={`item-${index}`}>
                                    {value}
                                </li>
                            )
                        }
                    </ul>
            }
            {
                values?.subsections && values?.subsections.length > 0 &&
                    <div className="px-4 py-2 flex flex-col gap-2">
                        {
                            values?.subsections.map((value,index) => 
                                <OutlineSection key={`subsection-${index}`} values={value} isSubsection={true} />
                            )
                        }

                    </div>
            }
        </div>
    )
}


const Outline = ({values}) => {
    return(
        values?.title && values?.sections && values?.sections.length > 0
        ?
            <div className="w-full text-left leading-tight">
                <div className="flex flex-col gap-2">
                    <h2 className="text-xl font-semibold text-slate-800 leading-tight">
                        {values?.title}
                    </h2>
                    <div className="flex flex-col gap-2">
                        {
                            values?.sections && values?.sections.length > 0 &&
                                values?.sections.map((item,index) => 
                                    <OutlineSection key={`section-${index}`} values={item} />
                                )
                        }
                    </div>
                </div>
            </div>
        :
            <div className="pt-2 text-left text-slate-400 font-medium">
                TL;DR Not Found
            </div>
    )
}

export default Outline;