import { Avatar } from "antd";
import { useState } from "react";
import CommentInput from "./CommentInput";
import { HeartFilled, HeartOutlined, MessageOutlined } from "@ant-design/icons";
import ContentServices from "../../utils/ContentServices";
import { useAuth } from "../../../../utils/useAuth";

function timeAgo(dateInput) {
    const date = new Date(dateInput);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
  
    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };
  
    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const interval = Math.floor(seconds / secondsInUnit);
        if (interval >= 1) {
            return `${interval} ${unit}${interval > 1 ? 's' : ''} ago`;
        }
    }
    
    return "just now";  // for very recent times within seconds
}
  
const LikeComment = ({comment_id,like_count,user_has_liked=false}) => {
    const [hover,setHover] = useState(false);
    const [liked,setLiked] = useState(user_has_liked);
    const [count,setCount] = useState(like_count);
    const {user,refresh} = useAuth();

    const clickLike = async () => {
        setLiked(prev => !prev);
        if(liked){
            setCount(prev => prev - 1);
            let likeResponse = await ContentServices.removeLike({values:{comment:comment_id},user:user});
            if(likeResponse.status == 401){
                let newUser = await refresh();
                likeResponse = await ContentServices.removeLike({values:{comment:comment_id},'user':newUser});
            }
        }
        else{
            setCount(prev => prev + 1);
            let likeResponse = await ContentServices.addLike({values:{comment:comment_id},user:user});
            if(likeResponse.status == 401){
                let newUser = await refresh();
                likeResponse = await ContentServices.addLike({values:{comment:comment_id},'user':newUser});
            }
        }
    }

    return(
        <div className="pr-3 text-gray-400 duration-300 cursor-pointer flex items-center justify-center rounded-lg"
            onClick={clickLike} onMouseEnter={() => setHover(!user_has_liked)} onMouseLeave={() => setHover(user_has_liked)} >
            <div className="flex items-center gap-1">
                {liked || hover ? <HeartFilled/> : <HeartOutlined />} 
                <span className="text-xs">
                    {(hover && !liked) ? count+1 : count || 0}
                </span>
            </div>
        </div>
    )   
}

const Comment = ({content_id,values,setComments,comment_id=false}) => {
    const [showReplies,setShowReplies] = useState(false);    
    const [showCommentInput,setShowCommentInput] = useState(false);

    return(
        <div className="text-left cursor-pointer">
            <div className="flex items-start gap-1">
                <div>
                    <Avatar src={values?.author?.avatar?.image} size={'small'} />
                </div>
                <div className="flex-1 flex flex-col gap-3 p-0.5">
                    <div className="flex flex-col gap-1 pb-3 border-b border-slate-100">
                        {/* User */}
                        <div className="text-sm text-slate-600 font-medium cursor-pointer flex items-center gap-2">
                            {values?.author?.username}
                            <span className="text-[10px] text-slate-300 hover:text-slate-400 duration-300">{timeAgo(values?.created_at)}</span>
                        </div>
                        {/* Comment */}
                        <div className="text-slate-800">
                            {values?.text}
                        </div>
                        {/* Attachments */}
                        {
                            (values?.image || values?.file || values?.link) &&
                                <div>
            
                                </div>
                        }
                        {/* Actions */}
                        <div className="flex items-center">
                            <LikeComment comment_id={values?.id} like_count={values?.like_count} user_has_liked={values?.user_has_liked} />
                            <div onClick={() => setShowCommentInput(prev => !prev)} 
                                className="text-gray-400 text-xs hover:underline duration-300 
                                            cursor-pointer flex items-center justify-center rounded-lg">
                                Reply
                            </div>
                        </div>
                    </div>
                    {/* Reply */}
                    {
                        showCommentInput &&
                            <div className="w-full">
                                <CommentInput content_id={content_id} setComments={setComments} onFinish={() => setShowCommentInput(false)} comment_id={values?.id} />
                            </div>
                    }
                    {/* Replies */}
                    {
                        values?.replies && values?.replies.length > 0 &&
                            values?.replies.map((value,index) => 
                                <div key={`reply-${comment_id}-${index}`} >
                                    <Comment content_id={content_id} values={value} comment_id={values?.id} />
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    )

}

export default Comment;