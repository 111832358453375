import { LinkOutlined } from "@ant-design/icons";
import { Image, Popover } from "antd";
import { useState } from "react";

const LinkPreview = ({url,title,description,imageUrl}) => {
    const [imageError, setImageError] = useState(false);

    return(
        <div className="w-full text-gray-800">
            <a href={url} target="_blank" rel="noopener noreferrer" className="w-full">
                <div className="w-full flex items-center gap-2 p-2 border rounded-xl shadow-lg bg-slate-50 hover:bg-slate-200 cursor-pointer duration-300">
                    <div className="flex-shrink-0 w-12 h-12 bg-gray-100 flex items-center justify-center">
                        {imageUrl && !imageError ? (
                            <img
                                src={imageUrl}
                                alt="Preview"
                                className="w-full h-full object-cover"
                                onError={() => setImageError(true)}
                            />
                        ) : (
                            <span className="text-gray-400 text-sm">No Image</span>
                        )}
                    </div>
                    {/* Link Info Section */}
                    <div className="flex-grow relative h-full">
                        <div className="flex flex-col gap-0.5 text-left pr-2">
                            {/* Title with Single-Line Truncation */}
                            <div className="w-full">
                                <h4 className="text-xs leading-tight font-semibold text-gray-900 truncate">
                                    {title ? title.length > 30 ? `${title.slice(0,30)}...` : title : 'No Title Available'}
                                </h4>
                            </div>
                            {/* URL with Single-Line Truncation */}
                            <div className="text-xs text-slate-400 truncate">
                                <LinkOutlined/> {url ? url.replace('https://','').replace('http://','').replace('www.','').slice(0,30) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    )
}

const LinkAttachment = ({links}) => {
    return(
        <div className="w-fit flex flex-col items-stretch justify-start gap-2">
            {
                links.map((link,index) => {
                    return <div key={index} className="w-full flex-shrink-0 ">
                        <Popover content={<div>{link?.link}</div>}>
                            <LinkPreview url={link?.link} title={link?.title} description={link?.link} imageUrl={link?.image} />
                        </Popover>
                    </div>
                })
            }
        </div>
    )
}

export default LinkAttachment;