import { Avatar, Dropdown } from "antd";
import {  SettingOutlined } from "@ant-design/icons";
import { useAuth } from "../../../../utils/useAuth";
import { useState } from "react";
import { useCommunityContext } from "../Context/CommunityContext";


function AccountDropdown(){
    const [open,setOpen] = useState(false);
    const {user,logout} = useAuth();
    const {setIsUserManagerModalVisible} = useCommunityContext();

    const clickEditProfile = () => {
        setIsUserManagerModalVisible(true);
    }
    
    const clickLogout = () => {
        logout({onLogout:window.location.reload()});
    }

    const menuItem = () => {
        return(
            <div onMouseLeave={() => setOpen(false)} className="w-[300px] p-8 rounded-3xl bg-white shadow-lg flex flex-col gap-2">
                <div className="w-full flex items-center gap-2 p-1 rounded-full border">
                    {
                        user?.avatar?.image
                        ?   <Avatar src={user?.avatar?.image} size='large' />
                        :   <Avatar size='large' className="bg-black">{user?.username && user?.username.slice(0,1)}</Avatar>
                    }
                    <div className="font-bold">
                        @{user?.username}
                    </div>
                </div>
                <div onClick={clickEditProfile} className="w-full border rounded-full p-1.5 flex items-center justify-center cursor-pointer duration-200 hover:bg-slate-100 hover:border-gray-300 text-gray-400 hover:text-gray-500">
                    Edit Profile
                </div>
                <div>
                    <div className="px-3 pb-2 text-gray-700 font-bold">
                        Your Actions
                    </div>
                    <div className="flex flex-col gap-1">
                        <div onClick={clickLogout} className="w-full border rounded-full p-1.5 text-red-400 flex items-center justify-center cursor-pointer duration-200 hover:border-red-300">
                            Logout
                        </div>
                        <div onClick={() => setOpen(prev => !prev)} className="w-full border rounded-full p-1.5 flex items-center justify-center cursor-pointer duration-200 hover:border-gray-300">
                            <a className="text-gray-400">Close</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <Dropdown dropdownRender={menuItem} open={open} placement="topLeft" onClick={() => setOpen(prev => !prev)} className="w-full">
            <div className="w-full flex items-center gap-2 p-1 cursor-pointer hover:bg-slate-50 duration-300 rounded-full border-2 border-slate-100 hover:border-slate-200">
                <div className="flex-1 flex items-center gap-2">
                    {
                        user?.avatar?.image
                        ?   <Avatar src={user?.avatar?.image} size='large' />
                        :   <Avatar size='large' className="bg-black">{user?.username && user?.username.slice(0,1)}</Avatar>
                    }
                    <div className="font-bold">
                        {user?.username}
                    </div>
                </div>
                <div className="pr-4 text-slate-400">
                    <SettingOutlined />
                </div>
            </div>
        </Dropdown>
    )

}

export default AccountDropdown;