import { FileOutlined, LinkOutlined, PictureOutlined } from "@ant-design/icons";
import { Button, Input, message, Tooltip } from "antd";
import { useState } from "react";
import ContentServices from "../../utils/ContentServices";
import { useAuth } from "../../../../utils/useAuth";

const addReplyToNestedComment = (comments, comment_id, reply) => {
    return comments.map(comment => {
        // Check if the current comment is the one we're looking for
        if (comment.id === comment_id) {
            return {
            ...comment,
            replies: [reply, ...comment.replies]
            };
        }
    
        // Otherwise, recursively search in the replies of the current comment
        if (comment.replies && comment.replies.length > 0) {
            return {
            ...comment,
            replies: addReplyToNestedComment(comment.replies, comment_id, reply)
            };
        }
    
        return comment; // Return the comment unchanged if no match
    });
};

const CommentInput = ({content_id,setComments,onFinish,comment_id=false,defaultShowComment=true}) => {
    const {user,refresh} = useAuth();
    const [loading,setLoading] = useState(false);
    const [comment,setComment] = useState('');
    const [showComment,setShowComment] = useState(defaultShowComment);

    const postComment = async () => {
        if(comment.length > 3 ){
            setLoading(true);
            let params = {
                content_id:content_id,
                text:comment,
                user:user,
                comment_id:comment_id
            }
            let commentResponse = await ContentServices.postComment(params);
            if(commentResponse.status == 401){
                let newUser = await refresh();
                commentResponse = await ContentServices.postComment({...params,'user':newUser});
            }
            if(commentResponse?.content){
                message.success('Comment posted!');
                setShowComment(false);
                setComment('');
                if(comment_id){
                    setComments(prevComments => addReplyToNestedComment(prevComments, comment_id, commentResponse));
                }
                else{
                    setComments(prev => ([commentResponse.data,...prev]));
                }
                onFinish();
            }
            setLoading(false);
        }
        else{
            message.info('Comment must be at least 3 characters')
        }
    }

    

    return(
        <div>
            {
                showComment
                ?
                    <div className="rounded-2xl border bg-slate-100" >
                        <div className="p-1 pb-0">
                            <Input.TextArea
                                value={comment}
                                onChange={(value) => setComment(value.target.value)}
                                variant="borderless"
                                className="text-slate-800"
                                placeholder="Add a Comment"
                                autoFocus
                                />
                        </div>
                        <div className="flex items-center p-1">
                            <div className="flex-1 flex items-center px-2 gap-2">
                                <Tooltip title="Attach Image">
                                    <div className="cursor-pointer rounded-full px-1 aspect-square text-slate-400 hover:text-slate-600 hover:bg-slate-200 duration-300 flex items-center justify-center">
                                        <PictureOutlined/>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Attach File">
                                    <div className="cursor-pointer rounded-full px-1 aspect-square text-slate-400 hover:text-slate-600 hover:bg-slate-200 duration-300 flex items-center justify-center">
                                        <FileOutlined/>
                                    </div>
                                </Tooltip>
                                <Tooltip title="Attach Link">
                                    <div className="cursor-pointer rounded-full px-1 aspect-square text-slate-400 hover:text-slate-600 hover:bg-slate-200 duration-300 flex items-center justify-center">
                                        <LinkOutlined/>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="flex items-center gap-1">
                                <Button onClick={() => setShowComment(false)} type='outline' size='small' className="text-xs rounded-full text-slate-600 bg-slate-100 hover:bg-slate-200 shadow-none">
                                    Cancel
                                </Button>
                                <Button onClick={postComment} loading={loading} disabled={loading} type='outline' size='small' className="text-xs rounded-full text-slate-600 bg-slate-300 hover:bg-slate-400 shadow-none">
                                    Comment
                                </Button>
                            </div>
                        </div>
                    </div>
                :   <div onClick={() => setShowComment(true)} 
                        className="rounded-2xl border bg-slate-100 text-slate-300 text-sm p-1.5 px-3 cursor-text w-full text-left" >
                        Add a Comment
                    </div>
            }
        </div>
    )

}

export default CommentInput;