import React, {useState} from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import { useAuth } from '../../utils/useAuth';


const LoginForm = ({onSuccess=false,is_community=false}) => {
  const {login} = useAuth();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    // Login with Auth Service
    setLoading(true);
    await login({username: values.username, password:values.password,is_community:is_community,setLoading:setLoading,onSuccess:onSuccess});
  };

  return (
    <Form
      name="normal_login"
      className="login-form m-0 py-2"
      layout="vertical"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
    >
      <div className='mb-4'>
        <div className='p-0.5 text-white font-medium'>
            Email or Username<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
            name="username"
            className='m-0'
            rules={[
                {
                    required: true,
                    message: 'Please input your Email or Username!',
                }
            ]}>
            <Input 
              prefix={<UserOutlined className="site-form-item-icon" />}
              style={{borderRadius:'10px'}} 
              placeholder="Email or Username" 
              size='large' />
        </Form.Item>
      </div>
      <div>
        <div className='p-0.5 text-white font-medium'>
            Password<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
          name="password"
          className='m-0'
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            size='large'
            style={{borderRadius:'10px'}} 
          />
        </Form.Item>
      </div>
      <div className='p-1 m-0 my-2 flex items-center w-full text-white text-sm'>
        <div className='flex-1'>
          <Form.Item name="remember" valuePropName="checked" className='m-0' noStyle>
            <Checkbox style={{color:'white'}}>Remember me</Checkbox>
          </Form.Item>
        </div>
        <div>
          <a className="login-form-forgot" href="/password-recovery">
            Forgot password
          </a>          
        </div>
      </div>
      <div>
        <Button type="primary" htmlType="submit" className="text-md p-2 md:p-1.5 font-semibold h-auto rounded-full w-full shadow-none" loading={loading}>
          {is_community ? 'Login' : 'Go to Dashboard'}
        </Button>
      </div>
    </Form>
  );
};

export default LoginForm;