import React, { useEffect, useState } from 'react';
import {Button, Form, Input, message} from 'antd';
import { CheckCircleFilled, CloseCircleOutlined, LoadingOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../../../utils/useAuth';
import AuthServices from '../../../../utils/AuthServices';

const RegistrationForm = ({onSuccess=false,is_community=false}) => {
  const [loading, setLoading] = useState(false);
  const {register} = useAuth();
  const [form] = Form.useForm();
  const [usernameValue,setUsernameValue] = useState('');
  const [usernameValidating, setUsernameValidating] = useState(false);
  const [usernameValidated, setUsernameValidated] = useState(false);
  const [emailValue,setEmailValue] = useState('');
  const [emailValidating, setEmailValidating] = useState(false);
  const [emailValidated, setEmailValidated] = useState(false);
  const [disableRegister,setDisableRegister] = useState(false);

  const emailChange = (value) => {
    setEmailValue(value.target.value);
  }
  const usernameChange = (value) => {
    setUsernameValue(value.target.value);
  }

  const focusInput = () => {
    const element = document.getElementById('email-input');
    element.focus();
  }
  const showError = (values) => {
    values.forEach((value) => {
      message.error(value);
    });
  }
  const onFinish = async (values) => {
    setLoading(true);
    form.validateFields();
    const registerResponse = await register({username: values.username, email: values.email, password: values.password,is_community:is_community, showError:showError, onSuccess:onSuccess});
    
    if(registerResponse.status !== 201){
      setLoading(false);
    }
  };

  useEffect(() => {
    focusInput();
},[]);

  return (
    <Form
      form={form}
      name="register"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
      >
      <div className='mb-1'>
        <div className='p-0.5 font-medium text-white'>
          Username<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
          name="username"
          tooltip="What do you want others to call you?"
          className='m-0'
          rules={[
            {
              required: true,
              message: 'Please input your username!',
              whitespace: true,
            },
            {
              validator: async (_, value) => {
                setUsernameValidating(true);
                const check = await AuthServices.checkRegister({'username': value});
                setUsernameValidating(false);
                if (check.usernameUnique) {
                  setUsernameValidated(true);
                  return Promise.resolve();
                } 
                else if(value.includes('_')){
                  setUsernameValidated(false);
                  return Promise.reject('Sorry! Username cannot contain the following: _');
                }
                else {
                  setUsernameValidated(false);
                  return Promise.reject('Sorry! This Username Already Exists!');
                }
              }
            }
          ]}
          >
          <Input 
            size='large' 
            placeholder="username" 
            className='rounded-xl'
            suffix={usernameValue !== '' ? usernameValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : usernameValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : <CloseCircleOutlined style={{opacity:0}} />} 
            value={usernameValue} 
            onChange={usernameChange} />
        </Form.Item>
      </div>
      <div className='mb-1'>
        <div className='p-0.5 font-medium text-white'>
          Email<span className='text-ecalmers-full'>*</span>
        </div>
        <Form.Item
          name="email"
          className='m-0'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
            {
                validator: async (_, value) => {
                    setEmailValidating(true);
                    const check = await AuthServices.checkRegister({'email': value});
                    setEmailValidating(false);
                    if (check.emailUnique) {
                        setEmailValidated(true);
                        return Promise.resolve();
                    } else {
                        setEmailValidated(false);
                        return Promise.reject('This Email has already been used!');
                    }
                }
            }
          ]}
          >
          <Input id='email-input'
            size='large' 
            className='rounded-xl'
            prefix={<MailOutlined className="site-form-item-icon" />} 
            suffix={emailValue !== '' ? emailValidating ? <LoadingOutlined style={{fontSize:'14px'}} /> : emailValidated ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : <CloseCircleOutlined style={{opacity:0}} />} 
            placeholder="Enter Your Email" 
            value={emailValue} 
            onChange={emailChange} />
        </Form.Item>
      </div>
      <div className='mb-4'>
        <div className='p-0.5 text-white font-medium'>
          Password
        </div>
        <Form.Item
          name="password"
          className='m-0'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
          hasFeedback
        >
          <Input.Password 
            prefix={<LockOutlined className="site-form-item-icon" />} 
            placeholder="Enter a Password" 
            className='rounded-xl'
            size='large'
            />
        </Form.Item>
      </div>
      <Form.Item>
        <Button type="primary" id='sign-up-button' className="rounded-full h-auto text-md p-2 md:p-1.5 font-semibold w-full hover-scale-sm shadow-none" htmlType="submit" loading={loading} disabled={disableRegister} >
          Create an Account
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegistrationForm;