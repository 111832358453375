import ImageAttachment from "./attachments/ImageAttachment";
import LinkAttachment from "./attachments/LinkAttachment";


const ChatAttachments = ({attachments}) => {

    return(
        <div className="w-full flex flex-col gap-2 px-2 md:px-4 pb-4">
            {
                attachments.filter(value => value?.type === 'image').length > 0 && 
                    <ImageAttachment images={attachments.filter(value => value?.type === 'image')} />
            }
            {
                attachments.filter(value => value?.type === 'link').length > 0 &&
                    <LinkAttachment links={attachments.filter(value => value?.type === 'link')} />
            }
        </div>
    )
}

export default ChatAttachments;