import { CheckCircleOutlined, DownOutlined, EditOutlined, ExclamationCircleOutlined, GlobalOutlined, LinkOutlined, LoadingOutlined, MessageOutlined } from "@ant-design/icons";
import { message, Popover, Tooltip } from "antd";
import { useRef, useState } from "react";
import { useAuth } from "../../../../../utils/useAuth";
import ContentServices from "../../../../community/utils/ContentServices";
import Outline from "./Outline";

const LinkPreviewCard = ({values,editting=false,editable=false,onDelete=false}) => {
    let newUser;
    const imageRef = useRef();
    const {user,refresh} = useAuth();
    const [link,setLink] = useState(values);    
    const [content,setContent] = useState(null);
    const [showContent,setShowContent] = useState(false);
    const [loadingContent,setLoadingContent] = useState(false);
    const [uploading,setUploading] = useState(false);

    const clickShowContent = () => {
        if(user){
            if(content){
                setShowContent(prev => !prev);
            }
            else{
                if(showContent){
                    setShowContent(false);
                }
                else if(!showContent && !loadingContent){
                    setLoadingContent(true);
                    getContent();
                    setShowContent(true);
                }
                else{
                    setShowContent(true);
                }
            }
        }
        else{
            message.info('Must Login to Show Raw Content')
        }
    }

    const getContent = async () => {
        setLoadingContent(true);
        let contentResponse = await ContentServices.getLink({link_id:values.id,user:user});
        if(contentResponse?.status === 401){
            let newUser = await refresh();
            contentResponse = await ContentServices.getLink({link_id:values.id,user:newUser});
        }
        if(contentResponse?.status === 'success'){
            setContent(contentResponse?.data);
        }
        setLoadingContent(false);
    }

    const uploadImage = async (file) => {
        let data = {}
        let imageResponse = await ContentServices.postImage({image:file,name:`${user.username}-image-${file.name}`,data:data,user:user});
        if(imageResponse?.status === 401){
            newUser = await refresh();
            imageResponse = await ContentServices.postImage({image:file,name:`${user.username}-image-${file.name}`,data:data,user:newUser});
        }
        if(imageResponse?.status === 'success'){
            return imageResponse?.data
        }
        else{
            return false
        }
    }
    const fileUpload = async (event) => {
        setUploading(true);
        var file = event.target.files[0];
        if(file){
            var reader = new FileReader();
            reader.readAsDataURL(file);
    
            let uploadResponse = await uploadImage(file);
            if(uploadResponse){
                let data = {
                    'image_url':uploadResponse?.image
                }
                let imageResponse = await ContentServices.updateLink({link_id:link.id,data:data,user:user});
                if(imageResponse?.status === 401){
                    newUser = await refresh();
                    imageResponse = await ContentServices.updateLink({link_id:link.id,data:data,user:newUser});
                }
                setLink(prev => ({...prev,'image_url':uploadResponse?.image}));
            }
            setUploading(false);
        }
    };
    return(
        <div className="w-full rounded-2xl p-4 md:p-2 shadow-md relative border bg-slate-50 hover:bg-slate-100 duration-300 
            flex flex-col gap-2">
            <div className="grid grid-cols-1 flex items-start md:items-center gap-2 md:gap-4 w-full">                                    
                <div className="aspect-video relative">
                    <a href={link?.url} target='_blank' rel="noreferrer" >
                        {
                            link?.image_url && link?.image_url !== ''
                            ?
                                <div className="aspect-video w-full rounded-xl flex items-center justify-center border bg-cover bg-center bg-no-repeat" 
                                    style={{'backgroundImage':`url(${link?.image_url})`}}></div>
                            :
                                <div className="aspect-video w-full rounded-xl text-2xl text-slate-400 border flex items-center justify-center bg-cover bg-center bg-no-repeat bg-slate-200" >
                                    <LinkOutlined />
                                </div>
                        }
                    </a>
                    {
                        editable
                        ?   uploading
                            ?   
                                <div className="absolute top-0 right-0 p-4 pt-2 cursor-not-allowed">
                                    <div className="bg-slate-100 hover: bg-slate-200 rounded-full aspect-square px-1 flex items-center justify-center">
                                        <LoadingOutlined/>
                                    </div>
                                </div>
                            :
                                <div onClick={() => imageRef.current.click()} className="absolute top-0 right-0 p-4 pt-2 cursor-pointer">
                                    <div className="bg-slate-100 hover: bg-slate-200 rounded-full aspect-square px-1 flex items-center justify-center">
                                        <EditOutlined/>
                                    </div>
                                    <input type='file' id='file' ref={imageRef} accept="image/*" onChange={(event) => fileUpload(event)} style={{display: 'none'}}/>
                                </div>
                        :   null
                    }
                </div>
                <div className="col-span-1 flex flex-col gap-3">
                    <div className="w-full flex item-start flex-wrap">
                        <a href={link?.url} target='_blank' rel="noreferrer"  className="w-full">
                            <div className="w-full flex flex-col items-start text-left px-1 gap-0.5">
                                <div className="flex items-center gap-2 w-full">
                                    <h3 className="flex-[11_11_0%] text-lg font-semibold text-slate-600 truncate">{link?.title}</h3>
                                    <div className="flex-[1_1_0%] flex justify-end">
                                        {
                                            link?.status === 'Processed'
                                            ?
                                                <Popover content={<div className="max-w-[300px] leading-tight">Ready to go!</div>} >
                                                    <div className="text-green-500 text-base cursor-pointer">
                                                        <CheckCircleOutlined />
                                                    </div>
                                                </Popover>
                                            :
                                                <Popover content={<div className="max-w-[300px] leading-tight">Pending. Batch processing can take up to 24 hours. Upgrade for instant processing.</div>} >
                                                    <div className="text-yellow-500 text-sm cursor-pointer">
                                                        <ExclamationCircleOutlined />
                                                    </div>
                                                </Popover>
                                        }
                                    </div>
                                </div>
                                <div className="text-slate-500 truncate w-full md:w-4/5 leading-tight flex items-center gap-1">
                                    <LinkOutlined/> {link?.url.replace('https://','').replace('http://','').replace('www.','')}
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flex flex-col md:flex-row  w-full items-center gap-1">
                        {
                            link?.status === 'Processed' &&
                                <Tooltip title={showContent ? 'Hide TL;DR' : 'Show TL;DR'}>
                                    <div onClick={clickShowContent} className="w-full px-2 py-2 duration-300 cursor-pointer
                                        border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                        text-xs text-slate-600" >
                                        {
                                            showContent
                                            ?   <span>Hide TL;DR </span>
                                            :   <span>Show TL;DR <DownOutlined/></span>
                                        }
                                    </div>
                                </Tooltip>
                        }
                        {
                            editting &&
                                <Tooltip title={'Delete this link'}>
                                    <div onClick={onDelete} 
                                        className="w-full px-2 py-2 duration-300 cursor-pointer rounded-full text-xs text-red-500 hover:text-red-800" >
                                        Delete Link
                                    </div>
                                </Tooltip>
                        }
                    </div>
                </div>
            </div>
            {
                showContent &&
                    <div className="w-full">
                        {
                            loadingContent
                            ?
                                <div className="text-slate-600 text-2xl">
                                    <LoadingOutlined/>
                                </div>
                            :   content?.outline
                                ?
                                    <div className="p-2 w-full">
                                        <div className="mb-2">
                                            <div onClick={() => window.open(link?.url)} className="w-full md:w-fit px-3 py-2 md:py-0.5 duration-300 cursor-pointer
                                                border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                                text-xs text-slate-600" >
                                                <GlobalOutlined/> Open Link
                                            </div>
                                        </div>
                                        <div className="mb-6">
                                            <Outline values={content?.outline}/>
                                        </div>
                                        <div onClick={clickShowContent} className="w-full md:w-fit px-3 py-2 md:py-0.5 duration-300 cursor-pointer
                                            border border-slate-300 hover:border-slate-400 bg-slate-100 hover:bg-slate-200 rounded-full 
                                            text-xs text-slate-600" >
                                            Hide TL;DR
                                        </div>
                                    </div>
                                :   <div>
                                        No TL;DR Found
                                    </div>
                        }                        
                    </div>
            }
        </div>
    )

}


const LinkPreview = ({links,setLinks,clickTalk,editting=false,editable=false}) => {

    const deleteLink = (id) => {
        setLinks(links.filter(link => link.id !== id));
    }
    return (
        <div className="flex items-start gap-1 w-full">
            {
                links && links.length > 0 &&
                    links.map((link,index) => {
                        return <div key={`link-${index}`} className={`w-full md:min-w-[250px] max-w-full`}>
                            <LinkPreviewCard 
                                values={link} 
                                editable={editable} 
                                editting={editting} 
                                clickTalk={clickTalk} onDelete={() => deleteLink(link?.id)} />
                        </div>
                    })
            }
        </div>
    )
}

export default LinkPreview;