import { Skeleton } from "antd";

const CommunityLoadingCard = () => {
    return(
        <div className="rounded-2xl w-full border shadow-md bg-white overflow-hidden cursor-pointer">
            <div className="w-full aspect-[5/1] overflow-hidden">
                <Skeleton.Input style={{height:'200px'}} block active />
            </div>
            <div className="p-6 flex flex-col gap-2 overflow-hidden">
                <div className="w-1/3 mb-3">
                    <Skeleton.Input style={{height:'28px'}} block active />
                </div>
                <Skeleton.Input style={{height:'18px'}} block active />
                <Skeleton.Input style={{height:'18px'}} block active />
                <div className="w-9/12">
                    <Skeleton.Input style={{height:'18px'}} block active />
                </div>
                <div className="text-left">
                    <Skeleton.Avatar />
                </div>
            </div>
        </div>
    )
}

export default CommunityLoadingCard;