import { Avatar, Skeleton } from "antd";


function UserAvatar({app,size='large'}){
    return(
            app
            ?
                app?.avatar?.image
                ?   <Avatar src={app?.avatar?.image} size={size} className="border" />
                :   <Avatar size={size} className="bg-black border">{app?.name ? app?.name.slice(0,1) : app?.username?.slice(0,1) || ''}</Avatar>
            :   <Skeleton.Avatar shape="circle" size={size} />
    )
}

export default UserAvatar;