import { Button, Layout } from "antd";
import { Helmet } from "react-helmet-async";
import { Suspense, useEffect, useState } from "react";
import ExploreSider from "../components/Explore/ExploreSider";
import { Content } from "antd/es/layout/layout";
import CommunityServices from "../utils/CommunityServices";
import CommunityNav from "../components/Feed/CommunityNav";
import { useCommunityContext } from "../components/Context/CommunityContext";
import CommunityCard from "../../../components/cards/CommunityCard";
import CommunityLoadingCard from "../../../components/cards/CommunityLoadingCard";
import { PlusOutlined } from "@ant-design/icons";
import PageNav from "../components/Nav/PageNav";

const CommunitiesPage = () => {
    const [communities,setCommunities] = useState([]);
    const {setIsSiderVisible,openCreateCommunity,openShareLink} = useCommunityContext();
    const [loading,setLoading] = useState(true);

    const getCommunities = async ({page,limit}) => {
        setLoading(true);
        const communitiesResponse = await CommunityServices.getCommunities({page:page,limit:limit})
        if(communitiesResponse?.results){
            setCommunities(prev => ([...prev,...communitiesResponse?.results]));
        }
        setLoading(false);
    }

    useEffect(() => {
        if(window.innerWidth > 1000){
            setIsSiderVisible(true);
        }
        window.scrollTo({ top: 0 });
        getCommunities({page:0});
    },[])

    return(
        <Layout className="w-full h-full min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Communities | TalkInBio`}</title>
                <meta property="og:title" content={`Communities | TalkInBio`}/>
                <meta name="description" content={`Communities | TalkInBio | AI and LLM Centric Communities.`} />
            </Helmet>
            <PageNav type='community' />
            <Layout className="w-full h-full " >
                <Suspense fallback={null}>
                    <ExploreSider />
                </Suspense>
                <Content className="pt-8 md:pt-0 w-full h-auto md:h-screen flex flex-col overflow-y-auto pb-24">
                    <CommunityNav type={'Communities'} />
                    <div className="w-full">
                        <div className="w-full">
                            <div className="w-full md:w-4/5 max-w-[600px] h-auto m-auto p-4 md:p-0">
                                <div className="pt-12 mb-4 flex items-center">
                                    <div className="flex-1">
                                        <h1 className="text-left text-slate-600 font-bold text-2xl leading-tight">
                                            Recent Communities
                                        </h1>
                                    </div>
                                    <div>
                                        <Button type='outline' onClick={openCreateCommunity}
                                            className="w-full md:w-fit rounded-full bg-slate-100 hover:bg-slate-200 border border-slate-300 shadow-md text-slate-600 hover:text-slate-700 ">
                                            Create a Community
                                        </Button>
                                    </div>
                                </div>
                                
                                <div className="flex flex-col gap-4">
                                    {
                                        communities && communities.length > 0 &&
                                            communities.map((value,index) => 
                                                <div key={`community-${index}`} className="w-full">
                                                    <CommunityCard values={value} openShareLink={openShareLink} />
                                                </div>
                                            )
                                    }
                                    {
                                        loading && 
                                            [1,2,3,4,5].map((_,index) => 
                                                <div key={`community-loading-${index}`}>
                                                    <CommunityLoadingCard />
                                                </div>
                                            )
                                    }
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="fixed bottom-0 right-0 left-0 md:left-auto p-4 md:p-6">
                        <Button type='outline' onClick={openCreateCommunity} size='large' icon={<PlusOutlined/>} 
                            className="w-full md:w-fit rounded-full h-auto text-lg md:text-base bg-slate-100 hover:bg-slate-200 border border-slate-300 shadow-md text-slate-600 hover:text-slate-700 ">
                            Create a Community
                        </Button>
                    </div>
                </Content>
            </Layout>
        </Layout>
    )
}

export default CommunitiesPage;